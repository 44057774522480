import * as React from 'react';
import {Form, FormSpy} from "react-final-form";
import {BlockTextField, TextField} from "common/components";
import {Question, QuestionDto} from "../models";
import {helpers} from "../../common/helpers";
import {FORM_ERROR} from "final-form";
import arrayMutators from "final-form-arrays";
import {Button} from "@mui/material";

interface Props {
    initial?: Question,
    onSubmit: (values: QuestionDto) => any,
}

export const QuestionForm: React.FC<Props> = (props: Props) => {
    const storageKey = 'questionForm';

    const onChange = (formData: any) => {
        if (!props.initial) {
            const valuesJson = JSON.stringify(formData.values);
            localStorage.setItem(storageKey, valuesJson);
        }
    };

    // @ts-ignore
    let initial: Question | undefined = props.initial;
    if (initial === undefined) {
        const fromStorage = localStorage.getItem(storageKey);
        if (fromStorage !== null) {
            initial = JSON.parse(fromStorage);
        }
    } else {
        initial.data = helpers.editorContentToView(initial.data);
    }

    const onSubmit = async (formData: any) => {
        formData.data = helpers.editorContentFromView(formData.data);

        let dto = {
            data: formData.data
        } as QuestionDto;

        try {
            await props.onSubmit(dto);
            if (!props.initial) {
                localStorage.removeItem(storageKey);
            }
        } catch (error) {
            // @ts-ignore
            if (!error.response) return Promise.reject({ [FORM_ERROR]: error });

            // @ts-ignore
            let errors = error.response.data.errors;
            if (errors.common) {
                return { [FORM_ERROR]: errors.common };
            }
            if (errors.imageId) {
                errors.image = errors.imageId;
                delete errors.imageId;
            }

            return errors;
        }
    };

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initial}
            mutators={{
                ...arrayMutators
            }}
            render={({
                         handleSubmit, submitError, form: {
                    mutators: {push, pop}
                },
                     }) => {
                let submit: (values: any) => any = handleSubmit;
                return (
                    <>
                        {onChange &&
                        <FormSpy onChange={onChange} subscription={{values: true}}/>
                        }
                        <form onSubmit={handleSubmit}>
                            {props.initial && props.initial.id &&
                            <TextField
                                label={"ID"}
                                name={"id"}
                                disabled
                            />
                            }
                            <BlockTextField name={"data"} label={"Вопрос"} required/>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={event => {
                                    submit(event);
                                }}
                            >
                                Сохранить
                            </Button>
                        </form>
                    </>
                )
            }}/>
    );
};


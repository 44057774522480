import * as React from 'react';
import commonStyles from "common/styles";
import {FieldError} from "./FieldError";
import {Field} from "react-final-form";
import {TextFieldProps} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {TextField as MuiTextField} from "@mui/material";
import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    formControl: {
        ...commonStyles.formControl
    },
    editor: {
        marginTop: '0.3rem'
    }
}));
//https://stackoverflow.com/questions/62440365/material-ui-wrapper-reuse-typescript-types-for-a-given-prop
interface Props  {
    name: string
}

export const TextField: React.FC<Props & TextFieldProps> = (props: Props & TextFieldProps) => {
    const classes = useStyles();

    const {name} = props;
    return (
        <Field
            name={name}
            component="input"
            required={props.required}
        >
            {({input, meta, ...rest}) => {
                const error = meta.error || meta.submitError;
                return <FormControl className={classes.formControl}>
                    <MuiTextField
                        {...input} {...rest}
                        {...props}
                        error={!!meta.error || !!meta.submitError}
                    />
                    {(meta.error || meta.submitError) && meta.touched &&
                    <FieldError text={error}/>
                    }
                </FormControl>
            }}
        </Field>
    );
};


import * as React from 'react';
import styles from "common/styles";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {createStyles, makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => createStyles({
    active: {
        color: styles.successColor,
    },
    inactive: {
        color: styles.dangerColor
    }
}));

interface Props {
    active: boolean;
}

export const BooleanIndicator: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const { active } = props;

    if (active) {
        return <DoneIcon className={classes.active} />
    }
    return <CloseIcon className={classes.inactive} />
};

import * as React from 'react';
import {Field, Form} from "react-final-form";
import {FieldError} from "common/components";
import commonStyles from "common/styles";
import {Image} from "common/models";
import 'moment/locale/ru';
import {validators} from "../validators";
import {DialogContent} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from "@mui/material";


const useStyles = makeStyles((theme: Theme) => createStyles({
    formControl: {
        ...commonStyles.formControl
    }
}));


interface ImageFormProps {
    initial?: Image,
    onSubmit: (values: any) => any,
    handleClose: () => any,
}

export const ImageForm: React.FC<ImageFormProps> = (props: ImageFormProps) => {
    const classes = useStyles();

    return (
        <Form
            onSubmit={props.onSubmit}
            initialValues={props.initial}
            render={({handleSubmit, submitError, form}) => {
                let submit: (values: any) => any = handleSubmit;
                return (
                    <>
                        <DialogContent>
                            <form onSubmit={handleSubmit}>
                                <Field
                                    name="file"
                                    type="file"
                                    validate={validators.composeValidators(validators.required)}
                                    required
                                >
                                    {({input, meta, ...rest}) => (
                                        <FormControl className={classes.formControl}>
                                            <input
                                                {...rest}
                                                onChange={(event) => {
                                                    // @ts-ignore
                                                    form.change(`file`, event.target.files[0]);
                                                    form.blur(`file`)
                                                }}
                                                type="file"
                                            />
                                            {(meta.error || meta.submitError) && meta.touched && (
                                                <FieldError text={meta.error || meta.submitError}/>
                                            )}
                                        </FormControl>
                                    )}
                                </Field>
                                <Field
                                    name="description"
                                    component="input"
                                    type="text"
                                    validate={validators.composeValidators(validators.required, validators.maxLength(100))}
                                >
                                    {({input, meta, ...rest}) => (
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                label="Описание"
                                                {...input} {...rest}
                                            />
                                            {(meta.error || meta.submitError) && meta.touched && (
                                                <FieldError text={meta.error || meta.submitError}/>
                                            )}
                                        </FormControl>
                                    )}
                                </Field>

                                {submitError && <FieldError text={submitError}/>}

                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={event => {
                                    submit(event);
                                }}
                            >
                                Сохранить
                            </Button>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={props.handleClose}
                            >
                                Отмена
                            </Button>
                        </DialogActions>
                    </>
                )
            }}
        />
    );
};


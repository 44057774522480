import {DogsState} from "../states";
import {DogsLoadAction, DogsLoadCompletedAction, types} from "../actions/types";

const initialState = (): DogsState => ({
    data: [],
    count: 0,
    params: {
        limit: 12,
        offset: 0,
        order: '-id'
    },
});

export const DogsReducer = (state = initialState(), action: any) => {
    switch (action.type) {
        case types.DOGS_LOAD:
            return Object.assign({}, state, {
                params: (action as DogsLoadAction).payload.params
            });
        case types.DOGS_LOAD_COMPLETED:
            const {data, count} = (action as DogsLoadCompletedAction).payload;
            return Object.assign({}, state, {
                data: data,
                count: count,
            });
    }
    return state;
};
import {types} from "./types";
import {LoginDto} from "../../models/LoginDto";
import {TokenStorage} from "common/token-storage";
import {ApiClient} from "common/api-client.ts";
import axios from 'axios';
import jwt_decode from "jwt-decode";
import {JwtPair} from "../../models/JwtPair";


const login = (dto: LoginDto) => (dispatch: any) => {
    return ApiClient.post(`/auth/login`, dto, {
        withCredentials: true
    })
        .then(function (response: any) {
            const { data } = response.data;
            const payload: any = jwt_decode(data.access);
            if (payload.user.isAdmin) {
                const tokenStorage = TokenStorage.getInstance();
                tokenStorage.setJwtPair(data);
                dispatch(loginSuccess(data.access));
            } else {
                return Promise.reject("Доступ запрещён");
            }
            return data;
        })
};

const loginSuccess = (accessToken: string) => {
    return {
        type: types.LOGIN,
        payload: {
            accessToken: accessToken,
        }
    }
};

const logout = () => (dispatch: any) => {
    ApiClient.post(`/auth/logout`, {})
        .then(function () {
            const tokenStorage = TokenStorage.getInstance();
            tokenStorage.clearToken();
            dispatch(logoutSuccess());
        })
};

const logoutSuccess = () => {
    return {
        type: types.LOGOUT,
        payload: {}
    }
};

const autologin = () => (dispatch: any) => {
    const tokenStorage = TokenStorage.getInstance();
    if (!tokenStorage.getAccess()) {
        refreshToken(tokenStorage.getRefresh())
            .then(function (jwt: any) {
                const tokenStorage = TokenStorage.getInstance();
                const { access } = jwt;
                tokenStorage.setJwtPair(jwt);
                dispatch(loginSuccess(access));
            })
            .catch(function (error: any) {
                if (error.response.status === 401) {
                    /*tokenStorage.clearToken();
                    store.dispatch(usersActions.logout());*/
                    return Promise.resolve();
                }
                return Promise.reject(error)
            });
    }
}

const refreshToken = (refresh: string | null = null): Promise<JwtPair> => {
    let data = {};
    if (refresh) {
        // @ts-ignore
        data['refresh'] = refresh;
    }

    return axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/auth/refresh`, data, {
        withCredentials: true
    }).then(function (response: any) {
        return response.data.data;
    });
};

const fetchUsers = () => {
    return ApiClient.get(`/admin/users`)
        .then(function (response: any) {
            return response.data.data;
        })
};

export const usersActions = {
    login,
    logout,
    autologin,
    loginSuccess,
    refreshToken,
    fetchUsers,
};

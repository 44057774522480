import * as React from 'react';
import commonStyles from "common/styles";
import {Field} from "react-final-form";
import {FieldError} from "./FieldError";
import {createStyles, makeStyles} from '@mui/styles';
import {Autocomplete, AutocompleteRenderInputParams} from "@mui/material";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";

const useStyles = makeStyles(() => createStyles({
    formControl: {
        ...commonStyles.formControl
    },
    formGroupControl: {
        margin: 0,
        display: 'flex'
    },
}));

interface Props {
    name: string
    label: string
}

export const TagsField: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    const {name, label} = props;
    return (
        <Field
            name={name}
            component="input"
            type="text"
        >
            {({input, meta, ...rest}) => {
                /*const addTag = (tag: string) => {
                    let value = input.value;
                    if (!Array.isArray(value)) {
                        value = [tag];
                    } else {
                        value.push(tag);
                    }
                    input.onChange(Object.assign([], value));
                };*/
                const value = input.value?input.value:[];
                return <FormControl className={classes.formControl}>
                    <Autocomplete
                        multiple
                        freeSolo
                        value={value}
                        onChange={(e, value) => input.onChange(value)}
                        options={[]}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                    onDelete={() => input.onChange(input.value.filter((tag: string, i: number) => i !== index))}
                                />
                            ))
                        }
                        renderInput={(params: AutocompleteRenderInputParams) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={label}
                                placeholder="Type and press enter"
                            />
                        )}
                    />
                    {(meta.error || meta.submitError) && meta.touched && (
                        <FieldError text={meta.error || meta.submitError}/>
                    )}
                </FormControl>
            }}
        </Field>

    );
};


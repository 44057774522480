import {helpers} from "common/helpers";
import {ApiClient} from "../../api-client.ts";

const uploadFromEditor = (image: File) => {
    const formData = new FormData();
    formData.append('image', image, image.name);
    return ApiClient.post('/common/upload', formData)
        .then(function (response) {
            const { data } = response.data;
            return {
                success: 1,
                file: {
                    url: helpers.getImageUrl(data.file, true)
                }
            };
        });
};

const upload = (image: File, description: string | null = null) => {
    const formData = new FormData();
    formData.append('image', image, image.name);
    
    if (description) {
        formData.append('description', description);
    }

    return ApiClient.post('/common/upload', formData)
        .then(function (response) {
            return response.data;
        });
};

const fetchByIds = (ids: number[]) => {
    return ApiClient.get(`/admin/common/images?ids=${ids.join(',')}`)
        .then(function (response) {
            return response.data;
        })
};

export const imagesActions = {
    upload,
    uploadFromEditor,
    fetchByIds
};

import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {Layout, Pages} from "common/components";
import cogoToast from "cogo-toast";
import {dogsActions} from "../store/actions";
import {DogDetailed, DogDto} from "../models";
import {DogForm} from "./DogForm";
import {Breadcrumb} from "../../common/models";
import {redirect, useParams} from "react-router";
import {LinearProgress} from "@mui/material";
import Grid from "@mui/material/Grid";

export const DogEditPage = () => {
    const {id} = useParams()
    const dispatch = useDispatch();

    const [dog, setDog] = useState<DogDetailed | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            const response = await dogsActions.fetchDog(Number(id));
            setDog(response.data);
            setIsLoading(false);
        };
        setIsLoading(true);
        fetchData();

        window.scrollTo(0, 0);

    }, [dispatch, id]);

    const [isUpdated, setIsUpdated] = useState(false);

    const onSubmit = async (dto: DogDto) => {
        await dogsActions.updateDog(Number(id), dto);
        cogoToast.success('Порода успешно изменена', {position: 'bottom-left'});
        setIsUpdated(true);
        window.scrollTo(0, 0);
    };

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Собаки",
                href: "/dogs"
            },
        ];

        if (dog) {
            breadcrumbs.push({
                name: 'Редактирование',
            },)
        }

        return breadcrumbs;
    };

    if (isUpdated) {
        redirect("/dogs");
    }

    return (
        <Layout page={Pages.Dogs} headline={"Редактирование породы"} breadcrumbs={getBreadcrumbs()}>
            {isLoading ? <LinearProgress/> :
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {dog && (
                            <DogForm onSubmit={onSubmit} initial={dog}/>
                        )}
                    </Grid>
                    <Grid item xs={6}>

                    </Grid>
                </Grid>
            }
        </Layout>
    );
};


import {FishesState} from "../states";
import {FishesLoadAction, FishesLoadCompletedAction, FishesLoadTypes, types} from "../actions/types";

const initialState = (): FishesState => ({
    data: [],
    count: 0,
    params: {
        limit: 12,
        offset: 0,
        order: '-id'
    },
});

export const FishesReducer = (state = initialState(), action: FishesLoadTypes) => {
    switch (action.type) {
        case types.FISHES_LOAD:
            return Object.assign({}, state, {
                params: (action as FishesLoadAction).payload.params
            });
        case types.FISHES_LOAD_COMPLETED:
            const {data, count} = (action as FishesLoadCompletedAction).payload;
            return Object.assign({}, state, {
                data: data,
                count: count,
            });
    }
    return state;
};
import * as React from 'react';
import {TextField} from "./index";
import commonStyles from "common/styles";
import {TextFieldProps} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import {makeStyles} from '@mui/styles';

const classNames = require('classnames');

const useStyles = makeStyles({
    formControl: {
        ...commonStyles.formControl
    },
    fromToControl: {
        width: "initial",
        display: "inline-block"
    },
});

interface Props {
    name_from: string
    name_to: string
}

export const RangeField: React.FC<Props & TextFieldProps> = (props: Props & TextFieldProps) => {
    const classes = useStyles();

    const {name_from, name_to, label} = props;

    return (
        <FormControl className={classNames(classes.formControl, classes.fromToControl)}>
            <FormLabel
                component="legend"
            >{label}</FormLabel>
            <Grid spacing={1} container>
                <Grid item sm={6}>
                    <TextField
                        {...props}
                        placeholder={"от"}
                        name={name_from}
                        type={"number"}
                        label={"от"}
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        {...props}
                        placeholder={"до"}
                        name={name_to}
                        type={"number"}
                        label={"до"}
                    />
                </Grid>
            </Grid>
        </FormControl>
    );
};


import * as React from 'react';
import {Form, FormSpy} from "react-final-form";
import {BlockTextField, ImageField, SelectField, TextField} from "common/components";
import {helpers} from "../../common/helpers";
import arrayMutators from "final-form-arrays";
import {FORM_ERROR} from "final-form";
import {TestDto} from "../models/TestDto";
import {TestDetailed} from "../models/TestDetailed";
import {Button} from "@mui/material";

interface Props {
    initial?: TestDetailed,
    onSubmit: (values: TestDto) => any,
}

export const TestForm: React.FC<Props> = (props: Props) => {
    const storageKey = 'testForm';

    const onChange = (formData: any) => {
        if (!props.initial) {
            const valuesJson = JSON.stringify(formData.values);
            localStorage.setItem(storageKey, valuesJson);
        }
    };

    // @ts-ignore
    let initial: TestDetailed | undefined = props.initial;
    if (initial === undefined) {
        const fromStorage = localStorage.getItem(storageKey);
        if (fromStorage !== null) {
            initial = JSON.parse(fromStorage);
        }
    } else {
        initial.data = helpers.editorContentToView(initial.data);
    }

    const onSubmit = async (formData: any) => {
        formData.data = helpers.editorContentFromView(formData.data);

        let imageId = null;
        if (formData.image) {
            if (!Array.isArray(formData.image)) {
                imageId = formData.image.id;
            } else {
                imageId = formData.image[0].id;
            }
        }

        let dto = {
            name: formData.name,
            annotation: formData.annotation,
            title: formData.title,
            description: formData.description,
            data: formData.data,
            imageId: imageId,
            status: initial === undefined?'draft':formData.status
        } as TestDto;

        try {
            await props.onSubmit(dto);
            if (!props.initial) {
                localStorage.removeItem(storageKey);
            }
        } catch (error) {
            // @ts-ignore
            if (!error.response) return Promise.reject({ [FORM_ERROR]: error });

            // @ts-ignore
            let errors = error.response.data.errors;
            if (errors.common) {
                return { [FORM_ERROR]: errors.common };
            }
            if (errors.imageId) {
                errors.image = errors.imageId;
                delete errors.imageId;
            }

            return errors;
        }
    };

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initial}
            mutators={{
                ...arrayMutators
            }}
            render={({
                         handleSubmit, submitError, form: {
                    mutators: {push, pop}
                },
                     }) => {
                let submit: (values: any) => any = handleSubmit;
                return (
                    <>
                        {onChange &&
                        <FormSpy onChange={onChange} subscription={{values: true}}/>
                        }
                        <form onSubmit={handleSubmit}>
                            {props.initial && props.initial.id &&
                            <>
                                <TextField
                                    label={"ID"}
                                    name={"id"}
                                    disabled
                                />
                                <TextField
                                    label={"Slug"}
                                    name={"slug"}
                                    disabled
                                />
                            </>
                            }
                            <TextField
                                label={"Title"}
                                name={"title"}
                                required
                            />
                            <TextField
                                label={"Meta-description"}
                                name={"description"}
                                multiline
                                required
                            />
                            <TextField
                                label={"Название теста"}
                                name={"name"}
                                required
                            />
                            <TextField
                                label={"Анонс"}
                                name={"annotation"}
                                multiline
                                required
                            />
                            <BlockTextField
                                name={"data"}
                                label={"Текст"}
                                required
                            />
                            <ImageField
                                name={"image"}
                                label={"Изображение"}
                                required
                            />
                            {initial !== undefined && initial.id &&
                            <SelectField
                                name={'status'}
                                label={'Статус'}
                                choices={[
                                    {key: "draft", value: "Черновик",},
                                    {key: "published", value: "Опубликовано",}
                                ]}/>
                            }
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={event => {
                                    submit(event);
                                }}
                            >
                                Сохранить
                            </Button>
                        </form>
                    </>
                )
            }}/>
    );
};

import {types} from "quizes/store/actions/types";
import {QuestionsState} from "../states";

const initialState = (): QuestionsState => ({
    data: [],
    count: 0,
    params: {
        limit: 12,
        offset: 0,
    },
});

export const QuestionsReducer = (state = initialState(), action: any) => {
    switch (action.type) {
        case types.TESTS_QUESTIONS_LOAD:
            return Object.assign({}, state, {
                params: action.payload.params
            });
        case types.TESTS_QUESTIONS_LOAD_COMPLETED:
            const {data, count} = action.payload;
            return Object.assign({}, state, {
                data: data,
                count: count,
            });
    }
    return state;
};
import * as React from 'react';
import commonStyles from "common/styles";
import {NavLink} from "react-router-dom";
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {FaCat, FaDog, FaUsers} from 'react-icons/fa';
import {GiTropicalFish} from 'react-icons/gi';
import {MenuList} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from "@mui/material";

const classNames = require('classnames');

const useStyles = makeStyles((theme: Theme) => createStyles({
    list: {
        marginTop: "20px",
        paddingLeft: "0",
        paddingTop: "0",
        paddingBottom: "0",
        marginBottom: "0",
        listStyle: "none"
    },
    item: {
        position: "relative",
        display: "block",
        textDecoration: "none",
    },
    menuLink: {
        ...commonStyles.defaultFont,
        width: 'auto',
        transition: "all 300ms linear",
        margin: "10px 15px 0",
        borderRadius: "3px",
        position: "relative",
        display: "block",
        padding: "10px 15px",
        backgroundColor: "transparent",
    },
    menuIcon: {
        fontSize: "1.5rem",
        width: "24px",
        height: "30px",
        float: "left",
        marginRight: "15px",
        textAlign: "center",
        verticalAlign: "middle",
        color: "rgba(255, 255, 255, 0.8)"
    },
    menuText: {
        ...commonStyles.defaultFont,
        margin: "0",
        lineHeight: "30px",
        fontSize: "14px",
        color: "#FFFFFF"

    },
    active: {
        backgroundColor: commonStyles.successColor,
        boxShadow:
            "0 12px 20px -10px rgba(76,175,80,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(76,175,80,.2)",
        "&:hover": {
            backgroundColor: commonStyles.successColor,
            boxShadow:
                "0 12px 20px -10px rgba(76,175,80,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(76,175,80,.2)"
        }
    }
}));

export enum Pages {
    Posts = 'posts',
    Tests = 'tests',
    Dogs = 'dogs',
    Cats = 'cats',
    Fishes = 'fishes',
    Users = 'users',
}

let menuItems = [
    {
        name: "Статьи",
        path: "/posts",
        icon: ArtTrackIcon,
        page: Pages.Posts,
    },
    {
        name: "Тесты",
        path: "/tests",
        icon: FormatListBulletedIcon,
        page: Pages.Tests,
    },
    {
        name: "Собаки",
        path: "/dogs",
        icon: FaDog,
        page: Pages.Dogs,
    },
    {
        name: "Кошки",
        path: "/cats",
        icon: FaCat,
        page: Pages.Cats,
    },
    {
        name: "Рыбы",
        path: "/fishes",
        icon: GiTropicalFish,
        page: Pages.Fishes,
    },
    {
        name: "Пользователи",
        path: "/users",
        icon: FaUsers,
        page: Pages.Users,
    },
];

interface MenuProps {
    page: Pages
}

export const SidebarMenu: React.FC<MenuProps> = (props: MenuProps) => {
    const classes = useStyles();
    // activeClassName="active"
    return (
        <MenuList className={classes.list}>
            {menuItems.map((prop, key) => {
                return (
                    <NavLink
                        to={prop.path}
                        className={classes.item}
                        key={key}
                    >
                        <MenuItem className={classNames({
                            [classes.active]: props.page === prop.page,
                            [classes.menuLink]: true
                        })} key={prop.path}>
                            <ListItemIcon className={classes.menuIcon}>
                                <prop.icon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={prop.name}
                                className={classes.menuText}
                                disableTypography={true}
                            />
                        </MenuItem>
                    </NavLink>
                );
            })}
        </MenuList>
    );
};

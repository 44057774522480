import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {postsActions} from "posts/store/actions";
import {StateInterface} from "reducers";
import AddIcon from '@mui/icons-material/Add';
import commonStyles from "common/styles";
import {Link} from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import {ActionButton, Layout, Pages} from "common/components";

import {
    CustomPaging,
    DataTypeProvider,
    PagingState,
    RowDetailState,
    SearchState,
    SortingDirection,
    SortingState
} from '@devexpress/dx-react-grid';
import {
    Grid,
    PagingPanel,
    SearchPanel,
    Table,
    TableColumnVisibility,
    TableFixedColumns,
    TableHeaderRow,
    TableRowDetail,
    Toolbar
} from '@devexpress/dx-react-grid-material-ui';
import {PostsFilters} from "../filters";
import {helpers} from "../../common/helpers";
import {Breadcrumb} from "../../common/models";
import {Button} from "@mui/material";
import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from "@mui/material";


const useStyles = makeStyles((theme: Theme) => createStyles({
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    link: {
        ...commonStyles.link,
    },
    data: {
        marginTop: "0.5rem"
    },
    head: {
        backgroundColor: "#fff",
        position: "sticky",
        top: 0
    },
    actionBtns: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const ThumbnailTypeProvider = (props: any) => {
    return <DataTypeProvider
        formatterComponent={(row: any) => row.value ? <img style={{maxWidth: '200px', width: '100%'}} alt="" src={helpers.getImageUrl(row.value.thb350x250)}/> : null}
        {...props}
    />
};

/*const CategoryTypeProvider = (props: any) => (
    <DataTypeProvider
        formatterComponent={(row: any) => row.value?row.value.name:null}
        {...props}
    />
);*/

const ActionsTypeProvider = (props: any) => (
    <DataTypeProvider
        formatterComponent={(props: any) => {
            return <>
                <ActionButton icon={<EditIcon/>}
                    url={'/posts/' + String(props.row.id)}/>
                <ActionButton icon={<VisibilityIcon/>}
                    url={`https://animalpaw.ru/posts/${props.row.slug}`}/>
                </>;
        }}
        {...props}
    />
);

const RowDetail = (props: any) => (
    <div>
        {props.row.annotation}
    </div>
);

export const PostsListPage: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const posts = useSelector((state: StateInterface) => state.posts.posts.data);
    //const isLoading = useSelector((state: StateInterface) => state.posts.posts.isLoading);
    const totalCount = useSelector((state: StateInterface) => state.posts.posts.count);
    const params = useSelector((state: StateInterface) => state.posts.posts.params);

    let orderDirection = 'asc';
    let orderField = params.order;
    if (params.order.startsWith('-')) {
        orderDirection = 'desc';
        orderField = orderField.substr(1);
    }

    const [pageSizes] = useState([12, 24, 48]);
    useEffect(() => {
        // @ts-ignore
        dispatch(postsActions.fetchPosts());
    }, [dispatch]);

    const handleChangePage = (pageNum: number) => {
        let newFilters: PostsFilters = Object.assign({}, params);
        newFilters.offset = pageNum * params.limit;
        // @ts-ignore
        dispatch(postsActions.fetchPosts(newFilters));
    };

    const handleChangePageSize = (value: number) => {
        let newFilters: PostsFilters = Object.assign({}, params);
        newFilters.limit = value;
        newFilters.offset = 0;
        // @ts-ignore
        dispatch(postsActions.fetchPosts(newFilters));
    };

    const handleSorting = (value: any) => {
        let newFilters: PostsFilters = Object.assign({}, params, {
            'order': (value[0].direction === 'desc'?'-':'') + value[0].columnName
        });
        // @ts-ignore
        dispatch(postsActions.fetchPosts(newFilters,));
        /*
        {
            field: value[0].columnName,
            direction: value[0].direction
        }
         */
    };

    const onSearch = (value: any) => {
        let newFilters: PostsFilters = Object.assign({}, params);
        newFilters.offset = 0;
        newFilters.search = value;
        // @ts-ignore
        dispatch(postsActions.fetchPosts(newFilters));
    };

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Статьи",
            },
        ];
        return breadcrumbs;
    };
//                     <CategoryTypeProvider for={['category']}/>
    return (
        <Layout page={Pages.Posts} headline={"Статьи"} breadcrumbs={getBreadcrumbs()}>
            <div className={classes.actionBtns}>
                <Link to={'/posts/create'} style={{textDecoration: 'none'}}>
                    <Button variant="outlined" color="primary">
                        <AddIcon className={classes.leftIcon}/> Создать
                    </Button>
                </Link>
            </div>
            <div className={classes.data}>
                <Grid
                    rows={posts}
                    columns={[
                        { name: 'id', title: 'ID' },
                        { name: 'slug' },
                        { name: 'image', title: 'Logo' },
                        { name: 'name', title: 'Headline' },
                        /*{ name: 'category', title: 'Category' },*/
                       /* { name: 'viewsCountToday', title: 'Last 24 hours' },
                        { name: 'viewsCountMonth', title: 'Last 30 days' },*/
                        { name: 'actions', title: 'Actions' },
                    ]}>
                    <SortingState
                        sorting={[
                            {
                                columnName: orderField,
                                direction: orderDirection as SortingDirection
                            }
                        ]}
                        onSortingChange={handleSorting}
                        columnExtensions={[
                            {
                                columnName: 'image', sortingEnabled: false
                            },
                            {
                                columnName: 'actions', sortingEnabled: false
                            }
                        ]}
                    />
                    <PagingState
                        currentPage={Math.floor(params.offset / params.limit)}
                        onCurrentPageChange={handleChangePage}
                        pageSize={params.limit}
                        onPageSizeChange={handleChangePageSize}
                    />
                    <SearchState
                        value={params.search}
                        onValueChange={onSearch}
                    />
                    <RowDetailState />

                    <CustomPaging totalCount={totalCount}/>
                    <Table />
                    <TableColumnVisibility defaultHiddenColumnNames={['annotation', 'slug']}/>
                    <ThumbnailTypeProvider for={['image']}/>
                    <ActionsTypeProvider for={['actions']}/>
                    <TableHeaderRow showSortingControls />
                    <TableRowDetail
                        contentComponent={RowDetail}
                    />
                    <Toolbar />
                    <SearchPanel />
                    <TableFixedColumns
                        leftColumns={['id']}
                        rightColumns={['actions']}
                    />
                    <PagingPanel
                        pageSizes={pageSizes}
                    />
                </Grid>
            </div>
        </Layout>
    );
};


import {types} from "../actions/types";
import {ImagesState} from "../states";

const initialState = (): ImagesState => ({
    data: [],
    meta: undefined,
    isLoading: false,
    filters: {
        limit: 24,
        offset: 0
    }
});

export const ImagesReducer = (state = initialState(), action: any) => {
    switch (action.type) {
        case types.IMAGES_LOAD:
            return Object.assign({}, state, {
                isLoading: true,
                filters: action.payload.filters,
            });
        case types.IMAGES_LOAD_COMPLETED:
            return Object.assign({}, state, {
                isLoading: false,
                data: action.payload.data,
                meta: action.payload.meta,
            });
    }
    return state;
};
import * as React from 'react';
import {useState} from 'react';
import {validators} from "../validators";
import {Field} from "react-final-form";
import {FieldError} from "./index";
import commonStyles from "common/styles";
import DeleteIcon from '@mui/icons-material/Delete';
import Dropzone from 'react-dropzone';
import {IconButton} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { makeStyles } from '@mui/styles';

const classNames = require('classnames');

const useStyles = makeStyles({
    formControl: {
        ...commonStyles.formControl
    },
    dropzone: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        cursor: 'pointer'
    },
    dropzoneActive: {
        borderColor: '#2196f3'
    },
    dropzoneAccept: {
        borderColor: '#00e676'
    },
    dropzoneReject: {
        borderColor: '#ff1744'
    },
    thumbnail: {
        width: "100%"
    },
    thumbnailPanel: {
        marginTop: "0.5rem",
        width: "30%",
        position: "relative",
        border: '1px solid rgba(0, 0, 0, 0.22)',
        borderRadius: '0.1rem'
    },
    imageRemoveIcon: {
        position: "absolute",
        top: "5px",
        right: "5px",
        color: commonStyles.dangerColor
    }
});

interface Props {
    name: string
    label: string
    required?: boolean
}

export const BaseImageField: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    const {name, label} = props;

    const required = !!props.required;

    let validatorsList: any = [];
    if (required) {
        validatorsList.push(validators.required);
    }

    const [isTouched, setIsTouched] = useState(false);

    return (
        <Field
            name={name}
            component="input"
            type="file"
            validate={validators.composeValidators(...validatorsList)}
            required={required}
        >
            {({input, meta, ...rest}) => {
                const error = meta.error || meta.submitError;
                return <FormControl className={classes.formControl}>
                    <FormLabel
                        component="legend"
                        error={meta.touched && error}
                        required={required}
                    >{label}</FormLabel>

                    <div onMouseEnter={(event: any) => setIsTouched(true)}>
                        <Dropzone
                            onDrop={(acceptedFiles: any[]) => {
                                setIsTouched(true);
                                input.onChange(acceptedFiles[0]);
                            }}
                            multiple={false}
                            accept={{"types": ["image/*"]}}
                        >
                            {({getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject}) => (
                                <>{input.value ?
                                    <div className={classes.thumbnailPanel}>
                                        <img
                                            alt={""}
                                            className={classes.thumbnail}
                                            src={input.value}
                                        />
                                        <IconButton
                                            className={classes.imageRemoveIcon}
                                            aria-label="Удалить"
                                            onClick={() => {
                                                input.onChange("")
                                            }}
                                        >
                                            <DeleteIcon/>
                                        </IconButton>
                                    </div> : <div {...getRootProps({
                                        className: classNames(
                                            classes.dropzone,
                                            {[classes.dropzoneActive]: isDragActive},
                                            {[classes.dropzoneAccept]: isDragAccept},
                                            {[classes.dropzoneReject]: isDragReject},
                                        )
                                    })}>
                                        <input {...getInputProps()} />
                                        <p>Перетащите изображение сюда или нажмите для выбора файла</p>
                                    </div>

                                }</>
                            )}
                        </Dropzone>
                    </div>

                    {isTouched && error &&
                    <FieldError text={error}/>
                    }
                </FormControl>
            }}
        </Field>
    );
};


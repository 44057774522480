import {types} from './types';
import {Answer, AnswerDto, Question, Result, ResultDto, Test} from "quizes/models";
import {store} from "../../../store";
import {ApiClient} from "../../../common/api-client.ts";
import {QuestionsFilters, TestsFilters} from "../../filters";
import {TestDto} from "../../models/TestDto";
import {QuestionDto} from "../../models";
import {arrayMove} from "react-sortable-hoc";

const buildUrl = require('build-url');

const fetchTests = (params?: TestsFilters) => (dispatch: any) => {
    let queryParams = new URLSearchParams();
    if (!params) {
        params = store.getState().quizes.tests.params;
    }
    Object.entries(params).forEach(([key, value]) => {
        queryParams.append(key, value)
    });

    let url = buildUrl(process.env.REACT_APP_BACKEND_API_URL, {
        path: '/admin/tests/',
        queryParams: Object.fromEntries(queryParams),
    });
    dispatch(testsListLoad(params));

    return ApiClient.get(url)
        .then(function (response) {
            dispatch(testsListLoadingSuccess(response.data.data, response.data.meta.total));
            return response.data;
        });
};

const testsListLoad = (params: TestsFilters) => ({
    type: types.TESTS_LOAD,
    payload: {
        params: params,
    }
});

const testsListLoadingSuccess = (tests: Test[], count: number) => ({
    type: types.TESTS_LOAD_COMPLETED,
    payload: {
        data: tests,
        count: count
    }
});

const fetchTest = (id: number) => {
    return ApiClient.get(`/admin/tests/${id}`)
        .then(function (response) {
            return response.data;
        })
};

const createTest = (dto: TestDto) => {
    return ApiClient.post('/admin/tests', dto)
        .then(function (response) {
            return response.data
        });
};

const updateTest = (id: number, dto: TestDto) => {
    return ApiClient.put(`/admin/tests/${id}`, dto)
};

const fetchQuestions = (testId: number) => (dispatch: any) => {
    let url = buildUrl(process.env.REACT_APP_BACKEND_API_URL, {
        path: `/admin/tests/${testId}/questions`,
    });
    dispatch(questionsListLoad({} as any));

    return ApiClient.get(url)
        .then(function (response) {
            dispatch(questionsListLoadingSuccess(response.data.data, response.data.meta.total));
            return response.data;
        });
};

const questionsListLoad = (params: QuestionsFilters) => ({
    type: types.TESTS_QUESTIONS_LOAD,
    payload: {
        params: params,
    }
});

const questionsListLoadingSuccess = (questions: Question[], count: number) => ({
    type: types.TESTS_QUESTIONS_LOAD_COMPLETED,
    payload: {
        data: questions,
        count: count
    }
});

const fetchQuestion = (testId: number, questionId: number) => {
    return ApiClient.get(`/admin/tests/${testId}/questions/${questionId}`)
        .then(function (response) {
            return response.data;
        })
};

const createQuestion = (testId: number, dto: QuestionDto) => {
    return ApiClient.post(`/admin/tests/${testId}/questions`, dto)
        .then(function (response) {
            return response.data
        });
};

const updateQuestion = (testId: number, questionId: number, dto: QuestionDto) => {
    return ApiClient.put(`/admin/tests/${testId}/questions/${questionId}`, dto)
        .then(function (response) {
            return response.data
        });
};

const deleteQuestion = (testId: number, questionId: number) => (dispatch: any) => {
    const questions = store.getState().quizes.questions.data;
    dispatch(questionsListLoadingSuccess(questions.filter(q => q.id !== questionId), store.getState().quizes.questions.count));
    return ApiClient.delete(`/admin/tests/${testId}/questions/${questionId}`)
};

const setQuestionPosition = (testId: number, questionId: number, dstQuestionId: number) => (dispatch: any) => {
    let questions: Question[] = store.getState().quizes.questions.data;
    let oldIndex = 0;
    let newIndex = 0;
    for (let i = 0; i < questions.length; i++) {
        if (questions[i].id === questionId) {
            oldIndex = i;
        }
        if (questions[i].id === dstQuestionId) {
            newIndex = i;
        }
    }
    dispatch(questionsListLoadingSuccess(arrayMove(questions, oldIndex, newIndex), store.getState().quizes.questions.count));

    return ApiClient.post(`/admin/tests/${testId}/questions/${questionId}/position`, {dstQuestionId: dstQuestionId})
        .then(function (response) {
            return response.data
        });
};

const fetchAnswers = (testId: number, questionId: number) => (dispatch: any) => {
    dispatch(answersListLoad());

    return ApiClient.get(`/admin/tests/${testId}/questions/${questionId}/answers`)
        .then(function (response) {
            dispatch(answersListLoadingSuccess(response.data.data, response.data.meta.total));

            return response.data;
        })
};

const answersListLoad = () => ({
    type: types.TESTS_ANSWERS_LOAD,
    payload: {}
});


const answersListLoadingSuccess = (answers: Answer[], count: number) => ({
    type: types.TESTS_ANSWERS_LOAD_COMPLETED,
    payload: {
        data: answers,
        count: count
    }
});

const fetchAnswer = (testId: number, questionId: number, answerId: number) => {
    return ApiClient.get(`/admin/tests/${testId}/questions/${questionId}/answers/${answerId}`)
        .then(function (response) {
            return response.data;
        })
};

const createAnswer = (testId: number, questionId: number, dto: AnswerDto) => {
    return ApiClient.post(`/admin/tests/${testId}/questions/${questionId}/answers`, dto)
        .then(function (response) {
            return response.data
        });
};

const updateAnswer = (testId: number, questionId: number, answerId: number, dto: AnswerDto) => {
    return ApiClient.put(`/admin/tests/${testId}/questions/${questionId}/answers/${answerId}`, dto)
};

const deleteAnswer = (testId: number, questionId: number, answerId: number) => (dispatch: any) => {
    const answers = store.getState().quizes.answers.data;
    dispatch(answersListLoadingSuccess(answers.filter( a => a.id !== answerId), store.getState().quizes.answers.count));
    return ApiClient.delete(`/admin/tests/${testId}/questions/${questionId}/answers/${answerId}`)
};

const setAnswerPosition = (testId: number, questionId: number, answerId: number, dstAnswerId: number) => (dispatch: any) => {
    let answers: Answer[] = store.getState().quizes.answers.data;
    let oldIndex = 0;
    let newIndex = 0;
    for (let i = 0; i < answers.length; i++) {
        if (answers[i].id === answerId) {
            oldIndex = i;
        }
        if (answers[i].id === dstAnswerId) {
            newIndex = i;
        }
    }
    dispatch(answersListLoadingSuccess(arrayMove(answers, oldIndex, newIndex), store.getState().quizes.answers.count));

    return ApiClient.post(`/admin/tests/${testId}/questions/${questionId}/answers/${answerId}/position`, {dstAnswerId: dstAnswerId})
        .then(function (response) {
            return response.data
        });
};

const fetchResults = (testId: number) => (dispatch: any) => {
    dispatch(resultsListLoad());

    return ApiClient.get(`/admin/tests/${testId}/results`,)
        .then(function (response) {
            dispatch(resultsListLoadingSuccess(response.data.data, response.data.meta.total));

            return response.data;
        });
};

const resultsListLoad = () => ({
    type: types.TESTS_RESULTS_LOAD,
    payload: {}
});

const resultsListLoadingSuccess = (results: Result[], count: number) => ({
    type: types.TESTS_RESULTS_LOAD_COMPLETED,
    payload: {
        data: results,
        count: count,
    }
});

const fetchResult = (testId: number, resultId: number) => {
    return ApiClient.get(`/admin/tests/${testId}/results/${resultId}`)
        .then(function (response) {
            return response.data;
        })
};

const deleteResult = (testId: number, resultId: number) => (dispatch: any) => {
    const results = store.getState().quizes.results.data;
    dispatch(resultsListLoadingSuccess(results.filter( a => a.id !== resultId), store.getState().quizes.results.count));
    return ApiClient.delete(`/admin/tests/${testId}/results/${resultId}`)
        .then(function (response) {
            return response.data;
        })
};

const createResult = (testId: number, dto: ResultDto) => {
    return ApiClient.post(`/admin/tests/${testId}/results`, dto)
        .then(function (response) {
            return response.data
        });
};

const updateResult = (testId: number, resultId: number, dto: ResultDto) => {
    return ApiClient.put(`/admin/tests/${testId}/results/${resultId}`, dto)
};

const setResultPosition = (testId: number, resultId: number, dstResultId: number) => (dispatch: any) => {
    let results: Result[] = store.getState().quizes.results.data;
    let oldIndex = 0;
    let newIndex = 0;
    for (let i = 0; i < results.length; i++) {
        if (results[i].id === resultId) {
            oldIndex = i;
        }
        if (results[i].id === dstResultId) {
            newIndex = i;
        }
    }
    dispatch(resultsListLoadingSuccess(arrayMove(results, oldIndex, newIndex), store.getState().quizes.results.count));

    return ApiClient.post(`/admin/tests/${testId}/results/${resultId}/position`, {dstResultId: dstResultId})
        .then(function (response) {
            return response.data
        });
};


export const testsActions = {
    fetchTests,
    fetchTest,
    createTest,
    updateTest,

    fetchQuestions,
    fetchQuestion,
    createQuestion,
    updateQuestion,
    deleteQuestion,
    setQuestionPosition,

    fetchAnswers,
    fetchAnswer,
    createAnswer,
    updateAnswer,
    deleteAnswer,
    setAnswerPosition,

    fetchResults,
    fetchResult,
    createResult,
    updateResult,
    deleteResult,
    setResultPosition,
};
import {CountryDto} from "common/models";
import {ApiClient} from "../../api-client.ts";

const fetchCountries = () => {
    return ApiClient.get('/admin/common/countries/')
        .then(function (response) {
            return response.data;
        })
};

const createCountry = (dto: CountryDto) => {
    return ApiClient.post('/admin/common/countries/', dto)
        .then(function (response) {
            return response.data
        });
};

export const countriesActions = {
    fetchCountries,
    createCountry
};
import * as React from 'react';
import {Form, FormSpy} from "react-final-form";
import {
    BlockTextField,
    FieldError,
    TextField
} from "common/components";
import arrayMutators from 'final-form-arrays';
import {helpers} from "common/helpers";
import {FORM_ERROR} from "final-form";
import {TagsField} from "./TagsField";
import {Kind} from "../types";
import {SelectionDto} from "../models/SelectionDto";
import {Button} from "@mui/material";


interface SelectionFormProps {
    kind: Kind
    initial?: SelectionDto,
    onSubmit: (values: SelectionDto) => any,
}

export const SelectionForm: React.FC<SelectionFormProps> = (props: SelectionFormProps) => {
    const storageKey = `selectionForm-${props.kind}`;

    const onChange = (formData: any) => {
        if (!props.initial) {
            const valuesJson = JSON.stringify(formData.values);
            localStorage.setItem(storageKey, valuesJson);
        }
    };

    // @ts-ignore
    let initial: any = props.initial;
    if (initial === undefined) {
        const fromStorage = localStorage.getItem(storageKey);
        if (fromStorage !== null) {
            initial = JSON.parse(fromStorage);
        } else {
            initial = {
                data: {
                    "time" : parseInt(String(new Date().getTime() / 1000)),
                    "blocks" : [],
                    "version" : "2.20.2"
                }
            };
        }
    } else {
        initial.data = helpers.editorContentToView(initial.data);
    }

    const onSubmit = async (formData: any) => {
        formData.data = helpers.editorContentFromView(formData.data);
        const dto = formData as SelectionDto;
        dto.kind = props.kind;

        try {
            await props.onSubmit(dto);
            if (!props.initial) {
                localStorage.removeItem(storageKey);
            }
        } catch (error) {
            // @ts-ignore
            if (!error.response) return Promise.reject({ [FORM_ERROR]: error });

            // @ts-ignore
            let errors = error.response.data.errors;
            if (errors.common) {
                return { [FORM_ERROR]: errors.common };
            }

            return errors;
        }
    };

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initial}
            mutators={{
                ...arrayMutators
            }}
            render={({
                handleSubmit, submitError, form: {
                    mutators: {push, pop}
                },
                     }) => {
                let submit: (values: any) => any = handleSubmit;
                return (
                    <>
                        {onChange &&
                        <FormSpy onChange={onChange} subscription={{values: true}}/>
                        }
                        <form onSubmit={handleSubmit}>
                            <TextField
                                label={"Meta title"}
                                name={"title"}
                            />
                            <TextField
                                label={"Meta description"}
                                name={"description"}
                                multiline
                            />
                            <TextField
                                label={"Название"}
                                name={"name"}
                                required
                            />
                            <TextField
                                label={"Название короткое"}
                                name={"nameShort"}
                                required
                            />
                            <TextField
                                label={"Слаг (фрагмент url)"}
                                name={"slug"}
                                disabled={!!(props.initial && props.initial.id)}
                            />
                            <TagsField name={'params'} label={'Параметры'} />
                            <BlockTextField name={"data"} label={"Текст"} required />
                            {submitError && <FieldError text={submitError}/>}
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={event => {
                                    submit(event);
                                }}
                            >
                                Сохранить
                            </Button>
                        </form>
                    </>
                )
            }}/>
    );
};


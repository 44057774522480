import {types} from './types';
import {Post, PostDto} from "posts/models";
import {PostsFilters} from "posts/filters";
import {store} from "store";
import {ApiClient} from "common/api-client.ts";

const buildUrl = require('build-url');

const fetchPosts = (params?: PostsFilters) => (dispatch: any) => {
    let queryParams = new URLSearchParams();
    if (!params) {
        params = store.getState().posts.posts.params;
    }
    Object.entries(params).forEach(([key, value]) => {
        queryParams.append(key, value)
    });

    let url = buildUrl('', {
        path: '/posts',
        queryParams: Object.fromEntries(queryParams),
    });
    dispatch(postsListLoad(params));

    return ApiClient.get(url)
        .then(function (response) {
            dispatch(postsListLoadingSuccess(response.data.data, response.data.meta.total));
            return response.data;
        });
};

const postsListLoad = (params: PostsFilters) => ({
    type: types.POSTS_LOAD,
    payload: {
        params: params,
    }
});

const postsListLoadingSuccess = (posts: Post[], count: number) => ({
    type: types.POSTS_LOAD_COMPLETED,
    payload: {
        data: posts,
        count: count
    }
});

const fetchPost = (id: number) => {
    return ApiClient.get(`/admin/posts/${id}/`)
        .then(function (response) {
            return response.data;
        })
};

const createPost = (dto: PostDto) => {
    return ApiClient.post('/admin/posts', dto)
        .then(function (response) {
            return response.data
        });
};

const updatePost = (id: number, dto: PostDto) => {
    return ApiClient.put(`/admin/posts/${id}`, dto)
};

export const postsActions = {
    fetchPosts,
    fetchPost,
    createPost,
    updatePost,
};
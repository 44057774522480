import * as React from 'react';
import {Layout} from "./Layout";
import {Pages} from "./SidebarMenu";

export function MainPage() {
    return (
        <Layout page={Pages.Posts} headline={"Главная страница"}>

        </Layout>
    )
}
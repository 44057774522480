import * as React from 'react';
import {useEffect, useState} from 'react';
import {Layout, Pages} from "common/components";
import cogoToast from "cogo-toast";
import {Category, CategoryDto} from "../models";
import {categoriesActions} from "../store/actions/CategoriesActions";
import {FishCategoryForm} from "./FishCategoryForm";
import {Breadcrumb} from "../../common/models";
import {redirect, useParams} from "react-router";
import {LinearProgress} from "@mui/material";
import Grid from "@mui/material/Grid";

export const FishCategoryEditPage = () => {
    const {id} = useParams();

    const [category, setCategory] = useState<Category | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUpdated, setIsUpdated] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const response = await categoriesActions.fetchCategory(Number(id));
            setCategory(response.data);
            setIsLoading(false);
        };
        setIsLoading(true);
        fetchData();

        window.scrollTo(0, 0);

    }, [id]);

    const onSubmit = async (dto: CategoryDto) => {
        await categoriesActions.updateCategory(Number(id), dto);
        cogoToast.success('Категория успешно изменена', {position: 'bottom-left'});
        setIsUpdated(true);
    };

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Рыбы",
                href: "/fishes"
            },
            {
                name: "Категории",
                href: "/fishes/categories"
            },
        ];

        if (category) {
            breadcrumbs.push({
                name: category.name,
            },)
        }

        return breadcrumbs;
    };

    if (isUpdated) {
        redirect("/fishes/categories");
    }

    return (
        <Layout
            page={Pages.Fishes}
            headline={"Редактирование " + ((category && !category.parent)?'отряда':'семейства')}
            breadcrumbs={getBreadcrumbs()}
        >
            {isLoading ? <LinearProgress/> :
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {category && (
                            <FishCategoryForm onSubmit={onSubmit} initial={category}/>
                        )}
                    </Grid>
                    <Grid item xs={6}>

                    </Grid>
                </Grid>
            }
        </Layout>
    );
};


import * as React from 'react';
import {useEffect, useState} from 'react';
import commonStyles from "common/styles";
import {Link} from "react-router-dom";
import {ActionButton, Layout, Pages} from "common/components/index";
import {Breadcrumb} from "../models";
import {selectionsAPI} from "../api/selections";
import {SelectionDto} from "../models/SelectionDto";
import {Kind} from "../types";
import {
    arrayMove,
    SortableContainer,
    SortableContainerProps,
    SortableElement,
    SortableElementProps,
    SortableHandle
} from "react-sortable-hoc";
import {TableRow} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import TableBody from "@mui/material/TableBody";
import Chip from "@mui/material/Chip";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import {useParams} from "react-router";
import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from "@mui/material";


const useStyles = makeStyles((theme: Theme) => createStyles({
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    link: {
        ...commonStyles.link,
    },
    data: {
        marginTop: "0.5rem"
    },
    head: {
        backgroundColor: "#fff",
        position: "sticky",
        top: 0
    },
}));

interface ISortableContainer extends SortableContainerProps {
    children: React.ReactNode
}

const TableBodySortable: React.ComponentClass<ISortableContainer> = SortableContainer((props: ISortableContainer) => (
    <TableBody>
        {props.children}
    </TableBody>
));

const DragHandle = SortableHandle(() => <DragIndicatorIcon style={{cursor: 'pointer', display: 'block'}} />);

interface RowProps extends SortableElementProps {
    selection: SelectionDto
}

const Row: React.ComponentClass<RowProps, any> = SortableElement((props: RowProps) => {
    const {selection} = props;
    return <TableRow>
        <TableCell style={{ width: '5%' }}>
            <DragHandle />
        </TableCell>
        <TableCell>{selection.id}</TableCell>
        <TableCell>{selection.name}</TableCell>
        <TableCell>
            <React.Fragment>
                {selection.params.map((param: string) => (
                    <Chip key={param} label={param}/>
                ))}
            </React.Fragment>
        </TableCell>
        <TableCell>
            <ActionButton
                tooltip={"Редактировать подборку"}
                icon={<EditIcon/>}
                url={`/${selection.kind}/selections/${selection.id}`}/>
            <ActionButton icon={<VisibilityIcon/>}
                          url={`https://animalpaw.ru/${selection.kind}/${selection.slug}`}/>
        </TableCell>
    </TableRow>
});

export const SelectionsListPage = () => {
    const { kind } = useParams();
    const classes = useStyles();
    const [selections, setSelections] = useState<SelectionDto[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            const response = await selectionsAPI.fetchAll(kind as Kind);
            setSelections(response.data);
            setIsLoading(false);
        };
        setIsLoading(true);
        fetchData();

        window.scrollTo(0, 0);

    }, [kind]);

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
        ];
        switch (kind) {
            case Kind.dogs:
                breadcrumbs.push({
                    name: "Собаки",
                    href: "/dogs"
                })
                break;
            case Kind.cats:
                breadcrumbs.push({
                    name: "Кошки",
                    href: "/cats"
                })
                break;
            case Kind.fishes:
                breadcrumbs.push({
                    name: "Рыбки",
                    href: "/fishes"
                })
                break;
        }
        breadcrumbs.push({
            name: "Подборки",
        });

        return breadcrumbs;
    };

    const getPage = (k: Kind): Pages => {
        switch (k) {
            case Kind.dogs:
                return Pages.Dogs;
            case Kind.cats:
                return Pages.Cats;
            case Kind.fishes:
                return Pages.Fishes;
        }
    }

    const onSortEnd = async (props: any) => {
        await selectionsAPI.setPosition(selections[props.oldIndex].id as number, selections[props.newIndex].id as number);
        setSelections(arrayMove(selections, props.oldIndex, props.newIndex));
    };

    return (
        <Layout page={getPage(kind as Kind)} headline={"Подборки"} breadcrumbs={getBreadcrumbs()}>
            <Link to={`/${kind}/selections/create`} style={{textDecoration: 'none'}}>
                <Button variant="outlined" color="primary">
                    <AddIcon className={classes.leftIcon}/> Создать
                </Button>
            </Link>
            <div className={classes.data}>
                {isLoading ?
                    <LinearProgress/> :
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '5%' }}>&nbsp;</TableCell>
                                <TableCell>#</TableCell>
                                <TableCell>Название</TableCell>
                                <TableCell>Параметры</TableCell>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBodySortable onSortEnd={onSortEnd} useDragHandle lockAxis={'y'}>
                            {selections.map((selection, index) => {
                                return <Row
                                    index={index}
                                    key={selection.id}
                                    selection={selection}
                                />
                            })}
                        </TableBodySortable>
                    </Table>
                }
            </div>
        </Layout>
    );
};


import * as React from 'react';
import {Form, FormSpy} from "react-final-form";
import {CheckboxField, TextField} from "common/components";
import {Answer, AnswerDto} from "../models";
import {FORM_ERROR} from "final-form";
import arrayMutators from "final-form-arrays";
import {Button} from "@mui/material";

interface Props {
    initial?: Answer,
    onSubmit: (values: AnswerDto) => any,
}

export const AnswerForm: React.FC<Props> = (props: Props) => {
    const storageKey = 'answerForm';

    const onChange = (formData: any) => {
        if (!props.initial) {
            const valuesJson = JSON.stringify(formData.values);
            localStorage.setItem(storageKey, valuesJson);
        }
    };

    // @ts-ignore
    let initial: Answer | undefined = props.initial;
    if (initial === undefined) {
        const fromStorage = localStorage.getItem(storageKey);
        if (fromStorage !== null) {
            initial = JSON.parse(fromStorage);
        }
    }

    const onSubmit = async (formData: any) => {
        let dto = {
            text: formData.text,
            comment: formData.comment,
            isCorrect: Boolean(formData.isCorrect)
        } as AnswerDto;

        try {
            await props.onSubmit(dto);
            if (!props.initial) {
                localStorage.removeItem(storageKey);
            }
        } catch (error) {
            // @ts-ignore
            if (!error.response) return Promise.reject({ [FORM_ERROR]: error });

            // @ts-ignore
            let errors = error.response.data.errors;
            if (errors.common) {
                return { [FORM_ERROR]: errors.common };
            }

            return errors;
        }
    };

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initial}
            mutators={{
                ...arrayMutators
            }}
            render={({
                         handleSubmit, submitError, form: {
                    mutators: {push, pop}
                },
                     }) => {
                let submit: (values: any) => any = handleSubmit;
                return (
                    <>
                        {onChange &&
                        <FormSpy onChange={onChange} subscription={{values: true}}/>
                        }
                        <form onSubmit={handleSubmit}>
                            {props.initial && props.initial.id &&
                            <TextField
                                label={"ID"}
                                name={"id"}
                                disabled
                            />
                            }
                            <TextField
                                name={"text"}
                                label={"Ответ"}
                                required
                            />
                            <TextField
                                name={"comment"}
                                label={"Комментарий"}
                                multiline
                            />
                            <CheckboxField
                                name={"isCorrect"}
                                label={"Правильный ответ"}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={event => {
                                    submit(event);
                                }}
                            >
                                Сохранить
                            </Button>
                        </form>
                    </>
                )
            }}/>
    );
};


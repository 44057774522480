import {combineReducers} from 'redux';
import {PostsReducer} from "./PostsReducer";
import {CategoriesState, PostsAppState} from "../states";
import {CategoriesTreeReducer} from "./CategoriesTreeReducer";

const CategoriesReducer = combineReducers<CategoriesState>({
    tree: CategoriesTreeReducer,
});

export const PostsAppReducer = combineReducers<PostsAppState>({
    categories: CategoriesReducer,
    posts: PostsReducer,
});
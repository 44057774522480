import {Post} from "posts/models";
import {DogsFilters} from "../../filters";

export const types = {
    DOGS_LOAD: 'DOGS_LOAD',
    DOGS_LOAD_COMPLETED: 'DOGS_LOAD_COMPLETED',
};

export interface DogsLoadAction {
    type: typeof types.DOGS_LOAD,
    payload: {
        params: DogsFilters,
    }
}

export interface DogsLoadCompletedAction {
    type: typeof types.DOGS_LOAD_COMPLETED
    payload: {
        data: Post[]
        count: number
    }
}

import * as React from 'react';
import {useEffect} from 'react';
import commonStyles from "common/styles";
import { styled } from '@mui/material/styles';
import {Pages} from "./SidebarMenu";
import {Link, Navigate} from "react-router-dom";
import {Breadcrumb} from "../models";
import {Breadcrumbs} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {usersActions} from "../../users/store/actions";
import {StateInterface} from "../../reducers";
import MenuIcon from '@mui/icons-material/Menu';
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from "@mui/material/Toolbar";
import List from '@mui/material/List';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {FaCat, FaDog, FaUsers} from "react-icons/fa";
import {GiTropicalFish} from "react-icons/gi";

const container = commonStyles.container;

const useStyles = makeStyles((theme: Theme) => createStyles({
    '@global': {
        body: {
            margin: 0,
            //backgroundColor: '#3C3F41'
        }
    },
    wrapper: {
        position: "relative",
        top: "0",
        height: "100vh",
        //backgroundColor: commonStyles.backgroundColor
    },
    mainPanel: {
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${commonStyles.drawerWidth}px)`
        },
        //overflow: "auto",
        position: "relative",
        float: "right",
        ...commonStyles.transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: 'touch',
    },
    container,
    content: {
        marginTop: "70px",
        padding: "30px 15px",
        minHeight: "calc(100% - 123px)"
    },
    pageContent: {
        marginTop: "0.5rem",
        padding: '1rem'
    },
    headline: {
        color: commonStyles.successColor,
    },
    link: {
        ...commonStyles.link,
    },
}));

interface LayoutProps {
    page: Pages
    breadcrumbs?: Breadcrumb[]
    headline?: string
    children?: any
}
const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

let menuItems = [
    {
        name: "Статьи",
        path: "/posts",
        icon: ArtTrackIcon,
        page: Pages.Posts,
    },
    {
        name: "Тесты",
        path: "/tests",
        icon: FormatListBulletedIcon,
        page: Pages.Tests,
    },
    {
        name: "Собаки",
        path: "/dogs",
        icon: FaDog,
        page: Pages.Dogs,
    },
    {
        name: "Кошки",
        path: "/cats",
        icon: FaCat,
        page: Pages.Cats,
    },
    {
        name: "Рыбы",
        path: "/fishes",
        icon: GiTropicalFish,
        page: Pages.Fishes,
    },
    {
        name: "Пользователи",
        path: "/users",
        icon: FaUsers,
        page: Pages.Users,
    },
];

export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState( localStorage.getItem("is-menu-opened") === "1");
    const toggleDrawer = () => {
        setOpen(!open);
        localStorage.setItem("is-menu-opened", String(open?0:1));
    };

    const dispatch = useDispatch();
    useEffect(() => {
        // @ts-ignore
        dispatch(usersActions.autologin());
    }, [dispatch]);

    const userData = useSelector((state: StateInterface) => state.users.auth.user);

    if (userData === null) {
        return <Navigate to={"/login"}/>
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="absolute" open={open}>
                <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        {props.headline}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    {menuItems.map((prop, key) => {
                       return <Link to={prop.path} style={{textDecoration: 'none', color: 'initial'}}>
                           <ListItemButton>
                               <ListItemIcon>
                                   <prop.icon/>
                               </ListItemIcon>
                               <ListItemText primary={prop.name} />
                           </ListItemButton>
                       </Link>
                    })}
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar />
                <div className={classes.pageContent}>
                    {props.breadcrumbs &&
                    <>
                        <Breadcrumbs>
                            {props.breadcrumbs.map((bc: Breadcrumb, i: number) => {
                                // @ts-ignore
                                if (i + 1 === props.breadcrumbs.length || !bc.href) {
                                    return <Typography key={i} color="textPrimary">{bc.name}</Typography>
                                }
                                return <Link to={bc.href}
                                             key={i}
                                             style={{textDecoration: 'none'}}
                                             className={classes.link}>{bc.name}</Link>;
                            })}
                        </Breadcrumbs>
                        <Divider/>
                    </>
                    }

                    {props.headline &&
                        <Typography variant={'h5'}>{props.headline}</Typography>
                    }
                    <div style={{padding: '0.5rem'}}>
                        {props.children}
                    </div>
                </div>
            </Box>
        </Box>
    );
};


import {types} from './types';
import {store} from "store";
import {ApiClient} from "common/api-client.ts";
import {FishesFilters} from "../../filters";
import {Fish, FishDto} from "../../models";

const buildUrl = require('build-url');

const fetchFishes = (params?: FishesFilters) => (dispatch: any) => {
    let queryParams = new URLSearchParams();
    if (!params) {
        params = store.getState().fishes.fishes.params;
    }
    Object.entries(params).forEach(([key, value]) => {
        queryParams.append(key, value)
    });

    let url = buildUrl(process.env.REACT_APP_BACKEND_API_URL, {
        path: '/admin/fishes/',
        queryParams: Object.fromEntries(queryParams),
    });
    dispatch(fishesListLoad(params));

    return ApiClient.get(url)
        .then(function (response) {
            dispatch(fishesListLoadingSuccess(response.data.data, response.data.meta.total));
            return response.data;
        });
};

const fishesListLoad = (params: FishesFilters) => ({
    type: types.FISHES_LOAD,
    payload: {
        params: params,
    }
});

const fishesListLoadingSuccess = (dogs: Fish[], count: number) => ({
    type: types.FISHES_LOAD_COMPLETED,
    payload: {
        data: dogs,
        count: count
    }
});

const fetchFish = (id: number) => {
    return ApiClient.get(`/admin/fishes/${id}`)
        .then(function (response) {
            return response.data;
        })
};

const createFish = (dto: FishDto) => {
    return ApiClient.post('/admin/fishes', dto)
        .then(function (response) {
            return response.data
        });
};

const updateFish = (id: number, dto: FishDto) => {
    return ApiClient.put(`/admin/fishes/${id}`, dto)
};

export const fishesActions = {
    fetchFishes,
    fetchFish,
    createFish,
    updateFish,
};
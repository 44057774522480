import {types} from './types';
import axios from 'axios';
import {CategoryTree} from "posts/models";

const buildUrl = require('build-url');

const fetchCategories = (params = {}) => (dispatch: any) => {
    let url = buildUrl(process.env.REACT_APP_BACKEND_API_URL, {
        path: 'posts/categories/'
    });

    dispatch(categoriesListLoadingProcess());
    return axios.get(url, {headers: {'Authorization': 'Token ' + localStorage.getItem('token')}})
        .then(function (response) {
            dispatch(categoriesListLoadingSuccess(response.data.data, response.data.meta));
            return response.data;
        })
        .catch(function (error) {
            //let errors = error.response.data;
            //return Promise.reject(FormHelpers.processErrors(errors));
        });
};

const categoriesListLoadingProcess = () => ({
    type: types.CATEGORIES_LOAD
});

const categoriesListLoadingSuccess = (tree: CategoryTree[], meta: any) => ({
    type: types.CATEGORIES_LOAD_COMPLETED,
    payload: {
        data: tree,
        meta: meta
    }
});

/*
const createCategory = (category: Category) => (dispatch: any) => {
    let url = buildUrl(process.env.BACKEND_API_URL, {
        path: 'posts/categories/'
    });

    let data = {
        name: category.name,
        description: category.description
    };

    if (category.parent_id !== 0) {
        data['parent_id'] = category.parent_id
    } else {
        data['parent_id'] = null;
    }

    dispatch(categoryCreateProcess());
    return axios.post(url, data, {headers: {'Authorization': 'Token ' + localStorage.getItem('token')}})
        .then(function (response) {
            dispatch(categoryCreateSuccess(response.data));
            return response.data
        })
        .catch(function (error) {
            let errors = error.response.data;
            return Promise.reject(FormHelpers.processErrors(errors));
        });
};

const categoryCreateProcess = () => ({
    type: types.CATEGORY_CREATE_PROCESS
});

const categoryCreateSuccess = (category: Category) => ({
    type: types.CATEGORY_CREATE_SUCCESS,
    payload: {
        data: category,
    }
});

*/

/*
const fetchCategory = (id: number) => (dispatch) => {
    const url = buildUrl(API_URL, {
        path: `posts/categories/${id}/`
    });

    dispatch(categoryLoadingProcess());
    return axios.get(url, {headers: {'Authorization': 'Token ' + localStorage.getItem('token')}})
        .then(function (response) {
            dispatch(categoryLoadingSuccess(response.data));
            return response.data
        })
        .catch(function (error) {
            let errors = error.response.data;
            return Promise.reject(FormHelpers.processErrors(errors));
        });
};

const categoryLoadingProcess = () => ({
    type: types.CATEGORY_LOAD_PROCESS
});

const categoryLoadingSuccess = (category: Category) => ({
    type: types.CATEGORY_LOAD_SUCCESS,
    payload: {
        data: category,
    }
});



const updateCategory = (category: Category) => (dispatch) => {
    let url = buildUrl(API_URL, {
        path: `posts/categories/${category.id}/`
    });

    let data = {
        name: category.name,
        description: category.description
    };

    if (category.parent_id != 0) {
        data['parent_id'] = category.parent_id
    } else {
        data['parent_id'] = null;
    }

    dispatch(categoryUpdateProcess());
    return axios.put(url, data, {headers: {'Authorization': 'Token ' + localStorage.getItem('token')}})
        .then(function (response) {
            dispatch(categoryUpdateSuccess(response.data));
            return response.data
        })
        .catch(function (error) {
            let errors = error.response.data;
            return Promise.reject(FormHelpers.processErrors(errors));
        });
};

const categoryUpdateProcess = () => ({
    type: types.CATEGORY_UPDATE_PROCESS
});

const categoryUpdateSuccess = (category: Category) => ({
    type: types.CATEGORY_UPDATE_SUCCESS,
    payload: {
        data: category,
    }
});




const removeCategory = (id: number) => (dispatch) => {
    const url = buildUrl(API_URL, {
        path: `posts/categories/${id}/`
    });

    dispatch(categoryRemoveProcess());
    return axios.delete(url, {headers: {'Authorization': 'Token ' + localStorage.getItem('token')}})
        .then(function (response) {
            dispatch(categoryRemoveSuccess());
            return response.data
        })
        .catch(function (error) {
            let errors = error.response.data;
            return Promise.reject(FormHelpers.processErrors(errors));
        });
};

const categoryRemoveProcess = () => ({
    type: types.CATEGORY_REMOVE_PROCESS
});

const categoryRemoveSuccess = () => ({
    type: types.CATEGORY_REMOVE_SUCCESS
});
*/
export const categoriesActions = {
    fetchCategories,
    /*fetchCategory,
    createCategory,
    updateCategory,
    removeCategory*/
};
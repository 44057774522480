import * as React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {LoginPage} from "users/components";
import {MainPage} from "./MainPage";
import {PostsCreatePage, PostsEditPage, PostsListPage} from "posts/components";
import {
    AnswerEditPage,
    AnswersListPage,
    QuestionCreatePage,
    QuestionsListPage,
    TestsCreatePage,
    TestsEditPage,
    TestsListPage,
} from "quizes/components";

import {DogCreatePage, DogEditPage, DogsListPage} from "../../dogs/components";
import {CatsListPage} from "../../cats/components";
import {CatCreatePage} from "../../cats/components/CatCreatePage";
import {CatEditPage} from "../../cats/components/CatEditPage";
import {
    FishCategoryEditPage,
    FishCreatePage,
    FishEditPage,
    FishesCategoriesListPage,
    FishesListPage
} from "../../fishes/components";
import {QuestionEditPage} from "../../quizes/components/QuestionEditPage";
import {AnswerCreatePage} from "../../quizes/components/AnswerCreatePage";
import {ResultsListPage} from "../../quizes/components/ResultsListPage";
import {ResultCreatePage} from "../../quizes/components/ResultCreatePage";
import {ResultEditPage} from "../../quizes/components/ResultEditPage";
import {SelectionsListPage} from "./SelectionsListPage";
import {SelectionEditPage} from "./SelectionEditPage";
import {SelectionCreatePage} from "./SelectionCreatePage";
import {UsersListPage} from "../../users/components/UsersListPage";

export const Router: React.FC = () => {


    /*<Route exact path="/breeds/cats/create" component={<CatsCreatePage/>}/>
    <Route exact path="/breeds/cats/:id" component={<CatsEditPage/>}/>
    <Route exact path="/breeds/fishes/categories" component={<FishesCategoriesListPage/>}/>
    <Route exact path="/breeds/fishes/categories/:id" component={<FishCategoryEditPage/>}/>
    <Route exact path="/breeds/fishes" component={<FishesListPage/>}/>
    <Route exact path="/breeds/fishes/create" component={<FishesCreatePage/>}/>
    <Route exact path="/breeds/fishes/:id" component={<FishesEditPage/>}/>*/

    /*<Route exact path="/tests/:test_id/results" component={<TestsResultsListPage} />
                <Route exact path="/tests/:test_id/results/create" component={<TestsResultCreatePage} />
                <Route exact path="/tests/:test_id/results/:result_id" component={<TestsResultEditPage} />
                <Route exact path="/tests/:test_id/questions" component={<QuestionsListPage_} />
                <Route exact path="/tests/:test_id/questions/create" component={<TestQuestionCreatePage} />
                <Route exact path="/tests/:test_id/questions/:question_id" component={<TestsQuestionsEditPage} />
                <Route exact path="/tests/:test_id/questions/:question_id/answers" component={<TestsAnswersListPage} />
                <Route exact path="/tests/:test_id/questions/:question_id/answers/create" component={<TestsAnswersCreatePage} />
                <Route exact path="/tests/:test_id/questions/:question_id/answers/:answer_id" component={<TestsAnswersEditPage} />*/
    //{!userData && <Navigate to={"/login"}/>}
    return (
        <BrowserRouter>
            <Routes>
            <Route path="*" element={<Routes>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/users" element={<UsersListPage/>}/>
                <Route path="/posts" element={<PostsListPage/>}/>
                <Route path="/posts/create" element={<PostsCreatePage/>}/>
                <Route path="/posts/:id" element={<PostsEditPage/>}/>
                <Route path="/tests" element={<TestsListPage/>}/>
                <Route path="/tests/create" element={<TestsCreatePage/>}/>
                <Route path="/tests/:id" element={<TestsEditPage/>}/>
                <Route path="/tests/:id/questions" element={<QuestionsListPage/>}/>
                <Route path="/tests/:testId/questions/create" element={<QuestionCreatePage/>}/>
                <Route path="/tests/:testId/questions/:questionId" element={<QuestionEditPage/>}/>
                <Route path="/tests/:testId/questions/:questionId/answers" element={<AnswersListPage/>}/>
                <Route path="/tests/:testId/questions/:questionId/answers/create" element={<AnswerCreatePage/>}/>
                <Route path="/tests/:testId/questions/:questionId/answers/:answerId" element={<AnswerEditPage/>}/>
                <Route path="/tests/:testId/results" element={<ResultsListPage/>}/>
                <Route path="/tests/:testId/results/create" element={<ResultCreatePage/>}/>
                <Route path="/tests/:testId/results/:resultId" element={<ResultEditPage/>}/>
                <Route path="/dogs" element={<DogsListPage/>}/>
                <Route path="/dogs/create" element={<DogCreatePage/>}/>
                <Route path="/:kind/selections" element={<SelectionsListPage/>}/>
                <Route path="/:kind/selections/create" element={<SelectionCreatePage/>}/>
                <Route path="/:kind/selections/:id" element={<SelectionEditPage/>}/>
                <Route path="/dogs/:id" element={<DogEditPage/>}/>
                <Route path="/cats" element={<CatsListPage/>}/>
                <Route path="/cats/create" element={<CatCreatePage/>}/>
                <Route path="/cats/:id" element={<CatEditPage/>}/>
                <Route path="/fishes" element={<FishesListPage/>}/>
                <Route path="/fishes/create" element={<FishCreatePage/>}/>
                <Route path="/fishes/categories/:id" element={<FishCategoryEditPage/>}/>
                <Route path="/fishes/categories" element={<FishesCategoriesListPage/>}/>
                <Route path="/fishes/:id" element={<FishEditPage/>}/>
                <Route path="/" element={<MainPage/>}/>
        </Routes>}/>
                </Routes>

        </BrowserRouter>
    );
};

import * as React from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import commonStyles from "common/styles";
import {Field} from "react-final-form";
import {FieldError} from "./FieldError";
import {FormControl} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {createStyles, makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => createStyles({
    formControl: {
        ...commonStyles.formControl
    },
}));

interface Props {
    name: string
    label: string
}

export const CheckboxField: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    const {name, label} = props;

    return (
        <Field
            name={name}
            component="input"
            type="text"
        >
            {({input, meta, ...rest}) => {
                const error = meta.error || meta.submitError;
                return <FormControl className={classes.formControl}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!!input.value}
                                onChange={input.onChange}
                                value={name}
                                color="primary"
                            />
                        }
                        label={label}
                    />
                    {meta.touched && error &&
                    <FieldError text={error}/>
                    }
                </FormControl>
            }}
        </Field>

    );
};


import * as React from 'react';
import {useEffect, useState} from 'react';
import {Layout, Pages} from "common/components";
import cogoToast from "cogo-toast";
import {Breadcrumb} from "../models";
import {SelectionDto} from "../models/SelectionDto";
import {selectionsAPI} from "../api/selections";
import {Kind} from "../types";
import {SelectionForm} from "./SelectionForm";
import {redirect, useParams} from "react-router";
import {LinearProgress} from "@mui/material";
import Grid from "@mui/material/Grid";

export const SelectionEditPage = () => {
    const {id, kind} = useParams();
    const [selection, setSelection] = useState<SelectionDto | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            const response = await selectionsAPI.fetchOne(Number(id));
            setSelection(response.data);
            setIsLoading(false);
        };
        setIsLoading(true);
        fetchData();

        window.scrollTo(0, 0);

    }, [id]);

    const [isUpdated, setIsUpdated] = useState(false);

    const onSubmit = async (dto: SelectionDto) => {
        dto.id = Number(id);
        await selectionsAPI.updateSelection(dto);
        cogoToast.success('Подборка успешно изменена', {position: 'bottom-left'});
        setIsUpdated(true);
        window.scrollTo(0, 0);
    };

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
        ];
        switch (kind) {
            case Kind.dogs:
                breadcrumbs.push({
                    name: "Собаки",
                    href: "/dogs"
                },{
                    name: "Подборки",
                    href: "/dogs/selections"
                })
                break;
            case Kind.cats:
                breadcrumbs.push({
                    name: "Кошки",
                    href: "/cats"
                },{
                    name: "Подборки",
                    href: "/cats/selections"
                })
                break;
            case Kind.fishes:
                breadcrumbs.push({
                    name: "Рыбки",
                    href: "/fishes"
                },{
                    name: "Подборки",
                    href: "/fishes/selections"
                })
                break;
        }
        if (selection) {
            breadcrumbs.push({
                name: `${selection.nameShort}`,
            });
        }

        return breadcrumbs;
    };

    const getRedirectUrl = (k: Kind): string => {
        switch (k) {
            case Kind.dogs:
                return '/dogs/selections';
            case Kind.cats:
                return '/cats/selections';
            case Kind.fishes:
                return '/fishes/selections';
        }
    };

    const getPage = (k: Kind): Pages => {
        switch (k) {
            case Kind.dogs:
                return Pages.Dogs;
            case Kind.cats:
                return Pages.Cats;
            case Kind.fishes:
                return Pages.Fishes;
        }
    }

    if (isUpdated) {
        redirect(getRedirectUrl(kind as Kind));
    }

    return (
        <Layout page={getPage(kind as Kind)} headline={"Редактирование подборки"} breadcrumbs={getBreadcrumbs()}>
            {isLoading ? <LinearProgress/> :
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {selection && (
                            <SelectionForm
                                kind={kind as Kind}
                                onSubmit={onSubmit}
                                initial={selection}
                            />
                        )}
                    </Grid>
                    <Grid item xs={6}>

                    </Grid>
                </Grid>
            }
        </Layout>
    );
};


import {PostsLoadAction, PostsLoadCompletedAction, PostsLoadTypes, types} from "posts/store/actions/types";
import {PostsState} from "../states";

const initialState = (): PostsState => ({
    data: [],
    count: 0,
    params: {
        limit: 12,
        offset: 0,
        order: '-id'
    },
});

export const PostsReducer = (state = initialState(), action: PostsLoadTypes) => {
    switch (action.type) {
        case types.POSTS_LOAD:
            return Object.assign({}, state, {
                params: (action as PostsLoadAction).payload.params
            });
        case types.POSTS_LOAD_COMPLETED:
            const {data, count} = (action as PostsLoadCompletedAction).payload;
            return Object.assign({}, state, {
                data: data,
                count: count,
            });
    }
    return state;
};
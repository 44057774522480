import {types} from "../actions/types";
import {CountriesState} from "../states";

const initialState = (): CountriesState => ({
    data: [],
    count: 0,
    isLoading: false,
    errors: []
});

export const CountriesReducer = (state = initialState(), action: any) => {
    switch (action.type) {
        case types.COUNTRIES_LOAD:
            return Object.assign({}, state, {
                isLoading: true
            });
        case types.COUNTRIES_LOAD_COMPLETED:
            return Object.assign({}, state, {
                isLoading: false,
                data: action.payload.data,
                count: action.payload.count,
            });
    }
    return state;
};
import {
    types
} from "../actions/types";
import {AuthState} from "../states/AuthState";
import jwt_decode from "jwt-decode";

const initialState = (): AuthState => {
    return {
        user: undefined
    };
};

export const AuthReducer = (state = initialState(), action: any) => {
    switch (action.type) {
        case types.LOGIN:
            const { accessToken } = action.payload;
            const data: any = jwt_decode(accessToken);
            return {
                ...state,
                user: data?data.user:null,
            };
        case types.LOGOUT:
            return {
                ...state,
                user: null
            };
    }
    return state;
};
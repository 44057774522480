import * as React from 'react';
import {useEffect, useState} from 'react';
import {redirect, useParams} from "react-router";
import {testsActions} from "../store/actions";
import {Layout, Pages} from "common/components";
import {QuestionForm} from "./QuestionForm";
import {Breadcrumb} from "../../common/models";
import {TestDetailed} from "../models/TestDetailed";
import {Question, QuestionDto} from "../models";
import cogoToast from "cogo-toast";
import {LinearProgress} from "@mui/material";
import Grid from "@mui/material/Grid";

export const QuestionEditPage = () => {
    const {testId, questionId} = useParams();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [test, setTest] = useState<TestDetailed | null>(null);
    const [question, setQuestion] = useState<Question | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const testResponse = await testsActions.fetchTest(Number(testId));
            setTest(testResponse.data);

            const questionResponse = await testsActions.fetchQuestion(Number(testId), Number(questionId));
            setQuestion(questionResponse.data);
            setIsLoading(false);
        };
        fetchData();
    }, [testId, questionId]);

    const [isUpdated, setIsUpdated] = useState(false);

    const onsubmit = async (dto: QuestionDto) => {
        await testsActions.updateQuestion(Number(testId), Number(questionId), dto);
        cogoToast.success('Вопрос успешно изменен', {position: 'bottom-left'});
        setIsUpdated(true);
    };

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Тесты",
                href: "/tests"
            },
        ];

        if (test) {
            breadcrumbs.push({
                name: test.name,
                href: `/tests/${testId}/questions`
            },{
                name: "Редактирование",
            },)
        }

        return breadcrumbs;
    };

    if (isUpdated) {
        redirect(`/tests/${testId}/questions`)
    }
    return (
        <Layout page={Pages.Tests} headline={`Редактирование вопроса теста "${test && test.name}"`} breadcrumbs={getBreadcrumbs()}>
            {isLoading ? <LinearProgress/> :
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {question && (
                            <QuestionForm onSubmit={onsubmit} initial={question}/>
                        )}
                    </Grid>
                    <Grid item xs={6}>

                    </Grid>
                </Grid>
            }
        </Layout>
    );
};


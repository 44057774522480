import * as React from 'react';
import {useEffect, useState} from 'react';
import {Category} from "../models";
import commonStyles from "common/styles";
import {FieldError} from "common/components";
import {Field} from "react-final-form";
import {categoriesActions} from "../store/actions/CategoriesActions";
import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import LinearProgress from "@mui/material/LinearProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";


const useStyles = makeStyles((theme: Theme) => createStyles({
    formControl: {
        ...commonStyles.formControl
    },
    addToListBtn: {
        float: "right",
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
    },
    dialogActionBtns: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    progress: {
        marginTop: "50px"
    }
}));

interface Props {
    name: string
    label: string
    required?: boolean
}

export const FamilySelector: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const [families, setFamilies] = useState<Category[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchFamilies = async () => {
        const response = await categoriesActions.fetchCategories();
        let items: Category[] = [];
        for (let i = 0; i < response.data.length; i++) {
            for (let j = 0; j < response.data[i].children.length; j++) {
                const family = response.data[i].children[j];
                items.push(family);
            }
        }
        setFamilies(items);
        setIsLoading(false);
    };

    useEffect(() => {
        setIsLoading(true);
        fetchFamilies();
    }, []);

    return (
        <Field
            name={props.name}
            component="input"
            type="text"
        >
            {({input, meta, ...rest}) => {
                const error = meta.error || meta.submitError;

                const handleChange = (value: any) => {
                    input.onChange(value);
                };

                return <FormControl className={classes.formControl}>
                    <InputLabel
                        shrink={true}
                        required={!!props.required} error={meta.touched && !!error}
                    >{props.label}</InputLabel>
                    {isLoading ?
                        <div className={classes.progress}>
                            <LinearProgress/>
                        </div>
                        :
                        <Select
                            {...input} {...rest}
                            required={!!props.required}
                            onChange={(event) => handleChange(event.target.value)}
                            displayEmpty
                        >
                            <MenuItem value="">
                                <em>{"\u2014"} Нет {"\u2014"}</em>
                            </MenuItem>
                            {families.map((item: Category) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    }
                    {meta.touched && error &&
                    <FieldError text={error}/>
                    }
                </FormControl>
            }}
        </Field>
    );
};


import {Post} from "posts/models";
import {FishesFilters} from "../../filters";

export const types = {
    FISHES_LOAD: 'FISHES_LOAD',
    FISHES_LOAD_COMPLETED: 'FISHES_LOAD_COMPLETED',

    DOG_LOAD: 'DOG_LOAD',
    DOG_LOAD_COMPLETED: 'DOG_LOAD_COMPLETED',

};

export interface FishesLoadAction {
    type: typeof types.FISHES_LOAD,
    payload: {
        params: FishesFilters,
    }
}

export interface FishesLoadCompletedAction {
    type: typeof types.DOG_LOAD_COMPLETED
    payload: {
        data: Post[]
        count: number
    }
}

export type FishesLoadTypes = FishesLoadAction | FishesLoadCompletedAction

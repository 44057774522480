import * as React from 'react';
import {Field} from "react-final-form";
import {FieldError} from "./index";
import commonStyles from "common/styles";
import {FormControl} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
    formControl: {
        ...commonStyles.formControl
    },
});

export interface Choice {
    key: string | number;
    value: string | number;
}

interface Props {
    name: string
    label: string
    choices: Choice[]
    required?: boolean
    multiple?: boolean
}

export const SelectField: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    const {name, label} = props;

    const multiple = !!props.multiple;
    const required = !!props.required;

    return (
        <Field
            name={name}
            component="input"
            type="number"
            required={required}
        >
            {({input, meta, ...rest}) => {
                const error = meta.error || meta.submitError;
                let value = input.value;
                if (!value) {
                    value = [];
                }

                if (!Array.isArray(value)) {
                    value= [value];
                }

                return <FormControl className={classes.formControl}>
                    <InputLabel
                        error={meta.touched && !!error}
                        required={required}
                    >{label}</InputLabel>
                    <Select
                        {...input} {...rest}
                        value={value}
                        required={required}
                        multiple={multiple}
                        error={!!meta.error || !!meta.submitError}
                    >
                        {!props.multiple && <MenuItem value="">
                            <>{"\u2014"} Нет {"\u2014"}</>
                        </MenuItem>}
                        {props.choices.map((choice: Choice) => (
                            <MenuItem key={choice.key} value={choice.key}>
                                {choice.value}
                            </MenuItem>
                        ))}
                    </Select>
                    {meta.touched && error &&
                        <FieldError text={error}/>
                    }
                </FormControl>
            }}
        </Field>
    );
};


import * as React from 'react';
import {Form, FormSpy} from "react-final-form";
import {
    BlockTextField,
    CheckboxField,
    FieldError,
    ImageField,
    RangeField,
    SelectField,
    SimpleArrayField,
    TextField
} from "common/components";
import arrayMutators from 'final-form-arrays';
import {FishDetailed, FishDto} from "../models";
import {helpers} from "common/helpers";
import {FORM_ERROR} from "final-form";
import {FamilySelector} from "./FamilySelector";
import createDecorator from 'final-form-focus';
import {TagsField} from "../../common/components/TagsField";
import {Button} from "@mui/material";

const focusOnErrors = createDecorator();

interface Props {
    initial?: FishDetailed,
    onSubmit: (values: FishDto) => any,
}

export const FishForm: React.FC<Props> = (props: Props) => {
    const storageKey = 'fishForm';

    const onChange = (formData: any) => {
        if (!props.initial) {
            const valuesJson = JSON.stringify(formData.values);
            localStorage.setItem(storageKey, valuesJson);
        }
    };

    // @ts-ignore
    let initial: any = props.initial;
    if (initial === undefined) {
        const fromStorage = localStorage.getItem(storageKey);
        if (fromStorage !== null) {
            initial = JSON.parse(fromStorage);
        } else {
            initial = {
                data: {
                    "time" : parseInt(String(new Date().getTime() / 1000)),
                    "blocks" : [
                        {
                            type: 'paragraph',
                            data: {
                                text: ''
                            }
                        },
                        {
                            type: 'header',
                            data: {
                                level: 2,
                                text: 'Среда обитания'
                            }
                        },
                        {
                            type: 'paragraph',
                            data: {
                                text: ''
                            }
                        },
                        {
                            type: 'header',
                            data: {
                                level: 2,
                                text: 'Питание'
                            }
                        },
                        {
                            type: 'paragraph',
                            data: {
                                text: ''
                            }
                        },
                        {
                            type: 'header',
                            data: {
                                level: 2,
                                text: 'Содержание и уход'
                            }
                        },
                        {
                            type: 'paragraph',
                            data: {
                                text: ''
                            }
                        },
                        {
                            type: 'header',
                            data: {
                                level: 2,
                                text: 'Половые различия'
                            }
                        },
                        {
                            type: 'paragraph',
                            data: {
                                text: ''
                            }
                        },
                        {
                            type: 'header',
                            data: {
                                level: 2,
                                text: 'Разведение'
                            }
                        },
                        {
                            type: 'paragraph',
                            data: {
                                text: ''
                            }
                        },
                    ],
                    "version" : "2.18.0"
                }
            };
        }
    } else {
        initial.data = helpers.editorContentToView(initial.data);
    }

    const onSubmit = async (formData: any) => {
        formData.data = helpers.editorContentFromView(formData.data);

        let imageId = null;
        if (formData.image) {
            if (!Array.isArray(formData.image)) {
                imageId = formData.image.id;
            } else {
                imageId = formData.image[0].id;
            }
        }
        let dto = {
            name: formData.name,
            scienceNames: formData.scienceNames,
            anotherNames: formData.anotherNames,
            annotation: formData.annotation,
            imageId: imageId,
            data: formData.data,
            metaTitle: formData.metaTitle,
            metaDescription: formData.metaDescription,
            habitatShort: formData.habitatShort,
            tankMinimumVolume: parseInt(formData.tankMinimumVolume),
            sizeInTankFrom: parseInt(formData.sizeInTankFrom),
            sizeInTankTo: parseInt(formData.sizeInTankTo),
            phFrom: parseFloat(formData.phFrom),
            phTo: parseFloat(formData.phTo),
            ghFrom: parseInt(formData.ghFrom),
            ghTo: parseInt(formData.ghTo),
            temperatureFrom: parseInt(formData.temperatureFrom),
            temperatureTo: parseInt(formData.temperatureTo),
            lifespanFrom: parseInt(formData.lifespanFrom),
            lifespanTo: parseInt(formData.lifespanTo),
            waterFlow: formData.waterFlow,
            waterType: formData.waterType,
            reproduceWay: formData.reproduceWay,
            prefferedTankLevel: formData.prefferedTankLevel,
            temperamentToOwn: formData.temperamentToOwn,
            temperamentToOther: formData.temperamentToOther,
            facts: formData.facts,
            categoryId: formData.categoryId?parseInt(formData.categoryId):null,
            isPredator: Boolean(formData.isPredator),
            foodType: formData.foodType,
            status: formData.status,
            flockSize: parseInt(formData.flockSize),
        } as FishDto;

        try {
            await props.onSubmit(dto);
            if (!props.initial) {
                localStorage.removeItem(storageKey);
            }
        } catch (error) {
            // @ts-ignore
            if (!error.response) return Promise.reject({ [FORM_ERROR]: error });

            // @ts-ignore
            let errors = error.response.data.errors;
            if (errors.common) {
                return { [FORM_ERROR]: errors.common };
            }

            if (errors.imageId) {
                errors.image = errors.imageId;
                delete errors.imageId;
            }

            if (errors.categoryId) {
                errors.category = errors.categoryId;
                delete errors.categoryId;
            }

            return errors;
        }
    };


    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initial}
            decorators={[ focusOnErrors ]}
            mutators={{
                ...arrayMutators
            }}
            render={({
                         handleSubmit, submitError, form: {
                    mutators: {push, pop}
                },
                     }) => {
                let submit: (values: any) => any = handleSubmit;
                return (
                    <>
                        {onChange &&
                        <FormSpy onChange={onChange} subscription={{values: true}}/>
                        }
                        <form onSubmit={handleSubmit}>
                            {props.initial && props.initial.id &&
                            <>
                                <TextField
                                    label={"ID"}
                                    name={"id"}
                                    disabled
                                />
                                <TextField
                                    label={"Slug"}
                                    name={"slug"}
                                    disabled
                                />
                            </>
                            }
                            <TextField
                                label={"Название"}
                                name={"name"}
                                required={true}
                            />
                            <TagsField name={'anotherNames'} label={'Другие названия'} />
                            <TagsField name={'scienceNames'} label={'Научные названия'} />
                            <TextField
                                label={"Краткая информация"}
                                name={"annotation"}
                                multiline
                            />
                            <TextField
                                label={"Meta title"}
                                name={"metaTitle"}
                            />
                            <TextField
                                label={"Meta description"}
                                name={"metaDescription"}
                                multiline
                            />
                            <FamilySelector
                                label={'Семейство'}
                                name={'categoryId'}
                                required={true}
                            />
                            <ImageField
                                name={"image"}
                                label={"Изображение"}
                                required
                            />
                            <TextField
                                label={"Среда обитания"}
                                name={"habitatShort"}
                            />
                            <BlockTextField
                                name={"data"}
                                label={"Текст"}
                                required
                            />
                            <TextField
                                label={"Минимальный объем аквариума"}
                                name={"tankMinimumVolume"}
                                type={"number"}
                            />
                            <RangeField
                                name_from={"sizeInTankFrom"}
                                name_to={"sizeInTankTo"}
                                label={"Размер в аквариуме (см)"}
                            />
                            <RangeField
                                name_from={"phFrom"}
                                name_to={"phTo"}
                                label={"ph"}
                            />
                            <RangeField
                                name_from={"ghFrom"}
                                name_to={"ghTo"}
                                label={"gh"}
                            />
                            <RangeField
                                name_from={"temperatureFrom"}
                                name_to={"temperatureTo"}
                                label={"Температура в аквариуме"}
                            />
                            <RangeField
                                name_from={"lifespanFrom"}
                                name_to={"lifespanTo"}
                                label={"Продолжительность жизни"}
                            />
                            <SelectField
                                name={'waterFlow'}
                                label={'Течение воды'}
                                choices={[
                                    {key: "strong", value: "Сильное",},
                                    {key: "medium", value: "Среднее",},
                                    {key: "low", value: "Слабое",}
                                ]} />
                            <SelectField
                                name={'waterType'}
                                label={'Тип воды'}
                                choices={[
                                    {key: "fresh", value: "Пресная",},
                                    {key: "salsuginous", value: "Солоноватая",},
                                    {key: "salt", value: "Соленая",}
                                ]} />
                            <SelectField
                                name={'reproduceWay'}
                                label={'Способ размножения'}
                                choices={[
                                    {key: "livebearer", value: "Живородящая",},
                                    {key: "spawning", value: "Икромечущая",}
                                ]} />
                            <SelectField
                                name={'prefferedTankLevel'}
                                label={'Предпочитаемое место в аквариуме'}
                                choices={[
                                    {key: "all", value: "Любое",},
                                    {key: "bottom", value: "Дно",},
                                    {key: "bottom-middle", value: "Дно или средний слой",},
                                    {key: "middle", value: "Средний слой",},
                                    {key: "middle-top", value: "Средний слой и поверхность",},
                                    {key: "top", value: "Поверхность",},
                                ]} />
                            <SelectField
                                name={'temperamentToOwn'}
                                label={'Отношение к сородичам'}
                                choices={[
                                    {key: "peaceful", value: "Миролюбивое",},
                                    {key: "peaceful-to-females", value: "Миролюбивое к самкам",},
                                    {key: "peaceful-to-males", value: "Миролюбивое к самцам",},
                                    {key: "aggressive", value: "Агрессивное",},
                                    {key: "aggressive-to-smaller", value: "Агрессивное к более мелким рыбам",},
                                ]} />
                            <SelectField
                                name={'temperamentToOther'}
                                label={'Отношение к рыбам других видов'}
                                choices={[
                                    {key: "peaceful", value: "Миролюбивое",},
                                    {key: "peaceful-to-females", value: "Миролюбивое к самкам",},
                                    {key: "peaceful-to-males", value: "Миролюбивое к самцам",},
                                    {key: "aggressive", value: "Агрессивное",},
                                    {key: "aggressive-to-smaller", value: "Агрессивное к более мелким рыбам",},
                                ]} />
                            <SelectField
                                name={'foodType'}
                                label={'Вид корма'}
                                choices={[
                                    {key: "all", value: "Любой",},
                                    {key: "vegetable", value: "Растительный корм",},
                                    {key: "protein", value: "Живой корм или заморозка",}
                                ]} />
                            <CheckboxField
                                name={"isPredator"}
                                label={"Хищник"}
                            />
                            <TextField
                                label={"Минимальный размер стаи"}
                                name={"flockSize"}
                                type={"number"}
                            />
                            <SimpleArrayField
                                name={'facts'}
                                label={'Интересные факты'}
                                unique={true}
                                required={false}
                            />
                            <SelectField
                                name={'status'}
                                label={'Статус'}
                                choices={[
                                    {key: "draft", value: "Черновик",},
                                    {key: "published", value: "Опубликовано",}
                                ]} />

                            {submitError && <FieldError text={submitError}/>}
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={event => {
                                    submit(event);
                                }}
                            >
                                Сохранить
                            </Button>
                        </form>
                    </>
                )
            }}/>
    );
};


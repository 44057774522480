import * as React from 'react';
import commonStyles from "common/styles";
import {FieldError} from "./FieldError";
import {Field} from "react-final-form";
import {validators} from "../validators";
import { createReactEditorJS } from 'react-editor-js'
// @ts-ignore
import EditorJsImage from '@editorjs/image';
import {imagesActions} from "../store/actions";
import {API} from "@editorjs/editorjs";
import {createStyles, makeStyles} from '@mui/styles';
import {FormControl, Theme} from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import {useMemo} from "react";
const EditorJsHeader = require('@editorjs/header');
const EditorJsParagraph = require('@editorjs/paragraph');
const classNames = require('classnames');
const EditorJsList = require('@editorjs/list');
const EditorJsQuote = require('@editorjs/quote');

const useStyles = makeStyles((theme: Theme) => createStyles({
    formControl: {
        ...commonStyles.formControl
    },
    wrapper: {
        marginTop: '0.3rem',
    },
    editor: {
        marginTop: '0.3rem',
        border: '1px solid rgba(0, 0, 0, 0.22)',
        borderRadius: '0.1rem'
    }
}));

interface Props {
    name: string
    label: string
    required?: boolean
}

export const BlockTextField: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    const {name, label} = props;

    const required = !!props.required;

    let validatorsList: any = [];
    if (required) {
        validatorsList.push(validators.notEmptyEditorJS);
    }

    const ReactEditorJS = createReactEditorJS()

    return (
        <Field
            name={name}
            component="input"
            type="text"
            validate={validators.composeValidators(...validatorsList)}
            required={required}
        >
            {({input, meta, ...rest}) => {
                const error = meta.error || meta.submitError;
                let value: any = input.value;

                // @ts-ignore
                const timestamp = Math.round(new Date() / 1000);
                if (!value) {
                    value = {
                        "time": timestamp,
                        "blocks": [],
                        "version": "2.16.1"
                    };
                }

                const onChange = (api: API) => {
                    (async () => {
                        input.onChange(await api.saver.save());
                    })();
                }

                return <FormControl className={classNames(classes.formControl, classes.wrapper)}>
                    <FormLabel
                        component="legend"
                        error={!!(meta.touched && error)}
                        required={required}
                    >{label}</FormLabel>

                    <div className={classes.editor}>
                        <ReactEditorJS
                            defaultValue={value}
                            onChange={onChange}
                            tools={{
                                header: EditorJsHeader,
                                paragraph: {
                                    class: EditorJsParagraph,
                                    inlineToolbar: true,
                                },
                                list: {
                                    class: EditorJsList,
                                    inlineToolbar : true
                                },
                                quote: EditorJsQuote,
                                /*embed: {
                                    class: EditorJsEmbed,
                                    inlineToolbar: true,
                                    config: {
                                        services: {
                                            youtube: true,
                                        }
                                    }
                                },*/
                                image: {
                                    class: EditorJsImage,
                                    config: {
                                        uploader: {
                                            uploadByFile(file: File) {
                                                return imagesActions.uploadFromEditor(file);
                                            }
                                        }
                                    }
                                },
                            }}
                        />
                    </div>
                    {error &&
                    <FieldError text={error}/>
                    }
                </FormControl>
            }}
        </Field>

    );
};


import * as React from "react";

import {Provider} from 'react-redux';
import {store} from './store';
import {Router} from "common/components";
import createTheme from "@mui/material/styles/createTheme";
import green from "@mui/material/colors/green";
import {ThemeProvider} from "@mui/styles";

const mdTheme = createTheme({
    palette: {
        primary: green,
    },
});

const App: React.FC = () => {
    return (
        <ThemeProvider theme={mdTheme}>
            <Provider store={store}>
                    <Router/>
            </Provider>
        </ThemeProvider>
    );
};

export default App;

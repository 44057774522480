import {ApiClient} from "../api-client.ts";
import {SelectionDto} from "../models/SelectionDto";
import {Kind} from "../types";

const fetchAll = (kind: Kind) => {
    return ApiClient.get(`/common/selections?kind=${kind}`)
        .then(function (response) {
            return response.data;
        })
};

const fetchOne = (id: number) => {
    return ApiClient.get(`/admin/common/selections/${id}`)
        .then(function (response) {
            return response.data;
        })
};

const createSelection = (dto: SelectionDto) => {
    return ApiClient.post('/admin/common/selections', dto)
        .then(function (response) {
            return response.data
        });
};

const updateSelection = (dto: SelectionDto) => {
    return ApiClient.put(`/admin/common/selections/${dto.id}`, dto)
        .then(function (response) {
            return response.data
        });
};

const setPosition = (selectionId: number, dstSelectionId: number) => {
    return ApiClient.post(`/admin/common/selections/${selectionId}/position`, {dstId: dstSelectionId})
        .then(function (response) {
            return response.data
        });
};

export const selectionsAPI = {
    fetchAll,
    fetchOne,
    createSelection,
    updateSelection,
    setPosition,
};
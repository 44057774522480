import * as React from 'react';
import {useState} from 'react';
import {TestForm} from "./TestForm";
import {testsActions} from "../store/actions";
import {redirect} from "react-router";
import {Layout, Pages} from "common/components";
import {Breadcrumb} from "common/models";
import cogoToast from "cogo-toast";
import {TestDto} from "../models/TestDto";
import {Grid} from "@mui/material";

export const TestsCreatePage: React.FC = () => {
    const [isCreated, setIsCreated] = useState(false);

    const onSubmit = async (dto: TestDto) => {
        await testsActions.createTest(dto);
        cogoToast.success('Тест успешно создан', {position: 'bottom-left'});
        setIsCreated(true);
    };

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Тесты",
                href: "/tests"
            },
            {
                name: "Создание",
            },
        ];

        return breadcrumbs;
    };

    if (isCreated) {
        redirect("/tests");
    }

    return (
        <Layout page={Pages.Tests} headline={"Создание теста"} breadcrumbs={getBreadcrumbs()}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TestForm onSubmit={onSubmit} />
                </Grid>
                <Grid item xs={6}>

                </Grid>
            </Grid>
        </Layout>
    );
};


import * as React from 'react';
import {Form, FormSpy} from "react-final-form";
import {BlockTextField, FieldError, SimpleArrayField, TextField} from "common/components";
import arrayMutators from 'final-form-arrays';
import {Category, CategoryDto} from "../models";
import {helpers} from "common/helpers";
import {FORM_ERROR} from "final-form";
import createDecorator from 'final-form-focus';
import {OrderSelector} from "./OrderSelector";
import {Button} from "@mui/material";

const focusOnErrors = createDecorator();

interface Props {
    initial?: Category,
    onSubmit: (values: CategoryDto) => any,
}

export const FishCategoryForm: React.FC<Props> = (props: Props) => {
    const storageKey = 'fishCategoryForm';

    const onChange = (formData: any) => {
        if (!props.initial) {
            const valuesJson = JSON.stringify(formData.values);
            localStorage.setItem(storageKey, valuesJson);
        }
    };

    // @ts-ignore
    let initial: Category | undefined = props.initial;
    if (initial === undefined) {
        const fromStorage = localStorage.getItem(storageKey);
        if (fromStorage !== null) {
            initial = JSON.parse(fromStorage);
        }
    } else {
        initial.data = helpers.editorContentToView(initial.data);
    }

    const onSubmit = async (formData: any) => {
        formData.data = helpers.editorContentFromView(formData.data);

        let dto = {
            name: formData.name,
            scienceNames: formData.scienceNames,
            anotherNames: formData.anotherNames,
            data: formData.data,
            parentId: (initial && !initial.children && formData.parent)?parseInt(formData.parent.id):null,
        } as CategoryDto;

        try {
            await props.onSubmit(dto);
            if (!props.initial) {
                localStorage.removeItem(storageKey);
            }
        } catch (error) {
            // @ts-ignore
            if (!error.response) return Promise.reject({ [FORM_ERROR]: error });

            // @ts-ignore
            let errors = error.response.data.errors;
            if (errors.common) {
                return { [FORM_ERROR]: errors.common };
            }

            if (errors.categoryId) {
                errors.category = errors.categoryId;
                delete errors.categoryId;
            }

            return errors;
        }
    };

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initial}
            decorators={[ focusOnErrors ]}
            mutators={{
                ...arrayMutators
            }}
            render={({
                         handleSubmit, submitError, form: {
                    mutators: {push, pop}
                },
                     }) => {
                let submit: (values: any) => any = handleSubmit;
                return (
                    <>
                        {onChange &&
                            <FormSpy onChange={onChange} subscription={{values: true}}/>
                        }
                        <form onSubmit={handleSubmit}>
                            {props.initial && props.initial.id &&
                            <>
                                <TextField
                                    label={"ID"}
                                    name={"id"}
                                    disabled
                                />
                                <TextField
                                    label={"Slug"}
                                    name={"slug"}
                                    disabled
                                />
                            </>
                            }
                            <TextField
                                label={"Название"}
                                name={"name"}
                                required={true}
                            />
                            <SimpleArrayField
                                name={'anotherNames'}
                                label={'Другие названия'}
                                unique={true}
                            />
                            <SimpleArrayField
                                name={'scienceNames'}
                                label={'Научные названия'}
                                unique={true}
                            />
                            {initial && initial.parent &&
                            <OrderSelector
                                label={'Отряд'}
                                name={'parent'}
                            />}
                            <BlockTextField
                                name={"data"}
                                label={"Текст"}
                                required
                            />
                            {submitError && <FieldError text={submitError}/>}
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={event => {
                                    submit(event);
                                }}
                            >
                                Сохранить
                            </Button>
                        </form>
                    </>
                )
            }}/>
    );
};


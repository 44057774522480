import * as React from 'react';
import {useEffect, useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import commonStyles from "common/styles";
import {Link} from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import CategoryIcon from '@mui/icons-material/Category';
import {ActionButton, Layout, Pages} from "common/components";

import {
    DataTypeProvider,
    SearchState,
    SortingDirection,
    SortingState
} from '@devexpress/dx-react-grid';
import {
    Grid,
    SearchPanel,
    TableColumnVisibility,
    TableFixedColumns,
    TableHeaderRow,
    Toolbar, VirtualTable
} from '@devexpress/dx-react-grid-material-ui';
import {DogsFilters} from "../filters";
import {helpers} from "../../common/helpers";
import {dogsActions} from "../store/actions";
import {Breadcrumb} from "../../common/models";
import {Dog} from "../models";
import {Button} from "@mui/material";
import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from "@mui/material";


const useStyles = makeStyles((theme: Theme) => createStyles({
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    link: {
        ...commonStyles.link,
    },
    data: {
        marginTop: "0.5rem"
    },
    head: {
        backgroundColor: "#fff",
        position: "sticky",
        top: 0
    },
    actionBtns: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const ThumbnailTypeProvider = (props: any) => {
    return <DataTypeProvider
        formatterComponent={(row: any) => row.value ? <img style={{maxWidth: '200px', width: '100%'}} alt="" src={helpers.getImageUrl(row.value.thb350x250)}/> : null}
        {...props}
    />
};

const NameTypeProvider = (props: any) => {
    return <DataTypeProvider
        formatterComponent={(row: any) => row.value[0].toUpperCase() + row.value.slice(1)}
        {...props}
    />
};

const ActionsTypeProvider = (props: any) => (
    <DataTypeProvider
        formatterComponent={(props: any) => {
            return <>
                <ActionButton icon={<EditIcon/>}
                    url={'/dogs/' + String(props.row.id)}/>
                <ActionButton icon={<VisibilityIcon/>}
                    url={`https://animalpaw.ru/dogs/${props.row.slug}`}/>
                </>;
        }}
        {...props}
    />
);

export const DogsListPage: React.FC = () => {
    const classes = useStyles();
    const [dogs, setDogs] = useState<Dog[]>([]);
    const [params, setParams] = useState<DogsFilters>({
        limit: 12,
        offset: 0,
        order: '-id'
    } as DogsFilters);

    let orderDirection = 'asc';
    let orderField = params.order;
    if (params.order.startsWith('-')) {
        orderDirection = 'desc';
        orderField = orderField.substr(1);
    }

    useEffect(() => {
        const fetchDogs = async () => {
            const searchParams = {...params}
            if (typeof searchParams.search == "undefined") {
                delete searchParams.search;
            }
            setDogs((await dogsActions.fetchDogs(searchParams)).data);
        };

        /*if (params.search && params.search.length < 3) {
            return
        }
        clearTimeout(timer);

        if (params.search) {
            setTypingTimeout(setTimeout(function () {
                fetchDogs();
            }, 400))
        } else {
            fetchDogs();
        }*/
        fetchDogs();
    }, [params]);

    const handleSorting = (value: any) => {
        let newFilters: DogsFilters = Object.assign({}, params, {
            'order': (value[0].direction === 'desc'?'-':'') + value[0].columnName
        });
        setParams(newFilters);
    };

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Собаки"
            },
        ];

        return breadcrumbs;
    };

    return (
        <Layout page={Pages.Dogs} headline={"Собаки"} breadcrumbs={getBreadcrumbs()}>
            <div className={classes.actionBtns}>
                <Link to={'/dogs/create'} style={{textDecoration: 'none'}}>
                    <Button variant="outlined" color="primary">
                        <AddIcon className={classes.leftIcon}/> Создать
                    </Button>
                </Link>
                <Link to={'/dogs/selections'} style={{textDecoration: 'none'}}>
                    <Button variant="outlined" color="primary">
                        <CategoryIcon className={classes.leftIcon}/> Подборки
                    </Button>
                </Link>
            </div>
            <div className={classes.data}>
                <Grid
                    rows={dogs}
                    columns={[
                        { name: 'id', title: 'ID' },
                        { name: 'slug' },
                        { name: 'image', title: 'Logo' },
                        { name: 'name', title: 'Name' },
                        { name: 'actions' },
                    ]}>
                    <SortingState
                        sorting={[
                            {
                                columnName: orderField,
                                direction: orderDirection as SortingDirection
                            }
                        ]}
                        onSortingChange={handleSorting}
                        columnExtensions={[
                            {
                                columnName: 'image', sortingEnabled: false
                            },
                            {
                                columnName: 'actions', sortingEnabled: false
                            }
                        ]}
                    />
                    <SearchState onValueChange={(v: string) => {
                            setParams({ ...params, search: v?v:undefined, offset: 0})
                        }}
                    />

                    <VirtualTable />
                    <TableColumnVisibility defaultHiddenColumnNames={['slug']}/>
                    <ThumbnailTypeProvider for={['image']}/>
                    <NameTypeProvider for={['name']}/>
                    <ActionsTypeProvider for={['actions']}/>
                    <TableHeaderRow showSortingControls />
                    <Toolbar />
                    <SearchPanel />
                    <TableFixedColumns
                        leftColumns={['id']}
                        rightColumns={['actions']}
                    />
                </Grid>
            </div>
        </Layout>
    );
};


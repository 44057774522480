import * as React from 'react';
import commonStyles from "common/styles";
import {FieldError} from "./FieldError";
import {Field} from "react-final-form";
import {validators} from "../validators";
import {FormControl, Theme} from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import {TextField as MuiTextField} from "@mui/material";
import {createStyles, makeStyles} from '@mui/styles';
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme: Theme) => createStyles({
    formControl: {
        ...commonStyles.formControl
    },
    editor: {
        marginTop: '0.3rem',
        border: '1px solid rgba(0, 0, 0, 0.22)',
        borderRadius: '0.1rem'
    }
}));

interface Props {
    name: string
    label: string
    required?: boolean
}

export const WordCasesField: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    const {name, label} = props;

    const required = !!props.required;

    let validatorsList: any = [];
    if (required) {
        //validatorsList.push(validators.notEmptyEditorJS);
    }


    return (
        <Field
            name={name}
            component="input"
            type="text"
            validate={validators.composeValidators(...validatorsList)}
            required={required}
        >
            {({input, meta, ...rest}) => {
                const error = meta.error || meta.submitError;

                let value: any = Object.assign({
                    nominative: "",
                    genitive: "",
                    dative: "",
                    accusative: "",
                    instrumental: "",
                    prepositional: "",
                }, input.value);

                const textChanged = (wordCase: string, v: string) => {
                    // @ts-ignore
                    value[wordCase] = v;
                    input.onChange(value);
                };

                return <FormControl className={classes.formControl}>
                    <FormLabel
                        component="legend"
                        error={!!error}
                        required={required}
                    >{label}</FormLabel>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <FormControl className={classes.formControl}>
                                    <MuiTextField
                                        label={"Именительный: Кто? Что?"}
                                        value={value['nominative']}
                                        placeholder={"Кто? Что?"}
                                        onChange={(e: any) => textChanged('nominative', e.target.value)} />
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <MuiTextField
                                        label={"Родительный: Кого? Чего?"}
                                        value={value['genitive']}
                                        placeholder={"Кого? Чего?"}
                                        onChange={(e: any) => textChanged('genitive', e.target.value)} />
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <MuiTextField
                                        label={"Дательный: Кому? Чему?"}
                                        value={value['dative']}
                                        placeholder={"Кому? Чему?"}
                                        onChange={(e: any) => textChanged('dative', e.target.value)} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl className={classes.formControl}>
                                    <MuiTextField
                                        label={"Винительный: Кого? Что?"}
                                        value={value['accusative']}
                                        placeholder={"Кого? Что?"}
                                        onChange={(e: any) => textChanged('accusative', e.target.value)} />
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <MuiTextField
                                        label={"Творительный: Кем? Чем?"}
                                        value={value['instrumental']}
                                        placeholder={"Кем? Чем?"}
                                        onChange={(e: any) => textChanged('instrumental', e.target.value)} />
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <MuiTextField
                                        label={"Предложный: О ком? О чём?"}
                                        value={value['prepositional']}
                                        placeholder={"О ком? О чём?"}
                                        onChange={(e: any) => textChanged('prepositional', e.target.value)}/>
                                </FormControl>
                            </Grid>
                        </Grid>
                    {error &&
                    <FieldError text={error}/>
                    }
                </FormControl>
            }}
        </Field>

    );
};


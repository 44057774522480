import {ApiClient} from "common/api-client.ts";
import {DogsFilters} from "../../filters";
import {DogDto} from "../../models";

const buildUrl = require('build-url');

const fetchDogs = (params: DogsFilters) => {
    let queryParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
        queryParams.append(key, value)
    });

    let url = buildUrl(process.env.REACT_APP_BACKEND_API_URL, {
        path: '/admin/dogs/',
        queryParams: Object.fromEntries(queryParams),
    });
    return ApiClient.get(url)
        .then(function (response) {
            return response.data;
        });
};

const fetchDog = (id: number) => {
    return ApiClient.get(`/admin/dogs/${id}/`)
        .then(function (response) {
            return response.data;
        })
};

const createDog = (dto: DogDto) => {
    return ApiClient.post('/admin/dogs', dto)
        .then(function (response) {
            return response.data
        });
};

const updateDog = (id: number, dto: DogDto) => {
    return ApiClient.put(`/admin/dogs/${id}`, dto)
};

export const dogsActions = {
    fetchDogs,
    fetchDog,
    createDog,
    updateDog,
};
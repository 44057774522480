import * as React from 'react';
import {useEffect, useState} from 'react';
import {testsActions} from "../store/actions";
import {Layout, Pages} from "common/components";
import {Breadcrumb} from "../../common/models";
import {TestDetailed} from "../models/TestDetailed";
import {Result, ResultDto} from "../models";
import {ResultForm} from "./ResultForm";
import {redirect, useParams} from "react-router";
import {LinearProgress} from "@mui/material";
import Grid from "@mui/material/Grid";

export const ResultEditPage = () => {
    const {testId, resultId} = useParams();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [test, setTest] = useState<TestDetailed | null>(null);
    const [result, setResult] = useState<Result | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const testResponse = await testsActions.fetchTest(Number(testId));
            setTest(testResponse.data);

            const resultResponse = await testsActions.fetchResult(Number(testId), Number(resultId));
            setResult(resultResponse.data);
            setIsLoading(false);
        };
        fetchData();
    }, [testId, resultId]);

    const [isUpdated, setIsUpdated] = useState(false);

    const onsubmit = async (dto: ResultDto) => {
        await testsActions.updateResult(Number(testId), Number(resultId), dto);
        setIsUpdated(true);
    };

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Тесты",
                href: "/tests"
            },
        ];

        if (test) {
            breadcrumbs.push({
                name: test.name,
                href: `/tests/${testId}/results`
            },{
                name: "Редактирование",
            },)
        }

        return breadcrumbs;
    };

    if (isUpdated) {
        redirect(`/tests/${testId}/results`);
    }

    return (
        <Layout page={Pages.Tests} headline={`Редактирование результата теста "${test && test.name}"`} breadcrumbs={getBreadcrumbs()}>
            {isLoading ? <LinearProgress/> :
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {result && (
                            <ResultForm onSubmit={onsubmit} initial={result}/>
                        )}
                    </Grid>
                    <Grid item xs={6}>

                    </Grid>
                </Grid>
            }
        </Layout>
    );
};


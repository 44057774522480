import * as React from 'react';
import {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {StateInterface} from "reducers";
import AddIcon from '@mui/icons-material/Add';
import commonStyles from "common/styles";
import {Link} from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import {ActionButton, Layout, Pages} from "common/components";

import {DataTypeProvider, RowDetailState,} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableColumnVisibility,
    TableFixedColumns,
    TableHeaderRow,
    TableRowDetail
} from '@devexpress/dx-react-grid-material-ui';
import {testsActions} from "../store/actions";
import {Breadcrumb} from "common/models";
import {helpers} from "../../common/helpers";
import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from "@mui/material";
import Button from "@mui/material/Button";


const useStyles = makeStyles((theme: Theme) => createStyles({
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    link: {
        ...commonStyles.link,
    },
    data: {
        marginTop: "0.5rem"
    },
    head: {
        backgroundColor: "#fff",
        position: "sticky" as any,
        top: 0
    },
    actionBtns: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const ThumbnailTypeProvider = (props: any) => {
    return <DataTypeProvider
        formatterComponent={(row: any) => row.value ? <img style={{maxWidth: '200px', width: '100%'}} alt="" src={helpers.getImageUrl(row.value.thb350x250)}/> : null}
        {...props}
    />
};

const ActionsTypeProvider = (props: any) => (
    <DataTypeProvider
        formatterComponent={(props: any) => {
            let viewUrl = `https://animalpaw.ru/tests/${props.row.slug}`;
            if (props.row.slug === 'dogs-selection') {
                viewUrl = `https://animalpaw.ru/dogs/selection`;
            } else if (props.row.slug !== 'cats-selection') {
                viewUrl = `https://animalpaw.ru/cats/selection`;
            }

            return <>
                <ActionButton
                    tooltip={"Редактировать тест"}
                    icon={<EditIcon/>}
                    url={'/tests/' + String(props.row.id)}
                />
                {props.row.status === 'publish' &&
                <ActionButton
                    tooltip={"Посмотреть на сайте"}
                    icon={<VisibilityIcon/>}
                    url={viewUrl}
                />
                }
            </>;
        }}
        {...props}
    />
);

const RowDetail = (props: any) => (
    <div>
        {props.row.annotation}
    </div>
);

export const TestsListPage: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const tests = useSelector((state: StateInterface) => state.quizes.tests.data);
    useEffect(() => {
        // @ts-ignore
        dispatch(testsActions.fetchTests());
    }, [dispatch]);

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Тесты",
                href: "/tests"
            },
        ];

        return breadcrumbs;
    };

    return (
        <Layout page={Pages.Tests} headline={"Тесты"} breadcrumbs={getBreadcrumbs()}>
            <div className={classes.actionBtns}>
                <Link to={'/tests/create'} style={{textDecoration: 'none'}}>
                    <Button variant="outlined" color="primary">
                        <AddIcon className={classes.leftIcon}/> Новый тест
                    </Button>
                </Link>
            </div>
            <div className={classes.data}>
                <Grid
                    rows={tests}
                    columns={[
                        { name: 'id', title: 'ID' },
                        { name: 'slug' },
                        { name: 'image', title: 'Картинка' },
                        { name: 'name', title: 'Название', getCellValue: (row: any) => {
                            return <Link to={`/tests/${row.id}/questions`} style={{textDecoration: 'none'}} className={classes.link}>
                                {row.name}
                            </Link>
                        }},
                        { name: 'results', title: 'Результаты', getCellValue: (row: any) => {
                            if (row.slug === 'dogs-selection' || row.slug === 'cats-selection')
                                return null;

                            return <Link to={`/tests/${row.id}/results`} style={{textDecoration: 'none'}} className={classes.link}>
                                {'Результаты'}
                            </Link>
                        }},
                        { name: 'actions', title: 'Действия' },
                    ]}>

                    <RowDetailState />

                    <Table />
                    <TableColumnVisibility defaultHiddenColumnNames={['slug']}/>
                    <ThumbnailTypeProvider for={['image']}/>
                    <ActionsTypeProvider for={['actions']}/>
                    <TableHeaderRow />
                    <TableRowDetail
                        contentComponent={RowDetail}
                    />
                    <TableFixedColumns
                        rightColumns={['actions']}
                    />
                </Grid>
            </div>
        </Layout>
    );
};


import * as React from 'react';
import {Image} from "../models";
import {helpers} from "../helpers";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from "@mui/material";


const useStyles = makeStyles((theme: Theme) => createStyles({
    header: {
        position: "relative"
    },
    content: {
        position: "relative"
    },
    card: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {},
}));

interface ImageCardProps {
    allowSelect: boolean
    isSelected: boolean
    image: Image
    onSelect: (image: Image) => any,
    onUnselect: (image: Image) => any,
}

export const ImageCard: React.FC<ImageCardProps> = (props: ImageCardProps) => {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardMedia
                className={classes.media}
                image={helpers.getImageUrl(props.image.thb350x250)}
            />
            <CardActions>
                {props.isSelected ?
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => props.onUnselect(props.image)}
                    >
                        Выбрано
                    </Button>
                    :
                    <Button
                        variant="contained"
                        size="small"
                        disabled={!props.allowSelect}
                        onClick={() => props.onSelect(props.image)}
                    >
                        Выбрать
                    </Button>
                }
            </CardActions>
        </Card>
    );
};


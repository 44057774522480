import {Post} from "posts/models";
import {CatsFilters} from "../../filters";
import {Cat} from "../../models";

export const types = {
    CATS_LOAD: 'CATS_LOAD',
    CATS_LOAD_COMPLETED: 'CATS_LOAD_COMPLETED',

    CAT_LOAD: 'CAT_LOAD',
    CAT_LOAD_COMPLETED: 'CAT_LOAD_COMPLETED',
};

export interface CatsLoadAction {
    type: typeof types.CATS_LOAD,
    payload: {
        params: CatsFilters,
    }
}

export interface CatsLoadCompletedAction {
    type: typeof types.CATS_LOAD_COMPLETED
    payload: {
        data: Cat[]
        count: number
    }
}

export type CatsLoadTypes = CatsLoadAction | CatsLoadCompletedAction

export interface DogLoadAction {
    type: typeof types.CAT_LOAD
}

export interface CatLoadCompletedAction {
    type: typeof types.CAT_LOAD_COMPLETED
    payload: {
        data: Post
    }
}

export type CatLoadTypes = DogLoadAction | CatLoadCompletedAction
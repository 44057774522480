import * as React from 'react';
import {validators} from "../validators";
import {Field} from "react-final-form";
import {FieldError} from "./index";
import {FieldArray} from "react-final-form-arrays";
import {
    SortableContainer,
    SortableContainerProps,
    SortableElement,
    SortableElementProps,
    SortableHandle
} from 'react-sortable-hoc';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {TextField} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
    label: {
        marginLeft: '0.5rem',
    },
    formGroupControl: {
        margin: 0,
        display: 'flex'
    },
    dragIcon: {
        display: 'block'
    },
    textField: {
        flex: 1,
    },
    addToListBtn: {
        float: "left",
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
    },
});

const DragHandle = SortableHandle(() => <DragIndicatorIcon style={{cursor: 'pointer', display: 'block'}} />);

interface RowProps extends SortableElementProps {
    name: string
    onRemove: (i: number) => void
}


const SortableItem: React.ComponentClass<RowProps, any> = SortableElement((props: RowProps) => {
    const classes = useStyles();
    return <Field
        key={props.index}
        name={`${props.name}`}
        component="input"
        type={"text"}
        validate={validators.composeValidators(validators.required)}
    >
        {({input, meta, ...rest}) => (
            <div className={classes.formGroupControl}>
                <TextField {...input} {...rest}
                   InputProps={{
                       startAdornment: (
                           <InputAdornment position="start">
                               <DragHandle />
                           </InputAdornment>
                       ),
                       endAdornment: (
                           <InputAdornment position="end">
                               <IconButton
                                   color={"secondary"}
                                   onClick={() => props.onRemove(props.index)}
                                   aria-label="delete">
                                   <DeleteIcon/>
                               </IconButton>
                           </InputAdornment>
                       ),
                   }}
                   multiline={true}
                   className={classes.textField} />
                {(meta.error || meta.submitError) && meta.touched && (
                    <FieldError text={meta.error || meta.submitError}/>
                )}
            </div>
        )}
    </Field>;
});

interface ISortableContainer extends SortableContainerProps {
    children: React.ReactNode
}

const SortableList: React.ComponentClass<ISortableContainer> = SortableContainer((props: ISortableContainer) => {
    return (
        <div>
            {props.children}
        </div>
    );
});


interface Props {
    name: string
    label: string
    itemLabel?: string
    required?: boolean
    unique?: boolean
}

export const SimpleArrayField: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    const {name, label} = props;

    const required = !!props.required;
    const unique = !!props.unique;

    let validatorsList: any = [];
    if (required) {
        validatorsList.push(validators.notEmptyList);
    }
    if (unique) {
        validatorsList.push(validators.uniqueList);
    }
    //  validate={validators.composeValidators(...validatorsList)}
    return (
        <FieldArray name={name}>
            {({fields, meta}) => {
                const onSortEnd = (indexes: any) => {
                    fields.move(indexes.oldIndex, indexes.newIndex)
                };

                return <div style={{overflow: "auto"}}>
                    <FormLabel component="legend" error={!!meta.error || !!meta.submitError}
                               required={required}>{label}</FormLabel>
                    <SortableList
                        onSortEnd={onSortEnd}
                        lockAxis={'y'}
                        useDragHandle={true}
                    >
                        {fields.map((name: string, index: number) => {
                            return <SortableItem index={index} key={index} name={name} onRemove={() => fields.remove(index)}/>
                        })}
                    </SortableList>
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={classes.addToListBtn}
                        onClick={() => fields.push("")}>
                        {"Добавить"}
                    </Button>

                    {(meta.error || meta.submitError) && !Array.isArray(meta.error) &&
                    <FieldError text={String(meta.error || meta.submitError)}/>
                    }
                </div>
            }}
        </FieldArray>
    );
};


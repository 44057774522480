import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {StateInterface} from "reducers";
import {Link} from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import commonStyles from "common/styles";
import EditIcon from '@mui/icons-material/Edit';
import {ActionButton, Layout, Pages} from "common/components";
import {testsActions} from "../store/actions";
import {Breadcrumb} from "common/models";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import {Result} from "../models";
import {TestDetailed} from "../models/TestDetailed";
import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from "@mui/material";
import {
    SortableContainer,
    SortableContainerProps,
    SortableElement,
    SortableElementProps,
    SortableHandle
} from "react-sortable-hoc";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import TableBody from "@mui/material/TableBody";
import {useParams} from "react-router";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

const useStyles = makeStyles((theme: Theme) => createStyles({
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    link: {
        ...commonStyles.link,
    },
    data: {
        marginTop: "0.5rem"
    },
    head: {
        backgroundColor: "#fff",
        position: "sticky" as any,
        top: 0
    },
    actionBtns: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const DragHandle = SortableHandle(() => <DragIndicatorIcon style={{cursor: 'pointer', display: 'block'}} />);

interface RowProps extends SortableElementProps {
    result: Result
    testId: number
    onDelete: (result: Result) => void
}

const Row: React.ComponentClass<RowProps, any> = SortableElement((props: RowProps) => {
    return (
        <TableRow>
            <TableCell style={{ width: '5%' }}>
                <DragHandle />
            </TableCell>
            <TableCell>
                {props.result.id}
            </TableCell>
            <TableCell>
                {props.result.headline}
            </TableCell>
            <TableCell>
                <ActionButton
                    tooltip={"Редактировать результат"}
                    icon={<EditIcon/>}
                    url={`/tests/${props.testId}/results/${props.result.id}`}/>
                <Tooltip title={"Удалить результат"}>
                    <Button
                        size={"small"}
                        color={"secondary"}
                        onClick={() => props.onDelete(props.result)}
                    >
                        <DeleteIcon/>
                    </Button>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
});

interface ISortableContainer extends SortableContainerProps {
    children: React.ReactNode
}

const TableBodySortable: React.ComponentClass<ISortableContainer> = SortableContainer((props: ISortableContainer) => (
    <TableBody>
        {props.children}
    </TableBody>
));

export const ResultsListPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {testId} = useParams();

    const [test, setTest] = useState<TestDetailed | null>(null);
    const results: Result[] = useSelector((state: StateInterface) => state.quizes.results.data);
    const [toDelete, setToDelete] = useState<null | Result>(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await testsActions.fetchTest(Number(testId));
            setTest(response.data);
        };
        fetchData();
        // @ts-ignore
        dispatch(testsActions.fetchResults(testId));
    }, [dispatch, testId]);

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Тесты",
                href: "/tests"
            },
        ];

        if (test) {
            breadcrumbs.push({
                name: getHeadline(),
            });
        }

        return breadcrumbs;
    };

    const getHeadline = () => {
        let headline = 'Результаты теста';
        if (test) {
            headline += ` "${test.name}"`;
        }
        return headline;
    };

    const onSortEnd = (props: any) => {
        // @ts-ignore
        dispatch(testsActions.setResultPosition(testId, results[props.oldIndex].id as number, results[props.newIndex].id as number));
    };

    const onDelete = (result: Result) => {
        // @ts-ignore
        dispatch(testsActions.deleteResult(testId, result.id as number));
        // dispatch(testsActions.setQuestionsList(questions.filter(q => q !== question)));
        setToDelete(null);
    };

    return (
        <Layout page={Pages.Tests} headline={getHeadline()} breadcrumbs={getBreadcrumbs()}>
            <div className={classes.actionBtns}>
                <Link to={`/tests/${testId}/results/create`} style={{textDecoration: 'none'}}>
                    <Button variant="outlined" color="primary">
                        <AddIcon className={classes.leftIcon}/> Новый результат
                    </Button>
                </Link>
            </div>
            <div className={classes.data}>
                {results.length !== 0 ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '5%' }}>&nbsp;</TableCell>
                                <TableCell>Id</TableCell>
                                <TableCell>Результат</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBodySortable onSortEnd={onSortEnd} useDragHandle lockAxis={'y'}>
                            {test && results.map((row, index) => {
                                return (
                                    <Row
                                        index={index}
                                        key={row.id}
                                        testId={Number(testId)}
                                        result={row}
                                        onDelete={(result: Result) => setToDelete(result)}
                                    />
                                )
                            })}
                        </TableBodySortable>
                    </Table>
                    :
                    <Typography paragraph={true}>Данных нет</Typography>
                }
                {toDelete &&
                <Dialog
                    open={true}
                    onClose={() => setToDelete(null)}
                >
                    <DialogTitle>{"Вы уверены, что хотите удалить результат?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Вы уверены, что хотите удалить результат "{toDelete.headline}"? Отменить это действие будет невозможно
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onDelete(toDelete as Result)}
                                color="primary">
                            Да
                        </Button>
                        <Button onClick={() => setToDelete(null)}
                                color="secondary"
                                autoFocus>
                            Нет
                        </Button>
                    </DialogActions>
                </Dialog>
                }
            </div>
        </Layout>
    );
};


import * as React from 'react';
import {useState} from 'react';
import {CatDto} from "../models";
import {Layout, Pages} from "common/components";
import cogoToast from "cogo-toast";
import {catsActions} from "../store/actions";
import {CatForm} from "./CatForm";
import {Breadcrumb} from "../../common/models";
import { redirect } from "react-router-dom";
import {Grid} from "@mui/material";


export const CatCreatePage: React.FC = () => {
    const [isCreated, setIsCreated] = useState(false);

    const onSubmit = async (dto: CatDto) => {
        await catsActions.createCat(dto);
        cogoToast.success('Порода успешно создана', {position: 'bottom-left'});
        setIsCreated(true);
    };

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Кошки",
                href: "/cats"
            },
            {
                name: "Новая порода"
            }
        ];

        return breadcrumbs;
    };

    if (isCreated) {
        redirect("/cats");
    }

    return (
        <Layout page={Pages.Cats} headline={"Создание породы"} breadcrumbs={getBreadcrumbs()}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <CatForm onSubmit={onSubmit} />
                </Grid>
                <Grid item xs={6}>

                </Grid>
            </Grid>
        </Layout>
    );
};


import * as React from 'react';
import {useEffect, useState} from 'react';
import {testsActions} from "../store/actions";
import {redirect, useParams} from "react-router";
import {Layout, Pages} from "common/components";
import {Breadcrumb} from "common/models";
import cogoToast from "cogo-toast";
import {TestDetailed} from "../models/TestDetailed";
import {AnswerDto, Question} from "../models";
import {helpers} from "../../common/helpers";
import {AnswerForm} from "./AnswerForm";
import {Grid} from "@mui/material";


export const AnswerCreatePage = () => {
    const {testId, questionId} = useParams();

    const [isCreated, setIsCreated] = useState(false);
    const [test, setTest] = useState<TestDetailed | null>(null);
    const [question, setQuestion] = useState<Question | null>(null);

    const onSubmit = async (dto: AnswerDto) => {
        await testsActions.createAnswer(Number(testId), Number(questionId), dto);
        cogoToast.success('Ответ успешно создан', {position: 'bottom-left'});
        setIsCreated(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            const [ testResponse, questionResponse ] = await Promise.all([
                testsActions.fetchTest(Number(testId)),
                testsActions.fetchQuestion(Number(testId), Number(questionId)),
            ]);
            setTest(testResponse.data);
            setQuestion(questionResponse.data);
            window.scrollTo(0, 0);
        };
        fetchData();

    }, [testId, questionId]);

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Тесты",
                href: "/tests"
            },
        ];

        if (test) {
            breadcrumbs.push({
                name: test.name,
                href: `/tests/${testId}/questions`
            },)
        }

        if (question) {
            breadcrumbs.push({
                name: helpers.getPreviewFromBlockEditor(question.data),
                href: `/tests/${testId}/questions/${questionId}/answers`
            },);
            breadcrumbs.push({
                name: 'Создание ответа',
            },);
        }

        return breadcrumbs;
    };

    if (isCreated) {
        redirect(`/tests/${testId}/questions/${questionId}/answers`);
    }

    return (
        <Layout page={Pages.Tests} headline={"Создание ответа"} breadcrumbs={getBreadcrumbs()}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <AnswerForm onSubmit={onSubmit} />
                </Grid>
                <Grid item xs={6}>

                </Grid>
            </Grid>
        </Layout>
    );
};


import * as React from 'react';
import {useState} from 'react';
import {validators} from "../validators";
import {Field, Form} from "react-final-form";
import {BaseImageField, FieldError, Thumbnail} from "./index";
import {TextField} from "common/components";
import commonStyles from "common/styles";
import {imagesActions} from "../store/actions";
import {Image} from "../models";
import {FORM_ERROR} from "final-form";
import {FormControl} from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import Grid from "@mui/material/Grid";
import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from "@mui/material";

const classNames = require('classnames');

const useStyles = makeStyles((theme: Theme) => createStyles({
    formControl: {
        ...commonStyles.formControl
    },
    imageFormControl: {
        marginTop: "0.5rem"
    },
    imagesList: {
        overflow: "hidden",
        marginTop: "0.5rem"
    },
    showPopupBtn: {
        marginTop: "0.5rem"
    },
    dialogActionBtns: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    }
}));

interface Props {
    name: string
    label: string
    required?: boolean
}

export const ImageField: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    const {name, label} = props;

    const required = !!props.required;

    const [isOpen, setIsOpen] = useState(false);

    let validatorsList: any = [];
    if (required) {
        validatorsList.push(validators.notEmptyList);
    }

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Field
            name={name}
            component="input"
            type="text"
            validate={validators.composeValidators(...validatorsList)}
            required={required}
        >
            {({input, meta, ...rest}) => {
                const hasError = !meta.valid;
                let value: Image | undefined = input.value as any;

                const onSubmit = async (values: any) => {
                    try {
                        const uploaded = await imagesActions.upload(values.file, values.description);
                        input.onChange(uploaded.data);
                        setIsOpen(false);
                    } catch (e) {
                        // @ts-ignore
                        if (!e.response) {
                            return { [FORM_ERROR]: "Что-то пошло не так" }
                        }

                        // @ts-ignore
                        let { errors } = e.response.data;
                        if (errors.common) {
                            return { [FORM_ERROR]: errors.common }
                        }
                        return errors
                    }
                };

                const onRemove = (image: Image) => {
                    input.onChange(undefined);
                };

                return <FormControl className={classNames(classes.formControl, classes.imageFormControl)}>
                    <FormLabel
                        component="legend"
                        error={hasError}
                        required={required}
                    >{label}</FormLabel>
                    <div>
                        {!value && <Button
                            type="button"
                            variant="contained"
                            className={classes.showPopupBtn}
                            onClick={() => setIsOpen(true)}>
                            <AddAPhotoIcon/>
                        </Button>}
                        <Dialog
                            open={isOpen}
                            onClose={handleClose}
                        >
                            <DialogTitle>{"Загрузка изображения"}</DialogTitle>
                            <DialogContent>
                                <Form
                                    onSubmit={onSubmit}
                                    render={({handleSubmit, submitError, form,}) => {
                                        let submit: (values: any) => any = handleSubmit;
                                        return (
                                            <form onSubmit={handleSubmit}>
                                                <BaseImageField name={"file"} label={"Изображение"} required/>
                                                <TextField name={"description"} label={"Описание"}/>
                                                {submitError && <FieldError text={submitError}/>}
                                                <div className={classes.dialogActionBtns}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={event => {
                                                            submit(event);
                                                        }}
                                                    >
                                                        Ок
                                                    </Button>
                                                    <Button
                                                        color="secondary"
                                                        variant="contained"
                                                        onClick={handleClose}
                                                    >
                                                        Отмена
                                                    </Button>
                                                </div>
                                            </form>
                                        );
                                    }}
                                />
                            </DialogContent>
                        </Dialog>
                        {value &&
                        <div className={classes.imagesList}>
                            <Grid container spacing={1}>
                                <Grid item xs={6} sm={4}>
                                    <Thumbnail image={value} onRemove={onRemove}/>
                                </Grid>
                            </Grid>
                        </div>
                        }
                    </div>
                    {meta.touched && hasError &&
                    <FieldError text={meta.error?meta.error:meta.submitError}/>
                    }
                </FormControl>
            }}
        </Field>
    );
};
import * as React from 'react';
import {useState} from 'react';
import {DogDto} from "../models";
import {redirect} from "react-router";
import {Layout, Pages} from "common/components";
import cogoToast from "cogo-toast";
import {dogsActions} from "../store/actions";
import {DogForm} from "./DogForm";
import {Breadcrumb} from "../../common/models";
import Grid from "@mui/material/Grid";


export const DogCreatePage: React.FC = () => {
    const [isCreated, setIsCreated] = useState(false);

    const onSubmit = async (dto: DogDto) => {
        await dogsActions.createDog(dto);
        cogoToast.success('Порода успешно создана', {position: 'bottom-left'});
        setIsCreated(true);
        window.scrollTo(0, 0);
    };

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Собаки",
                href: "/dogs"
            },
            {
                name: "Новая порода"
            }
        ];

        return breadcrumbs;
    };

    if (isCreated) {
        redirect("/dogs");
    }

    return (
        <Layout page={Pages.Dogs} headline={"Создание породы"} breadcrumbs={getBreadcrumbs()}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <DogForm onSubmit={onSubmit} />
                </Grid>
                <Grid item xs={6}>

                </Grid>
            </Grid>
        </Layout>
    );
};


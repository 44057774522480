export const types = {
    TESTS_LOAD: 'TESTS_LOAD',
    TESTS_LOAD_COMPLETED: 'TESTS_LOAD_COMPLETED',

    TEST_LOAD: 'TEST_LOAD',
    TEST_LOAD_COMPLETED: 'TEST_LOAD_COMPLETED',

    TEST_CREATE: 'TEST_CREATE',
    TEST_CREATE_COMPLETED: 'TEST_CREATE_COMPLETED',

    TEST_UPDATE: 'TEST_UPDATE',
    TEST_UPDATE_COMPLETED: 'TEST_UPDATE_COMPLETED',

    TESTS_RESULTS_LOAD: 'TESTS_RESULTS_LOAD',
    TESTS_RESULTS_LOAD_COMPLETED: 'TESTS_RESULTS_LOAD_COMPLETED',

    TESTS_RESULT_LOAD: 'TEST_RESULTS_LOAD',
    TESTS_RESULT_LOAD_COMPLETED: 'TEST_RESULTS_LOAD_COMPLETED',

    TESTS_QUESTIONS_LOAD: 'TESTS_QUESTIONS_LOAD',
    TESTS_QUESTIONS_LOAD_COMPLETED: 'TESTS_QUESTIONS_LOAD_COMPLETED',

    TESTS_QUESTION_LOAD: 'TESTS_QUESTION_LOAD',
    TESTS_QUESTION_LOAD_COMPLETED: 'TESTS_QUESTION_LOAD_COMPLETED',

    TESTS_QUESTION_CREATE: 'TESTS_QUESTION_CREATE',
    TESTS_QUESTION_CREATE_COMPLETED: 'TESTS_QUESTION_CREATE_COMPLETED',

    TESTS_QUESTION_UPDATE: 'TESTS_QUESTION_UPDATE',
    TESTS_QUESTION_UPDATE_COMPLETED: 'TESTS_QUESTION_UPDATE_COMPLETED',

    TESTS_ANSWERS_LOAD: 'TESTS_ANSWERS_LOAD',
    TESTS_ANSWERS_LOAD_COMPLETED: 'TESTS_ANSWERS_LOAD_COMPLETED',

    TESTS_ANSWER_LOAD: 'TESTS_ANSWER_LOAD',
    TESTS_ANSWER_LOAD_COMPLETED: 'TESTS_ANSWER_LOAD_COMPLETED',

    TESTS_ANSWER_CREATE: 'TESTS_ANSWER_CREATE',
    TESTS_ANSWER_CREATE_COMPLETED: 'TESTS_ANSWER_CREATE_COMPLETED',

    TESTS_ANSWER_UPDATE: 'TESTS_ANSWER_UPDATE',
    TESTS_ANSWER_UPDATE_COMPLETED: 'TESTS_ANSWER_UPDATE_COMPLETED',
};
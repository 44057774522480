import * as React from 'react';
import commonStyles from "common/styles";
import DeleteIcon from '@mui/icons-material/Delete';
import {Image} from "../models";
import {helpers} from "../helpers";
import {IconButton} from "@mui/material";
import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from "@mui/material";


const useStyles = makeStyles((theme: Theme) => createStyles({
    formControl: {
        ...commonStyles.formControl
    },
    imagesList: {
        overflow: "hidden",
        marginTop: "0.5rem"
    },
    imageBlock: {
        position: "relative"
    },
    imageRemoveIcon: {
        position: "absolute",
        top: "5px",
        right: "5px",
        color: commonStyles.dangerColor
    },
    imgThb: {
        border: `1px solid ${theme.palette.grey[200]}`,
        width: "100%"
    }

}));

interface Props {
    image: Image
    onRemove: (i: Image) => void
}

export const Thumbnail: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    return (
        <div className={classes.imageBlock}>
            <IconButton
                className={classes.imageRemoveIcon}
                aria-label="Удалить"
                onClick={() => props.onRemove(props.image)}
            >
                <DeleteIcon/>
            </IconButton>
            <img className={classes.imgThb} alt={""} src={helpers.getImageUrl(props.image.thb350x250)}/>
        </div>
    );
};


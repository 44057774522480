import {stripHtml} from "string-strip-html";

const composeValidators = (...validators: any) => (value: string) =>
    validators.reduce((error: any, validator: any) => error || validator(value), undefined);

const required = (value: any) => value ? undefined : "Это поле обязательно";
const maxLength = (max: number) => (value: string) =>
    (value === undefined || value.length < max) ? undefined : `Длина не должна превышать ${max} символов. Текущее значение имеет ${value.length} символов`;

const notEmptyList = (value: any[]) => {
    return (value && value.length !== 0) ? undefined : "Это поле обязательно";
};

const uniqueList = (data: any[]) => {
    let processed = [];
    if (!data) {
        return undefined;
    }
    for (let i = 0; i < data.length; i++) {
        const lc = data[i];
        if (lc && processed.indexOf(lc) !== -1) {
            return `Значение "${lc}" встречается более одного раза`
        }
        processed.push(lc)
    }
    return undefined;
};

const notEmptyHtml = (value: string) => (value && stripHtml(value).result.replace(/\s/g, '').length !== 0) ? undefined : "Это поле обязательно";

const notEmptyEditorJS = (value: any) => value && value.blocks.length !== 0 ? undefined : "Это поле обязательно";

export const validators = {
    composeValidators,
    required,
    maxLength,
    notEmptyList,
    notEmptyHtml,
    notEmptyEditorJS,
    uniqueList,
};
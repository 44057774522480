import * as React from 'react';
import {Form, FormSpy} from "react-final-form";
import {
    BlockTextField,
    CountrySelector,
    FieldError,
    ImageField,
    RangeField,
    RatingField,
    SelectField,
    SimpleArrayField,
    TextField
} from "common/components";
import commonStyles from "common/styles";
import arrayMutators from 'final-form-arrays';
import {DogDetailed, DogDto} from "../models";
import {helpers} from "common/helpers";
import {FORM_ERROR} from "final-form";
import {TagsField} from "../../common/components/TagsField";
import {FormControl} from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from "@mui/material";

const classNames = require('classnames');


const useStyles = makeStyles((theme: Theme) => createStyles({
    formControl: {
        ...commonStyles.formControl
    },
    fromToControl: {
        width: "initial",
        display: "inline-block"
    },
    formGroupControl: {
        ...commonStyles.formControl,
        margin: 0
    },
    fab: {
        margin: theme.spacing(1),
    },
    addToListBtn: {
        float: "right",
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
    },
}));

interface DogFormProps {
    initial?: DogDetailed,
    onSubmit: (values: DogDto) => any,
}

export const DogForm: React.FC<DogFormProps> = (props: DogFormProps) => {
    const classes = useStyles();

    const storageKey = 'dogForm';

    const onChange = (formData: any) => {
        if (!props.initial) {
            const valuesJson = JSON.stringify(formData.values);
            localStorage.setItem(storageKey, valuesJson);
        }
    };

    // @ts-ignore
    let initial: any = props.initial;
    if (initial === undefined) {
        const fromStorage = localStorage.getItem(storageKey);
        if (fromStorage !== null) {
            initial = JSON.parse(fromStorage);
        } else {
            initial = {
                data: {
                    "time" : parseInt(String(new Date().getTime() / 1000)),
                    "blocks" : [
                        {
                            type: 'paragraph',
                            data: {
                                text: ''
                            }
                        },
                        {
                            type: 'header',
                            data: {
                                level: 2,
                                text: 'Происхождение породы'
                            }
                        },
                        {
                            type: 'paragraph',
                            data: {
                                text: ''
                            }
                        },
                        {
                            type: 'header',
                            data: {
                                level: 2,
                                text: 'Черты характера'
                            }
                        },
                        {
                            type: 'paragraph',
                            data: {
                                text: ''
                            }
                        },
                        {
                            type: 'header',
                            data: {
                                level: 2,
                                text: 'Содержание и уход'
                            }
                        },
                        {
                            type: 'paragraph',
                            data: {
                                text: ''
                            }
                        },
                    ],
                    "version" : "2.18.0"
                }
            };
        }
    } else {
        initial.data = helpers.editorContentToView(initial.data);
    }

    const onSubmit = async (formData: any) => {
        formData.data = helpers.editorContentFromView(formData.data);

        let imageId = null;
        if (formData.image) {
            if (!Array.isArray(formData.image)) {
                imageId = formData.image.id;
            } else {
                imageId = formData.image[0].id;
            }
        }

        let countriesIds = [];
        if (formData.countries) {
            for (let i = 0; i < formData.countries.length; i++) {
                countriesIds.push(formData.countries[i].id)
            }
        }

        let dto = {
            activity: formData.activity,
            careDifficulty: formData.careDifficulty,
            compatibilityWithChildren: formData.compatibilityWithChildren,
            domination: formData.domination,
            educability: formData.educability,
            facts: formData.facts,
            imageId: imageId,
            countriesIds: countriesIds,
            friendliness: formData.friendliness,
            data: formData.data,
            metaTitle: formData.metaTitle,
            metaDescription: formData.metaDescription,
            growthFemaleFrom: parseInt(formData.growthFemaleFrom),
            growthFemaleTo: parseInt(formData.growthFemaleTo),
            growthMaleFrom: parseInt(formData.growthMaleFrom),
            growthMaleTo: parseInt(formData.growthMaleTo),
            guardAbility: formData.guardAbility,
            independence: formData.independence,
            keepInFlat: formData.keepInFlat,
            latinName: formData.latinName,
            maxLifespan: parseInt(formData.maxLifespan),
            minLifespan: parseInt(formData.minLifespan),
            moltingLevel: formData.moltingLevel,
            name: formData.name,
            anotherNames: formData.anotherNames,
            noisiness: formData.noisiness,
            ownerExperience: formData.ownerExperience,
            size: formData.size,
            slug: formData.slug,
            weightFemaleFrom: parseInt(formData.weightFemaleFrom),
            weightFemaleTo: parseInt(formData.weightFemaleTo),
            weightMaleFrom: parseInt(formData.weightMaleFrom),
            weightMaleTo: parseInt(formData.weightMaleTo),
            year: formData.year,
            woolType: formData.woolType,
            annotation: formData.annotation,
            tags: formData.tags,
        } as DogDto;

        try {
            await props.onSubmit(dto);
            if (!props.initial) {
                localStorage.removeItem(storageKey);
            }
        } catch (error) {
            // @ts-ignore
            if (!error.response) return Promise.reject({ [FORM_ERROR]: error });

            // @ts-ignore
            let errors = error.response.data.errors;
            if (errors.common) {
                return { [FORM_ERROR]: errors.common };
            }
            if (errors.imageId) {
                errors.image = errors.imageId;
                delete errors.imageId;
            }

            if (errors.countriesIds) {
                errors.countries = errors.countriesIds;
                delete errors.countriesIds;
            }
            return errors;
        }
    };

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initial}
            mutators={{
                ...arrayMutators
            }}
            render={({
                         handleSubmit, submitError, form: {
                    mutators: {push, pop}
                },
                     }) => {
                let submit: (values: any) => any = handleSubmit;
                return (
                    <>
                        {onChange &&
                        <FormSpy onChange={onChange} subscription={{values: true}}/>
                        }
                        <form onSubmit={handleSubmit}>
                            {props.initial && props.initial.id &&
                            <>
                                <TextField
                                    label={"ID"}
                                    name={"id"}
                                    disabled
                                />
                                <TextField
                                    label={"Slug"}
                                    name={"slug"}
                                    disabled
                                />
                            </>
                            }
                            <TextField
                                label={"Название"}
                                name={"name"}
                                required
                            />
                            <TagsField name={'anotherNames'} label={'Другие названия'} />
                            <TextField
                                label={"Латинское название"}
                                name={"latinName"}
                            />
                            <TextField
                                label={"Краткая информация"}
                                name={"annotation"}
                                multiline
                            />
                            <TextField
                                label={"Meta title"}
                                name={"metaTitle"}
                            />
                            <TextField
                                label={"Meta description"}
                                name={"metaDescription"}
                                multiline
                            />
                            <CountrySelector
                                name="countries"
                                label="Страны"/>
                            <TextField
                                label={"Время появления"}
                                name={"year"}
                            />
                            <BlockTextField name={"data"} label={"Текст"} required/>
                            <ImageField
                                name={"image"}
                                label={"Изображение"}
                                required
                            />
                            <SelectField
                                name={'woolType'}
                                label={'Тип шерсти'}
                                choices={[
                                    {key: "hairless", value: "Бесшёрстная"},
                                    {key: "shorthaired", value: "Короткошёрстная",},
                                    {key: "longhaired", value: "Длинношёрстная",},
                                    {key: "wirehaired", value: "Жесткошерстная",},
                                ]}
                            />
                            <RangeField
                                name_from={"growthMaleFrom"}
                                name_to={"growthMaleTo"}
                                label={"Рост кобелей (см)"}
                            />

                            <FormControl className={classNames(classes.formControl, classes.fromToControl)}>
                                <FormLabel
                                    component="legend"
                                >{"Рост сук (см)"}</FormLabel>
                                <Grid spacing={1} container>
                                    <Grid item sm={6}>
                                        <TextField
                                            placeholder={"от"}
                                            name={"growthFemaleFrom"}
                                            type={"number"}
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <TextField
                                            placeholder={"до"}
                                            name={"growthFemaleTo"}
                                            type={"number"}
                                        />
                                    </Grid>
                                </Grid>
                            </FormControl>
                            <FormControl className={classNames(classes.formControl, classes.fromToControl)}>
                                <FormLabel
                                    component="legend"
                                >{"Вес кобелей (кг)"}</FormLabel>
                                <Grid spacing={1} container>
                                    <Grid item sm={6}>
                                        <TextField
                                            placeholder={"от"}
                                            name={"weightMaleFrom"}
                                            type={"number"}
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <TextField
                                            placeholder={"до"}
                                            name={"weightMaleTo"}
                                            type={"number"}
                                        />
                                    </Grid>
                                </Grid>
                            </FormControl>
                            <FormControl className={classNames(classes.formControl, classes.fromToControl)}>
                                <FormLabel
                                    component="legend"
                                >{"Вес сук (кг)"}</FormLabel>
                                <Grid spacing={1} container>
                                    <Grid item sm={6}>
                                        <TextField
                                            placeholder={"от"}
                                            name={"weightFemaleFrom"}
                                            type={"number"}
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <TextField
                                            placeholder={"до"}
                                            name={"weightFemaleTo"}
                                            type={"number"}
                                        />
                                    </Grid>
                                </Grid>
                            </FormControl>
                            <FormControl className={classNames(classes.formControl, classes.fromToControl)}>
                                <FormLabel
                                    component="legend"
                                >{"Продолжительность жизни"}</FormLabel>
                                <Grid spacing={1} container>
                                    <Grid item sm={6}>
                                        <TextField
                                            placeholder={"от"}
                                            name={"minLifespan"}
                                            type={"number"}
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <TextField
                                            placeholder={"до"}
                                            name={"maxLifespan"}
                                            type={"number"}
                                        />
                                    </Grid>
                                </Grid>
                            </FormControl>
                            <TagsField name={'tags'} label={'Теги'} />
                            <SimpleArrayField
                                name={'facts'}
                                label={'Интересные факты'}
                                unique={true}
                            />
                            <RatingField name={"size"} label={"Размер"} required/>
                            <RatingField name={"domination"} label={"Доминирование"} required/>
                            <RatingField name={"keepInFlat"} label={"Удобство содержания в квартире"} max={3}
                                         required/>
                            <RatingField name={"compatibilityWithChildren"} label={"Совместимость с детьми"}
                                         required/>
                            <RatingField name={"activity"} label={"Активность"} required/>
                            <RatingField name={"ownerExperience"} label={"Опыт владельца"} max={2} required/>
                            <RatingField name={"careDifficulty"} label={"Сложность в уходе"} required/>
                            <RatingField name={"guardAbility"} label={"Сторожевые качества"} required/>
                            <RatingField name={"educability"} label={"Способность к дрессировке"} required/>
                            <RatingField name={"independence"} label={"Независимость"} required/>
                            <RatingField name={"friendliness"} label={"Дружелюбие"} required/>
                            <RatingField name={"noisiness"} label={"Шумливость"} required/>
                            <RatingField name={"moltingLevel"} label={"Уровень линьки"} required/>

                            {submitError && <FieldError text={submitError}/>}
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={event => {
                                    submit(event);
                                }}
                            >
                                Сохранить
                            </Button>
                        </form>
                    </>
                )
            }}/>
    );
};


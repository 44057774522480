import * as React from 'react';
import {CircularProgress} from "@mui/material";
import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from "@mui/material";

const classNames = require('classnames');

const useStyles = makeStyles((theme: Theme) => createStyles({
    wrapper: {
        position: "relative"
    },
    loading: {
        filter: "blur(5px)",
    },
    overlay: {
        opacity: 1,
        height: "100%",
        position: "absolute",
        width: "100%",
        top: 0,
        left: 0,
        zIndex: 1101
    },
    indicator: {
        position: "absolute",
        top: "50%",
        left: "50%",
        zIndex: 1102
    }
}));

interface LoadableProps {
    isLoading: boolean
    children: any
}

export const Loadable: React.FC<LoadableProps> = (props: LoadableProps) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            {props.isLoading && (
                <div className={classes.overlay}>
                    <div className={classes.indicator}>
                        <CircularProgress/>
                    </div>
                </div>
            )}
            <div className={classNames({[classes.loading]: props.isLoading})}>
                {props.children}
            </div>
        </div>
    );
};


import {ApiClient} from "common/api-client.ts";
import {CategoryDto} from "../../models";

const fetchCategories = () => {
    return ApiClient.get('/admin/fishes/categories')
        .then(function (response) {
            return response.data;
        })
};

const fetchCategory = (id: number) => {
    return ApiClient.get(`/admin/fishes/categories/${id}`)
        .then(function (response) {
            return response.data;
        })
};

const updateCategory = (id: number, dto: CategoryDto) => {
    return ApiClient.put(`/admin/fishes/categories/${id}`, dto)
};


export const categoriesActions = {
    fetchCategories,
    fetchCategory,
    updateCategory,
};
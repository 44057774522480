import {combineReducers} from 'redux';
import {UsersAppState} from "users/store/states";
import {UsersReducer} from "users/store/reducers";
import {PostsAppState} from "posts/store/states";
import {PostsAppReducer} from "./posts/store/reducers";
import {CommonAppState} from "./common/store/states";
import {CommonAppReducer} from "./common/store/reducers";
import {QuizesAppState} from "./quizes/store/states";
import {QuizesAppReducer} from "./quizes/store/reducers";
import {DogsAppReducer} from "./dogs/store/reducers";
import {DogsAppState} from "./dogs/store/states";
import {CatsAppReducer} from "./cats/store/reducers";
import {CatsAppState} from "./cats/store/states";
import {FishesAppState} from "./fishes/store/states";
import {FishesAppReducer} from "./fishes/store/reducers";


export interface StateInterface {
    common: CommonAppState;
    quizes: QuizesAppState;
    posts: PostsAppState;
    users: UsersAppState;
    dogs: DogsAppState;
    cats: CatsAppState;
    fishes: FishesAppState;
}

export const state = combineReducers<StateInterface>({
    common: CommonAppReducer,
    quizes: QuizesAppReducer,
    posts: PostsAppReducer,
    users: UsersReducer,
    dogs: DogsAppReducer,
    cats: CatsAppReducer,
    fishes: FishesAppReducer
});
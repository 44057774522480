import * as React from 'react';
import {useEffect, useState} from 'react';
import {testsActions} from "../store/actions";
import {Layout, Pages} from "common/components";
import {Breadcrumb} from "../../common/models";
import {TestDetailed} from "../models/TestDetailed";
import {Answer, AnswerDto, Question} from "../models";
import {helpers} from "../../common/helpers";
import {AnswerForm} from "./AnswerForm";
import {redirect, useParams} from "react-router";
import {LinearProgress} from "@mui/material";
import Grid from "@mui/material/Grid";


export const AnswerEditPage = () => {
    const {testId, questionId, answerId} = useParams();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [test, setTest] = useState<TestDetailed | null>(null);
    const [question, setQuestion] = useState<Question | null>(null);
    const [answer, setAnswer] = useState<Answer | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const [ testResponse, questionResponse, answerResponse ] = await Promise.all([
                testsActions.fetchTest(Number(testId)),
                testsActions.fetchQuestion(Number(testId), Number(questionId)),
                testsActions.fetchAnswer(Number(testId), Number(questionId), Number(answerId)),
            ]);

            setTest(testResponse.data);
            setQuestion(questionResponse.data);
            setAnswer(answerResponse.data);
            setIsLoading(false);
        };
        fetchData();
    }, [testId, questionId, answerId]);

    const [isUpdated, setIsUpdated] = useState(false);

    const onsubmit = async (dto: AnswerDto) => {
        await testsActions.updateAnswer(Number(testId), Number(questionId), Number(answerId), dto);
        setIsUpdated(true);
    };

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Тесты",
                href: "/tests"
            },
        ];

        if (test) {
            breadcrumbs.push({
                name: test.name,
                href: `/tests/${testId}/questions`
            },)
        }

        if (question) {
            breadcrumbs.push({
                name: helpers.getPreviewFromBlockEditor(question.data),
                href: `/tests/${testId}/questions/${questionId}/answers`
            });
            breadcrumbs.push({
                name: 'Редактирование',
            });
        }

        return breadcrumbs;
    };

    if (isUpdated) {
        redirect(`/tests/${testId}/questions/${questionId}/answers`);
    }

    return (
        <Layout page={Pages.Tests} headline={`Редактирование ответа на вопрос "${question && helpers.getPreviewFromBlockEditor(question.data)}"`} breadcrumbs={getBreadcrumbs()}>
            {isLoading ? <LinearProgress/> :
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {answer && (
                            <AnswerForm onSubmit={onsubmit} initial={answer}/>
                        )}
                    </Grid>
                    <Grid item xs={6}>

                    </Grid>
                </Grid>
            }
        </Layout>
    );
};


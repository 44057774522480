import * as React from 'react';
import {useEffect, useState} from 'react';
import {testsActions} from "../store/actions";
import {redirect, useParams} from "react-router";
import {Layout, Pages} from "common/components";
import {Breadcrumb} from "common/models";
import cogoToast from "cogo-toast";
import {TestDetailed} from "../models/TestDetailed";
import {ResultDto} from "../models";
import {ResultForm} from "./ResultForm";
import {Grid} from "@mui/material";

export const ResultCreatePage = () => {
    const {testId} = useParams();

    const [isCreated, setIsCreated] = useState(false);
    const [test, setTest] = useState<TestDetailed | null>(null);

    const onSubmit = async (dto: ResultDto) => {
        await testsActions.createResult(Number(testId), dto);
        cogoToast.success('Результат успешно создан', {position: 'bottom-left'});
        setIsCreated(true);
    };

    useEffect(() => {
        const fetchTest = async () => {
            const response = await testsActions.fetchTest(Number(testId));
            setTest(response.data);
            window.scrollTo(0, 0);
        };
        fetchTest();

    }, [testId]);

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Тесты",
                href: "/tests"
            },
        ];

        if (test) {
            breadcrumbs.push({
                name: test.name,
                href: `/tests/${testId}/results`
            },{
                name: "Создание результата",
            },)
        }

        return breadcrumbs;
    };

    if (isCreated) {
        redirect(`/tests/${testId}/results`);
    }

    return (
        <Layout page={Pages.Tests} headline={"Создание результата"} breadcrumbs={getBreadcrumbs()}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <ResultForm onSubmit={onSubmit} />
                </Grid>
                <Grid item xs={6}>

                </Grid>
            </Grid>
        </Layout>
    );
};


import {combineReducers} from 'redux';
import {QuizesAppState} from "../states";
import {TestsReducer} from "./TestsReducer";
import {QuestionsReducer} from "./QuestionsReducer";
import {AnswersReducer} from "./AnswersReducer";
import {ResultsReducer} from "./ResultsReducer";


export const QuizesAppReducer = combineReducers<QuizesAppState>({
    tests: TestsReducer,
    results: ResultsReducer,
    questions: QuestionsReducer,
    answers: AnswersReducer,
});
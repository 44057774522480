import {JwtPair} from "../users/models/JwtPair";

export class TokenStorage {
    private static instance: TokenStorage;
    private access: string | undefined = undefined;

    private constructor() { }

    public static getInstance(): TokenStorage {
        if (!TokenStorage.instance) {
            TokenStorage.instance = new TokenStorage();
        }

        return TokenStorage.instance;
    }

    public getAccess() {
        return this.access
    }

    public setJwtPair(pair: JwtPair) {
        this.access = pair.access
        if (process.env.NODE_ENV !== 'production') {
            localStorage.setItem('refresh', pair.refresh);
        }
    }

    public getRefresh() {
        return localStorage.getItem('refresh');
    }

    public clearToken() {
        this.access = undefined;
        localStorage.removeItem('refresh');
    }
}

import * as React from 'react';
import {useEffect, useState} from 'react';
import commonStyles from "common/styles";
import {CustomTreeData, TreeDataState,} from '@devexpress/dx-react-grid';
import {Grid, Table, TableHeaderRow, TableTreeColumn,} from '@devexpress/dx-react-grid-material-ui';
import {ActionButton, Layout, Pages} from "common/components";
import EditIcon from '@mui/icons-material/Edit';
import {Category} from "../models";
import {categoriesActions} from "../store/actions/CategoriesActions";
import {Breadcrumb} from "../../common/models";
import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";


const useStyles = makeStyles((theme: Theme) => createStyles({
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    link: {
        ...commonStyles.link,
    },
    data: {
        marginTop: "0.5rem"
    },
    head: {
        backgroundColor: "#fff",
        position: "sticky",
        top: 0
    },
}));

const getChildRows = (row: any, rootRows: any) => {
    if (row) {
        if (row.children.length !== 0) {
            return row.children;
        }
        return undefined;
    }
    return rootRows
};

export const FishesCategoriesListPage: React.FC = () => {
    const classes = useStyles();

    const [categories, setCategories] = useState<Category[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchCategories = async () => {
        const response = await categoriesActions.fetchCategories();
        setCategories(response.data);
        setIsLoading(false);
    };

    useEffect(() => {
        setIsLoading(true);
        fetchCategories();
    }, []);

    const [columns] = useState([
        { name: 'name', title: 'name', getCellValue: (row: Category) => row.name },
        { name: 'scienceNames', title: 'Научное название', getCellValue: (row: Category) => row.scienceNames.length !== 0?row.scienceNames[0]:null },
        //{ name: 'is_enabled', title: 'Опубликовано', getCellValue: (row: Category) => row.is_enabled },
        { name: '', title: '', getCellValue: (row: Category) =>
                <ActionButton
                    icon={<EditIcon/>}
                    label={"Редактировать"}
                    url={'/fishes/categories/' + String(row.id)}/> },

    ]);

    const [tableColumnExtensions] = useState([
        { columnName: 'name', width: 300 },
    ]);

    //const [statusColumns] = useState(['is_enabled']);
    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Рыбы",
                href: "/fishes"
            },
            {
                name: "Категории"
            }
        ];
        
        return breadcrumbs;
    };

    return (
        <Layout page={Pages.Fishes} headline={"Отряды и семейства рыб"} breadcrumbs={getBreadcrumbs()}>
            <div className={classes.data}>
                {isLoading ?
                    <LinearProgress/>
                    :
                    <Grid
                        rows={categories}
                        columns={columns}
                    >
                        <TreeDataState />
                        <CustomTreeData
                            getChildRows={getChildRows}
                        />
                        <Table
                            columnExtensions={tableColumnExtensions}
                        />
                        <TableHeaderRow />
                        <TableTreeColumn
                            for="name"
                        />
                    </Grid>
                }
            </div>
        </Layout>
    );
};

import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {StateInterface} from "reducers";
import AddIcon from '@mui/icons-material/Add';
import commonStyles from "common/styles";
import {Link} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {ActionButton, BooleanIndicator, Layout, Pages} from "common/components";

import {testsActions} from "../store/actions";
import {helpers} from "common/helpers";
import {Breadcrumb} from "common/models";
import {
    SortableContainer,
    SortableContainerProps,
    SortableElement,
    SortableElementProps,
    SortableHandle
} from 'react-sortable-hoc';
import {Answer, Question} from "../models";
import {TestDetailed} from "../models/TestDetailed";
import {useParams} from "react-router";
import Button from '@mui/material/Button';
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {TableBody} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from "@mui/material";


const useStyles = makeStyles((theme: Theme) => createStyles({
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    link: {
        ...commonStyles.link,
    },
    data: {
        marginTop: "0.5rem"
    },
    head: {
        backgroundColor: "#fff",
        position: "sticky" as any,
        top: 0
    },
    actionBtns: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

interface RowProps extends SortableElementProps {
    answer: Answer
    test: TestDetailed
    questionId: number
    onDelete: (answer: Answer) => void
}

const Row: React.ComponentClass<RowProps, any> = SortableElement((props: RowProps) => {
    return (
        <TableRow>
            <TableCell style={{ width: '5%' }}>
                <DragHandle />
            </TableCell>
            <TableCell>
                {props.answer.id}
            </TableCell>
            <TableCell>
                {props.answer.text}
            </TableCell>
            <TableCell>
                <BooleanIndicator active={props.answer.isCorrect}/>
            </TableCell>
            <TableCell>
                <ActionButton icon={<EditIcon/>}
                              url={`/tests/${props.test.id}/questions/${props.questionId}/answers/${props.answer.id}`}
                />
                {props.test.slug !== 'dogs-selection' && props.test.slug !== 'cats-selection' &&
                <Button
                    size={"small"}
                    color={"secondary"}
                    onClick={() => props.onDelete(props.answer)}
                >
                    <DeleteIcon/>
                </Button>
                }
            </TableCell>
        </TableRow>
    )
});

interface ISortableContainer extends SortableContainerProps {
    children: React.ReactNode
}

const TableBodySortable: React.ComponentClass<ISortableContainer> = SortableContainer((props: ISortableContainer) => (
    <TableBody>
        {props.children}
    </TableBody>
));

// @ts-ignore
//TableBodySortable.muiName = 'TableBody';

const DragHandle = SortableHandle(() => <DragIndicatorIcon style={{cursor: 'pointer', display: 'block'}} />);

export const AnswersListPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const answers = useSelector((state: StateInterface) => state.quizes.answers.data);
    const [test, setTest] = useState<TestDetailed | null>(null);
    const [question, setQuestion] = useState<Question | null>(null);

    const {testId, questionId} =  useParams();

    useEffect( () => {
        const fetchData = async () => {
            const [testResponse, questionResponse] = await Promise.all([
                testsActions.fetchTest(Number(testId)),
                testsActions.fetchQuestion(Number(testId), Number(questionId)),
                // @ts-ignore
                dispatch(testsActions.fetchAnswers(Number(testId), Number(questionId)))
            ]);
            setTest(testResponse.data);
            setQuestion(questionResponse.data);
        };
        fetchData();

    },[dispatch, testId, questionId]);

    const [toDelete, setToDelete] = useState<null | Answer>(null);

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Тесты",
                href: "/tests"
            },
        ];

        if (test) {
            breadcrumbs.push({
                name: test.name,
                href: `/tests/${test.id}/questions`
            });
        }

        if (question) {
            breadcrumbs.push({
                name: helpers.getPreviewFromBlockEditor(question.data)
            });
        }

        return breadcrumbs;
    };

    const onSortEnd = (props: any) => {
        // @ts-ignore
        dispatch(testsActions.setAnswerPosition(testId, questionId, answers[props.oldIndex].id as number, answers[props.newIndex].id as number));
    };

    const onDelete = (answer: Answer) => {
        // @ts-ignore
        dispatch(testsActions.deleteAnswer(testId, questionId, answer.id as number));
        setToDelete(null);
    };

    return (
        <Layout page={Pages.Tests}
                headline={question?helpers.getPreviewFromBlockEditor(question.data):""}
                breadcrumbs={getBreadcrumbs()}>
            {test && test.slug !== 'dogs-selection' && test.slug !== 'cats-selection' &&
            <div className={classes.actionBtns}>
                <Link to={`/tests/${testId}/questions/${questionId}/answers/create`} style={{textDecoration: 'none'}}>
                    <Button variant="outlined" color="primary">
                        <AddIcon className={classes.leftIcon}/> Новый ответ
                    </Button>
                </Link>
            </div>
            }
            <div className={classes.data}>
                {test && answers.length !== 0 ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '5%' }}>&nbsp;</TableCell>
                                <TableCell>Id</TableCell>
                                <TableCell>Ответ</TableCell>
                                <TableCell>Правильный</TableCell>
                                <TableCell>Действия</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBodySortable onSortEnd={onSortEnd} useDragHandle lockAxis={'y'}>
                            {answers.map((row, index) => {
                                return (
                                    <Row
                                        disabled={test.slug === 'dogs-selection' || test.slug === 'cats-selection'}
                                        index={index}
                                        key={row.id}
                                        answer={row}
                                        test={test}
                                        questionId={Number(questionId)}
                                        onDelete={(answer: Answer) => setToDelete(answer)}
                                    />
                                )
                            })}
                        </TableBodySortable>
                    </Table>
                    :
                    <Typography paragraph={true}>Данных нет</Typography>
                }
                {toDelete &&
                    <Dialog
                        open={true}
                        onClose={() => setToDelete(null)}
                    >
                        <DialogTitle>{"Вы уверены, что хотите удалить ответ?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Вы уверены, что хотите удалить ответ "{toDelete.text}"? Отменить это действие будет невозможно
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => onDelete(toDelete as Answer)}
                                    color="primary">
                                Да
                            </Button>
                            <Button onClick={() => setToDelete(null)}
                                    color="secondary"
                                    autoFocus>
                                Нет
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
            </div>
        </Layout>
    );
};


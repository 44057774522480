import {types} from './types';
import {store} from "store";
import {ApiClient} from "common/api-client.ts";
import {CatsFilters} from "../../filters";
import {Cat, CatDto} from "../../models";

const buildUrl = require('build-url');

const fetchCats = (params?: CatsFilters) => (dispatch: any) => {
    let queryParams = new URLSearchParams();
    if (!params) {
        params = store.getState().cats.cats.params;
    }
    Object.entries(params).forEach(([key, value]) => {
        queryParams.append(key, value)
    });

    let url = buildUrl(process.env.REACT_APP_BACKEND_API_URL, {
        path: '/admin/cats/',
        queryParams: Object.fromEntries(queryParams),
    });
    dispatch(catsListLoad(params));

    return ApiClient.get(url)
        .then(function (response) {
            dispatch(catsListLoadingSuccess(response.data.data, response.data.meta.total));
            return response.data;
        });
};

const catsListLoad = (params: CatsFilters) => ({
    type: types.CATS_LOAD,
    payload: {
        params: params,
    }
});

const catsListLoadingSuccess = (cats: Cat[], count: number) => ({
    type: types.CATS_LOAD_COMPLETED,
    payload: {
        data: cats,
        count: count
    }
});

const fetchCat = (id: number) => {
    return ApiClient.get(`/admin/cats/${id}/`)
        .then(function (response) {
            return response.data;
        })
};

const createCat = (dto: CatDto) => {
    return ApiClient.post('/admin/cats', dto)
        .then(function (response) {
            return response.data
        });
};

const updateCat = (id: number, dto: CatDto) => {
    return ApiClient.put(`/admin/cats/${id}`, dto)
};

export const catsActions = {
    fetchCats,
    fetchCat,
    createCat,
    updateCat,
};
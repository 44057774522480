import * as React from 'react';
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    label: {
        marginLeft: '0.5rem',
    }
});

interface Props {
    icon: any
    label?: string
    tooltip?: string
    url: string
}

export const ActionButton: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const {icon, label, url} = props;

    const isExternal = (url: string) => url.startsWith("http");

    return (
        <Tooltip title={props.tooltip?props.tooltip:""}>
            {isExternal(url) ?
                <a
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                    href={url}
                    style={{textDecoration: "none"}}>
                    <Button
                        size={"small"}
                        color={"primary"}>
                        {icon}
                        {label && <span className={classes.label}>{label}</span>}
                    </Button>
                </a> :
                <Link to={url} style={{textDecoration: "none"}}>
                    <Button
                        size={"small"}
                        color={"primary"}>
                        {icon}
                        {label && <span className={classes.label}>{label}</span>}
                    </Button>
                </Link>
            }
        </Tooltip>
    );
};


import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {redirect} from "react-router-dom";
import {Layout, Pages} from "common/components";
import cogoToast from "cogo-toast";
import {catsActions} from "../store/actions";
import {CatDetailed, CatDto} from "../models";
import {CatForm} from "./CatForm";
import {Breadcrumb} from "../../common/models";
import {useParams} from "react-router";
import Grid from "@mui/material/Grid";
import {LinearProgress} from "@mui/material";

export const CatEditPage = () => {
    const {id} = useParams();
    const dispatch = useDispatch();

    const [cat, setCat] = useState<CatDetailed | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            const response = await catsActions.fetchCat(Number(id));
            setCat(response.data);
            setIsLoading(false);
        };
        setIsLoading(true);
        fetchData();

        window.scrollTo(0, 0);

    }, [dispatch, id]);

    const [isUpdated, setIsUpdated] = useState(false);

    const onSubmit = async (dto: CatDto) => {
        await catsActions.updateCat(Number(id), dto);
        cogoToast.success('Порода успешно изменена', {position: 'bottom-left'});
        setIsUpdated(true);
    };

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Кошки",
                href: "/cats"
            },
        ];

        if (cat) {
            breadcrumbs.push({
                name: 'Редактирование',
            },)
        }

        return breadcrumbs;
    };

    if (isUpdated) {
        redirect("/cats");
    }

    return (
        <Layout page={Pages.Cats} headline={"Редактирование породы"} breadcrumbs={getBreadcrumbs()}>
            {isLoading ? <LinearProgress/> :
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {cat && (
                            <CatForm onSubmit={onSubmit} initial={cat}/>
                        )}
                    </Grid>
                    <Grid item xs={6}>

                    </Grid>
                </Grid>
            }
        </Layout>
    );
};


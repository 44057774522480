import * as React from 'react';
import {useState} from 'react';
import {PostForm} from "./PostForm";
import {postsActions} from "../store/actions";
import {PostDto} from "../models";
import {redirect} from "react-router";
import {Layout, Pages} from "common/components";
import cogoToast from "cogo-toast";
import {Breadcrumb} from "../../common/models";
import {Grid} from "@mui/material";


export const PostsCreatePage: React.FC = () => {
    const [isCreated, setIsCreated] = useState(false);

    const onSubmit = async (dto: PostDto) => {
        await postsActions.createPost(dto);
        cogoToast.success('Статья успешно создана', {position: 'bottom-left'});
        setIsCreated(true);
    };

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Статьи",
                href: "/posts"
            },
            {
                name: "Новая статья",
            }
        ];

        return breadcrumbs;
    };

    if (isCreated) {
        redirect("/posts")
    }

    return (
        <Layout page={Pages.Posts} headline={"Создание статьи"} breadcrumbs={getBreadcrumbs()}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <PostForm onSubmit={onSubmit} />
                </Grid>
                <Grid item xs={6}>

                </Grid>
            </Grid>
        </Layout>
    );
};


import * as React from 'react';
import {useState} from 'react';
import {Field, Form} from "react-final-form";
import {useNavigate} from "react-router";
import commonStyles from "common/styles";
import {FieldError} from "common/components";
import * as EmailValidator from 'email-validator';
import {FORM_ERROR} from "final-form";
import {useDispatch} from "react-redux";
import {usersActions} from "../store/actions";
import {LoginDto} from "../models/LoginDto";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {Dialog} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";

const useStyles = makeStyles({
    formControl: {
        ...commonStyles.formControl
    },
    dialogContent: {
        padding: '0 24px'
    }
});

const required = (value: string) => value ? undefined : "Это поле обязательно";
const isEmail = (value: string) => EmailValidator.validate(value) ? undefined : "Неправильный email";
const composeValidators = (...validators: any) => (value: string) =>
    validators.reduce((error: any, validator: any) => error || validator(value), undefined);

export const LoginPage: React.FC = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const onSubmit = async (formData: any) => {
        let dto = {
            email: formData.email,
            password: formData.password,
        } as LoginDto;
        try {
            // @ts-ignore
            await dispatch(usersActions.login(dto));
            setIsAuth(true);
        } catch (error) {
            // @ts-ignore
            if (!error.response) return { [FORM_ERROR]: error };

            // @ts-ignore
            let { errors } = error.response.data;
            if (errors.common) {
                return { [FORM_ERROR]: errors.common };
            }
            return errors;
        }
    };

    const classes = useStyles();
    const [isAuth, setIsAuth] = useState(false);

    let submit: any;

    if (isAuth) {
       navigate('/')
    }

    return (
        <Dialog open={true}>
            <DialogTitle>{"Авторизация"}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Form
                    onSubmit={onSubmit}
                    render={({handleSubmit, submitError}) => {
                        submit = handleSubmit;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Field
                                    name="email"
                                    component="input"
                                    type="email"
                                    validate={composeValidators(required, isEmail)}
                                    required
                                >
                                    {({input, meta, ...rest}) => (
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                autoFocus
                                                label="Email"
                                                {...input} {...rest}
                                                error={!!meta.error || !!meta.submitError}
                                            />
                                            {(meta.error || meta.submitError) && meta.touched && (
                                                <FieldError text={meta.error || meta.submitError}/>
                                            )}
                                        </FormControl>
                                    )}
                                </Field>
                                <Field
                                    name="password"
                                    component="input"
                                    type="password"
                                    validate={composeValidators(required)}
                                    required
                                >
                                    {({input, meta, ...rest}) => (
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                label="Пароль"
                                                {...input} {...rest}
                                                error={!!meta.error || !!meta.submitError}
                                            />
                                            {(meta.error || meta.submitError) && meta.touched && (
                                                <FieldError text={meta.error || meta.submitError}/>
                                            )}
                                        </FormControl>
                                    )}
                                </Field>
                                {submitError && <FieldError text={submitError}/>}
                            </form>
                        )
                    }}/>
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={event => {
                        submit(event);
                    }}
                >
                    {"Войти"}
                </Button>
            </DialogActions>
        </Dialog>
    )
};
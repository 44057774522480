import axios, {AxiosRequestConfig} from 'axios';
import {store} from "../store";
import {usersActions} from "../users/store/actions";
import {TokenStorage} from "./token-storage";

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_URL
});

const tokenStorage = TokenStorage.getInstance();

apiClient.defaults.withCredentials = true;

apiClient.interceptors.request.use(function (config: AxiosRequestConfig) {
    const cleanJSON = (json: any) => {
        // Потому что axios undefined-значения выпиливает, и надо заменять на null
        for (let key in json) {
            if (json[key] === undefined) {
                json[key] = null;
            } else if (typeof json[key] === 'object') {
                json[key] = cleanJSON(json[key]);
            }
        }
        return json;
    };

    if (['post', 'put', 'patch'].includes(config.method as string)) {
        config.data = cleanJSON(config.data);
    }
    return config;
},function (error: any) {
    // Do something with request error
    return Promise.reject(error);
});

apiClient.interceptors.request.use(function (config: AxiosRequestConfig) {
    const token = tokenStorage.getAccess();
    if (token) {
        // @ts-ignore
        config.headers.Authorization = 'Bearer ' +  token;
    }
    return config;
},function (error: any) {
    // Do something with request error
    return Promise.reject(error);
});

apiClient.interceptors.response.use(function (response: any) {
    return response;
}, async function (error: any) {
    const originalRequest = error.config;

    /*if (!error.response || [500, 404].includes(error.response.status) || (error.response.config.method === 'get' && error.response.status === 400)) {
        return Promise.reject(error);
    }*/

    if (error.response.status === 401) {
        if (error.request.withCredentials && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const jwtPair = await usersActions.refreshToken(tokenStorage.getRefresh());
                tokenStorage.setJwtPair(jwtPair);
                return apiClient.request(originalRequest);
            } catch (e) {
                // @ts-ignore
                store.dispatch(usersActions.logout());
                return Promise.reject(error)
            }
        } else {
            // @ts-ignore
            store.dispatch(usersActions.logout());
        }
    }

    return Promise.reject(error);
});

export const ApiClient = apiClient;
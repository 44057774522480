import {combineReducers} from 'redux';
import {ImagesReducer} from "./ImagesReducer";
import {CommonAppState, MediaState} from "../states";
import {CountriesReducer} from "./CountriesReducer";

export const CommonAppReducer = combineReducers<CommonAppState>({
    countries: CountriesReducer,
    media: combineReducers<MediaState>({
        images: ImagesReducer
    }),
});
import * as React from 'react';
import {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Category, CategoryTree} from "../models";
import {categoriesActions} from "../store/actions";
import {StateInterface} from "reducers";
import commonStyles from "common/styles";
import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from "@mui/material";
import {FieldError} from "common/components";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";


const useStyles = makeStyles((theme: Theme) => createStyles({
    newCategory: {
        color: commonStyles.successColor,
        cursor: "pointer",
        textDecoration: "underline",
        lineHeight: "3rem",
        "&:hover": {
            textDecoration: "none"
        }
    },
    formControl: {
        ...commonStyles.formControl
    }
}));

interface CategorySelectorProps {
    selected: any | undefined
    onChange: (category: Category) => void
    onBlur: (event: any) => void
    error: boolean
    errorText: string
    required?: boolean
}

export const CategorySelector: React.FC<CategorySelectorProps> = (props: CategorySelectorProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        // @ts-ignore
        dispatch(categoriesActions.fetchCategories());
    }, [dispatch]);

    interface CategoryItem {
        value: number;
        name: string;
        category: Category
    }

    const buildList = (categories: CategoryTree[], level: number = 0, items: CategoryItem[] = []) => {
        for (let i = 0; i < categories.length; i++) {
            let space = '\u00A0\u00A0\u00A0'.repeat(level);
            items.push({
                value: categories[i].id as number,
                name: space + categories[i].name,
                category: categories[i]
            });
            buildList(categories[i].children as CategoryTree[], level + 1, items)
        }
        return items;
    };

    const tree = useSelector((state: StateInterface) => state.posts.categories.tree.data);
    const isLoading = useSelector((state: StateInterface) => state.posts.categories.tree.isLoading);
    const flatTree = buildList(tree);

    const handleChange = (categoryId: number) => {
        for (let i = 0; i < flatTree.length; i++) {
            if (flatTree[i].category.id === categoryId) {
                props.onChange(flatTree[i].category);
            }
        }
    };


    return (
        <FormControl className={classes.formControl}>
            {isLoading ?
                <CircularProgress/>
                :
                <>
                    <InputLabel required={!!props.required} error={props.error}>Категория</InputLabel>
                    <Select
                        onChange={(event) => handleChange(event.target.value as number)}
                        onBlur={(event) => props.onBlur(event)}
                        error={props.error}
                        value={props.selected?props.selected.id:undefined}>
                        <MenuItem value="">
                            <em>{"\u2014"} Нет {"\u2014"}</em>
                        </MenuItem>
                        {flatTree.map((item: CategoryItem) => {
                            return (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </>
            }

            {props.errorText && (
                <FieldError text={props.errorText}/>
            )}
        </FormControl>
    );
};


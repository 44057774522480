import * as React from 'react';
import {useEffect, useState} from 'react';
import {redirect} from "react-router-dom";
import {testsActions} from "../store/actions";
import {Layout, Pages} from "common/components";
import {TestForm} from "./TestForm";
import {Breadcrumb} from "../../common/models";
import cogoToast from "cogo-toast";
import {TestDetailed} from "../models/TestDetailed";
import {TestDto} from "../models/TestDto";
import {LinearProgress} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useParams} from "react-router";


export const TestsEditPage = () => {
    const {id} = useParams();

    const [test, setTest] = useState<TestDetailed | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const response = await testsActions.fetchTest(Number(id));
            setTest(response.data);
            setIsLoading(false);
        };
        fetchData();

        window.scrollTo(0, 0);

    }, [id]);

    const [isUpdated, setIsUpdated] = useState(false);

    const onSubmit = async (dto: TestDto) => {
        await testsActions.updateTest(Number(id), dto);
        cogoToast.success('Изменения сохранены', {position: 'bottom-left'});
        setIsUpdated(true);
    };

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Тесты",
                href: "/tests"
            },
        ];
        if (test) {
            breadcrumbs.push({
                name: 'Редактирование',
            });
        }

        return breadcrumbs;
    };

    if (isUpdated) {
        redirect('/tests');
    }

    return (
        <Layout page={Pages.Tests} headline={"Редактирование теста"} breadcrumbs={getBreadcrumbs()}>
            {isLoading ? <LinearProgress/> :
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {test && (
                            <TestForm onSubmit={onSubmit} initial={test}/>
                        )}
                    </Grid>
                    <Grid item xs={6}>

                    </Grid>
                </Grid>
            }
        </Layout>
    );
};


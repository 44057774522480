import * as React from 'react';
import {validators} from "../validators";
import {Field} from "react-final-form";
import {FieldError} from "./index";
import commonStyles from "common/styles";
import {FormControl, Rating, RatingProps} from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
    formControl: {
        ...commonStyles.formControl
    },
});

interface Props {
    name: string
    label: string
    required?: boolean
}

export const RatingField: React.FC<Props & RatingProps> = (props: Props & RatingProps) => {
    const classes = useStyles();

    const {name, label} = props;

    const max = props.max ? props.max : 5;

    const required = !!props.required;

    let validatorsList: any = [];
    if (required) {
        validatorsList.push(validators.required);
    }

    return (
        <Field
            name={name}
            component="input"
            type="number"
            validate={validators.composeValidators(...validatorsList)}
            required={required}
        >
            {({input, meta, ...rest}) => {
                const error = meta.error || meta.submitError;
                const value = parseInt(input.value);
                return <FormControl className={classes.formControl}>
                    <FormLabel
                        component="legend"
                        error={meta.touched && error}
                        required={required}
                    >{label}</FormLabel>
                    <Rating
                        {...input}
                        {...rest}
                        {...props}
                        onChange={(event, value) => {
                            return input.onChange(parseInt(String(value)));
                        }}
                        value={value}
                        max={max}
                        onChangeActive={(event, newHover) => {
                            // @ts-ignore
                            //return input.onBlur(document.createEvent('HTMLEvents'));
                        }}
                    />
                    {meta.touched && error &&
                    <FieldError text={error}/>
                    }
                </FormControl>
            }}
        </Field>
    );
};


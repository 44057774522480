import * as React from 'react';
import {useState} from 'react';
import {FishDto} from "../models";
import {redirect} from "react-router";
import {Layout, Pages} from "common/components";
import cogoToast from "cogo-toast";
import {fishesActions} from "../store/actions";
import {FishForm} from "./FishForm";
import {Breadcrumb} from "../../common/models";
import {Grid} from "@mui/material";


export const FishCreatePage: React.FC = () => {
    const [isCreated, setIsCreated] = useState(false);

    const onSubmit = async (dto: FishDto) => {
        await fishesActions.createFish(dto);
        cogoToast.success('Вид успешно создан', {position: 'bottom-left'});
        setIsCreated(true);
    };

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Рыбы",
                href: "/fishes"
            },
            {
                name: "Новый вид"
            }
        ];

        return breadcrumbs;
    };

    if (isCreated) {
        redirect("/fishes");
    }

    return (
        <Layout page={Pages.Fishes} headline={"Создание вида"} breadcrumbs={getBreadcrumbs()}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <FishForm onSubmit={onSubmit} />
                </Grid>
                <Grid item xs={6}>

                </Grid>
            </Grid>
        </Layout>
    );
};


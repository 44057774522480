import * as React from 'react';
import commonStyles from "common/styles";
import {FormHelperText} from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    error: {
        fontWeight: 'bold',
        color: commonStyles.dangerColor,
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
    }

});

interface FieldErrorProps {
    text?: string,
}

export const FieldError: React.FC<FieldErrorProps> = (props: FieldErrorProps) => {
    const classes = useStyles();
    const {text} = props;

    return (
        <>
            {text && <FormHelperText className={classes.error}>{text}</FormHelperText>}
        </>
    );
};


import * as React from 'react';
import {useEffect, useState} from 'react';
import {Country, CountryDto} from "../models";
import commonStyles from "common/styles";
import {FieldError, TextField} from "common/components";
import {countriesActions} from "common/store/actions/CountriesActions";
import {Field, Form} from "react-final-form";
import cogoToast from "cogo-toast";
import {FORM_ERROR} from "final-form";
import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import Input from "@mui/material/Input";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";


const useStyles = makeStyles((theme: Theme) => createStyles({
    formControl: {
        ...commonStyles.formControl
    },
    addToListBtn: {
        float: "right",
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
    },
    dialogActionBtns: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    }
}));

interface CountrySelectorProps {
    name: string
    label: string
    required?: boolean
}

export const CountrySelector: React.FC<CountrySelectorProps> = (props: CountrySelectorProps) => {
    const classes = useStyles();
    const [countries, setCountries] = useState<Country[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchCountries = async () => {
        const response = await countriesActions.fetchCountries();
        setCountries(response.data);
        setIsLoading(false);
    };

    useEffect(() => {
        setIsLoading(true);
        fetchCountries();
    }, []);

    const [isOpen, setIsOpen] = useState(false);

    const onSubmit = async (values: any) => {
        return await countriesActions.createCountry({
            name: values.name
        } as CountryDto)
            .then(function (response) {
                //const newCountry: Country = response.data;
                cogoToast.success('Страна добавлена', {position: 'bottom-left'});
                setIsOpen(false);
                fetchCountries();
            }).catch(error => {
                if (!error.response) return { [FORM_ERROR]: error };

                let errors = error.response.data.errors;
                if (errors.common) {
                    return { [FORM_ERROR]: errors.common };
                }
                return errors;
            });
    };

    return (
        <Field
            name={props.name}
            component="input"
            type="text"
        >
            {({input, meta, ...rest}) => {
                let selectedIds: number[] = [];
                for (let i = 0; i < input.value.length; i++) {
                    selectedIds.push(input.value[i].id);
                }

                const onChange = (event: any) => {
                    let selectedCountries: Country[] = [];
                    event.target.value.forEach(function (cId: number) {
                        countries.forEach(function (country: Country) {
                            if (country.id === cId) {
                                selectedCountries.push(country);
                            }
                        });
                    });

                    input.onChange(selectedCountries);
                };

                const error = meta.error || meta.submitError;

                return <div>
                    <FormControl className={classes.formControl}>
                        <InputLabel required={!!props.required} error={meta.touched && error}>{props.label}</InputLabel>
                        {isLoading ?
                            <CircularProgress/>
                            :
                            <Select
                                multiple
                                value={selectedIds}
                                onChange={onChange}
                                input={<Input id="countries-multiple-chip"/>}
                                renderValue={selectedIds => {
                                    if (typeof selectedIds === 'object') {
                                        return (
                                            <>
                                                {input.value.map((value: Country) => (
                                                    <Chip key={value.id} label={value.name}/>
                                                ))}
                                            </>
                                        )
                                    }
                                }}
                            >
                                {countries.map((country, index) => (
                                    <MenuItem
                                        key={index}
                                        value={country.id}
                                    >
                                        <span>{country.name}</span>
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                        {meta.touched && error &&
                        <FieldError text={error}/>
                        }
                    </FormControl>
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => setIsOpen(true)}
                        className={classes.addToListBtn}>
                        {"Добавить"}
                    </Button>
                    <Dialog
                        open={isOpen}
                        onClose={() => setIsOpen(false)}
                    >
                        <DialogTitle>{"Добавить страну"}</DialogTitle>
                        <DialogContent>
                            <Form
                                onSubmit={onSubmit}
                                render={({handleSubmit, submitError, form,}) => {
                                    let submit: (values: any) => any = handleSubmit;
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <TextField name={"name"} label={"Название страны"} required/>
                                            {submitError && <FieldError text={submitError}/>}
                                            <div className={classes.dialogActionBtns}>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={event => {
                                                        submit(event);
                                                    }}
                                                >
                                                    Ок
                                                </Button>
                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={() => setIsOpen(false)}
                                                >
                                                    Отмена
                                                </Button>
                                            </div>
                                        </form>
                                    );
                                }}
                            />
                        </DialogContent>
                    </Dialog>
                </div>
            }}
        </Field>
    );
};


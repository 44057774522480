import {types} from "../actions/types";
import {CategoriesTreeState} from "../states";

const initialState = (): CategoriesTreeState => ({
    data: [],
    meta: {
        total: 0
    },
    isLoading: false,
    errors: [],
});

export const CategoriesTreeReducer = (state = initialState(), action: any) => {
    switch (action.type) {
        case types.CATEGORIES_LOAD:
            return Object.assign({}, state, {
                process: true
            });
        case types.CATEGORIES_LOAD_COMPLETED:
            return Object.assign({}, state, {
                process: false,
                data: action.payload.data,
                meta: action.payload.meta
            });
    }
    return state;
};
import {FORM_ERROR} from "final-form";
import { OutputData } from "@editorjs/editorjs";
import {EditorContent} from "../models";

const processErrors = (errors: any) => {
    return Object.keys(errors).reduce(function (processed: any, key) {
        if (key.startsWith('logo_')) {
            processed['logo'] = (Array.isArray(errors[key]) ? errors[key][0] : errors[key]) as string;
        } else {
            processed[key === 'non_field_errors' || key === 'details' ? FORM_ERROR : key] = (Array.isArray(errors[key]) ? errors[key][0] : errors[key]) as string;
        }
        return processed;
    }, {});
};

const removeHtmlTags = (html: string) => {
    const regex = /(<([^>]+)>)/ig;
    return html.replace(regex, '');
};

const getPreviewFromBlockEditor = (content: EditorContent) => {
    if (!content) {
        return '';
    }
    for (let i = 0; i < content.blocks.length; i++) {
        if (content.blocks[i].type === 'paragraph') {
            let firstParagraph = removeHtmlTags(content.blocks[i].data.text);
            if (firstParagraph.length > 50) {
                firstParagraph = firstParagraph.slice(0, 50) + '...';
            }
            return firstParagraph;
        }
    }
    return '';
};

const getImageUrl = (path: string, isSource: boolean = false) => {
    let mediaRoot = process.env.REACT_APP_IMGPROXY_URL;
    if (isSource) {
        mediaRoot = process.env.REACT_APP_MEDIA_URL;
    }

    if (mediaRoot && !mediaRoot.endsWith('/')) {
        mediaRoot += '/';
    }

    return mediaRoot + path;
};

const editorContentToView = (content: EditorContent): EditorContent => {
    for (let i = 0; i < content.blocks.length; i++) {
        if (content.blocks[i].type === 'image') {
            let block = content.blocks[i];
            if (!block.data.file.url) {
                continue;
            }
            block.data.file.url = getImageUrl(block.data.file.url, true);
            content.blocks[i] = block;
        }
    }
    return content;
};

const editorContentFromView = (content: EditorContent): EditorContent => {
    for (let i = 0; i < content.blocks.length; i++) {
        if (content.blocks[i].type === 'image') {
            let block = content.blocks[i];
            if (!block.data.file.url) {
                continue;
            }

            let mediaRoot = process.env.REACT_APP_MEDIA_URL;
            if (mediaRoot && !mediaRoot.endsWith('/')) {
                mediaRoot += '/';
            }
            block.data.file.url = block.data.file.url.replace(`${mediaRoot}`, '');
            content.blocks[i] = block;
        }
    }
    return content;
};

export const helpers = {
    removeHtmlTags,
    processErrors,
    getPreviewFromBlockEditor,
    getImageUrl,
    editorContentToView,
    editorContentFromView,
};

import {types} from "quizes/store/actions/types";
import {AnswersState} from "../states";

const initialState = (): AnswersState => ({
    data: [],
    count: 0
});

export const AnswersReducer = (state = initialState(), action: any) => {
    switch (action.type) {
        case types.TESTS_ANSWERS_LOAD:
            return Object.assign({}, state);
        case types.TESTS_ANSWERS_LOAD_COMPLETED:
            const {data, count} = action.payload;
            return Object.assign({}, state, {
                data: data,
                count: count
            });
    }
    return state;
};
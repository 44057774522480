import {CategoryTree, Post} from "posts/models";
import {PostsFilters} from "posts/filters";

export const types = {
    POSTS_LOAD: 'POSTS_LOAD',
    POSTS_LOAD_COMPLETED: 'POSTS_LOAD_COMPLETED',
    POSTS_LOAD_ERROR: 'POSTS_LOAD_ERROR',

    POST_LOAD: 'POST_LOAD',
    POST_LOAD_COMPLETED: 'POST_LOAD_COMPLETED',

    CATEGORIES_LOAD: 'CATEGORIES_LOAD',
    CATEGORIES_LOAD_COMPLETED: 'CATEGORIES_LOAD_COMPLETED',
    CATEGORIES_LOAD_ERROR: 'CATEGORIES_LOAD_ERROR',

    CATEGORY_CREATE: 'CATEGORY_CREATE',
    CATEGORY_CREATE_COMPLETED: 'CATEGORY_CREATE_COMPLETED',

    POST_CREATE: 'POST_CREATE',
    POST_CREATE_COMPLETED: 'POST_CREATE_COMPLETED',

    POST_UPDATE: 'POST_UPDATE',
    POST_UPDATE_COMPLETED: 'POST_UPDATE_COMPLETED',
};

export interface PostsLoadAction {
    type: typeof types.POSTS_LOAD,
    payload: {
        params: PostsFilters,
    }
}

export interface PostsLoadCompletedAction {
    type: typeof types.POSTS_LOAD_COMPLETED
    payload: {
        data: Post[]
        count: number
    }
}

export type PostsLoadTypes = PostsLoadAction | PostsLoadCompletedAction

export interface PostLoadAction {
    type: typeof types.POST_LOAD
}

export interface PostLoadCompletedAction {
    type: typeof types.POST_LOAD_COMPLETED
    payload: {
        data: Post
    }
}


export interface CategoriesLoadAction {
    type: typeof types.CATEGORIES_LOAD
}

export interface CategoriesLoadCompletedAction {
    type: typeof types.CATEGORIES_LOAD_COMPLETED,
    payload: {
        data: CategoryTree[]
    }
}
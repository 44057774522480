import * as React from 'react';
import {PostDetailed, PostDto} from "../models";
import {Form, FormSpy} from "react-final-form";
import {BlockTextField, CheckboxField, ImageField, TextField} from "../../common/components";
import {helpers} from "../../common/helpers";
import {FORM_ERROR} from "final-form";
import arrayMutators from "final-form-arrays";
import {TagsField} from "../../common/components/TagsField";
import createDecorator from "final-form-focus";
import {Button} from "@mui/material";
/*
 <Field
    name="category"
    component="input"
    type="text"
    validate={validators.composeValidators(validators.required)}
    required
>
    {({input, meta, ...rest}) => (
        <CategorySelector
            selected={input.value}
            {...input} {...rest}
            error={!!meta.error || !!meta.submitError}
            errorText={meta.touched ? (meta.error || meta.submitError) : ""}/>
    )}
</Field>
<Field
    name="status"
    component="input"
    type="text"
    required
    validate={validators.composeValidators(validators.required)}
>
    {({input, meta, ...rest}) => (
        <FormControl className={classes.formControl}>
            <InputLabel {...rest}
                        error={!!meta.error || !!meta.submitError}>Статус</InputLabel>
            <Select
                {...input} {...rest}
                error={!!meta.error || !!meta.submitError}
            >
                <MenuItem value="">
                    <>{"\u2014"} Нет {"\u2014"}</>
                </MenuItem>
                <MenuItem value="publish">
                    {"Опубликован"}
                </MenuItem>
                <MenuItem value="draft">
                    {"Черновик"}
                </MenuItem>
            </Select>

            {(meta.error || meta.submitError) && meta.touched && (
                <FieldError text={meta.error || meta.submitError}/>
            )}
        </FormControl>
    )}
</Field>
 */
const focusOnErrors = createDecorator();

interface PostFormProps {
    initial?: PostDetailed | undefined,
    onSubmit: (values: PostDto) => any,
    //onChange?: (values: PostDetailed) => any,
}

export const PostForm: React.FC<PostFormProps> = (props: PostFormProps) => {
    const storageKey = 'postForm';

    const onChange = (formData: any) => {
        if (!props.initial) {
            const valuesJson = JSON.stringify(formData.values);
            localStorage.setItem(storageKey, valuesJson);
        }
    };

    // @ts-ignore
    let initial: PostDetailed | undefined = props.initial;
    if (initial === undefined) {
        const fromStorage = localStorage.getItem(storageKey);
        if (fromStorage !== null) {
            initial = JSON.parse(fromStorage);
        }
    } else {
        initial.data = helpers.editorContentToView(initial.data);
    }

    const onSubmit = async (formData: any) => {
        formData.data = helpers.editorContentFromView(formData.data);

        let imageId = null;
        if (formData.image) {
            if (!Array.isArray(formData.image)) {
                imageId = formData.image.id;
            } else {
                imageId = formData.image[0].id;
            }
        }
        let dto = {
            name: formData.name,
            annotation: formData.annotation,
            title: formData.title,
            description: formData.description,
            data: formData.data,
            tags: formData.tags,
            imageId: imageId,
            sourceUrl: formData.sourceUrl,
            noIndex: formData.noIndex,
        } as PostDto;

        try {
            await props.onSubmit(dto);

            if (!props.initial) {
                localStorage.removeItem(storageKey);
            }
        } catch (error) {
            // @ts-ignore
            if (!error.response) return Promise.reject({ [FORM_ERROR]: error });

            // @ts-ignore
            let errors = error.response.data.errors;
            if (errors.common) {
                return { [FORM_ERROR]: errors.common };
            }
            if (errors.imageId) {
                errors.image = errors.imageId;
                delete errors.imageId;
            }

            if (errors.countriesIds) {
                errors.countries = errors.countriesIds;
                delete errors.countriesIds;
            }
            return errors;
        }
    };

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initial}
            decorators={[ focusOnErrors ]}
            mutators={{
                ...arrayMutators
            }}
            render={({handleSubmit, submitError}) => {
                let submit: (values: any) => any = handleSubmit;
                return (
                    <>
                        {onChange &&
                        <FormSpy onChange={onChange} subscription={{values: true}}/>
                        }
                        <form onSubmit={handleSubmit}>
                            {initial && initial.id &&
                            <>
                                <TextField
                                    label={"ID"}
                                    name={"id"}
                                    disabled
                                />
                                <TextField
                                    label={"Slug"}
                                    name={"slug"}
                                    disabled
                                />
                            </>
                            }
                            <TextField
                                label={"Название статьи"}
                                name={"name"}
                                required
                            />
                            <TextField
                                label={"Отрывок"}
                                name={"annotation"}
                                multiline
                                required
                            />
                            <TextField
                                label={"Title"}
                                name={"title"}
                                required
                            />
                            <TextField
                                label={"Meta-description"}
                                name={"description"}
                                multiline
                                required
                            />
                            <BlockTextField name={"data"} label={"Текст статьи"} required/>
                            <ImageField
                                name={"image"}
                                label={"Изображение"}
                                required
                            />
                            <TagsField name={'tags'} label={'Теги'} />
                            <CheckboxField
                                name={"noIndex"}
                                label={"Скрывать от поисковых систем"}
                            />
                            <TextField
                                label={"Ссылка на источник"}
                                name={"sourceUrl"}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={event => {
                                    submit(event);
                                }}
                            >
                                Сохранить
                            </Button>
                        </form>
                    </>
                )
            }}/>
    );
};


import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {redirect} from "react-router-dom";
import * as H from 'history';
import {Layout, Pages} from "common/components";
import cogoToast from "cogo-toast";
import {fishesActions} from "../store/actions";
import {FishDetailed, FishDto} from "../models";
import {FishForm} from "./FishForm";
import {Breadcrumb} from "../../common/models";
import {useParams} from "react-router";
import {LinearProgress} from "@mui/material";
import Grid from "@mui/material/Grid";

export const FishEditPage = () => {
    const {id} = useParams();
    const dispatch = useDispatch();

    const [fish, setFish] = useState<FishDetailed | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fishesActions.fetchFish(Number(id));
            setFish(response.data);
            setIsLoading(false);
        };
        setIsLoading(true);
        fetchData();

        window.scrollTo(0, 0);

    }, [dispatch, id]);

    const [isUpdated, setIsUpdated] = useState(false);

    const onSubmit = async (dto: FishDto) => {
        await fishesActions.updateFish(Number(id), dto);
        cogoToast.success('Изменения сохранены', {position: 'bottom-left'});
        setIsUpdated(true);
    };

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Рыбы",
                href: "/fishes"
            },
        ];

        if (fish) {
            breadcrumbs.push({
                name: 'Редактирование',
            },)
        }

        return breadcrumbs;
    };

    if (isUpdated) {
        redirect("/fishes")
    }

    return (
        <Layout page={Pages.Fishes} headline={"Редактирование вида"} breadcrumbs={getBreadcrumbs()}>
            {isLoading ? <LinearProgress/> :
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {fish && (
                            <FishForm onSubmit={onSubmit} initial={fish}/>
                        )}
                    </Grid>
                    <Grid item xs={6}>

                    </Grid>
                </Grid>
            }
        </Layout>
    );
};


import * as React from 'react';
import {useEffect, useState} from 'react';
import commonStyles from "common/styles";
import {Layout, Pages} from "common/components";

import {Breadcrumb} from "../../common/models";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import {UserAdmin} from "../models";
import {usersActions} from "../store/actions";
import {TableRow} from "@mui/material";
import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from "@mui/material";


const useStyles = makeStyles((theme: Theme) => createStyles({
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    link: {
        ...commonStyles.link,
    },
    data: {
        marginTop: "0.5rem"
    },
    head: {
        backgroundColor: "#fff",
        position: "sticky",
        top: 0
    },
    actionBtns: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

export const UsersListPage: React.FC = () => {
    const classes = useStyles();
    const [users, setUsers] = useState<UserAdmin[]>([]);

    useEffect(() => {
        usersActions.fetchUsers().then(function (users: UserAdmin[]) {
            setUsers(users);
        })
    }, []);

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Пользователи",
            },
        ];
        return breadcrumbs;
    };

    console.dir(users);

    return (
        <Layout page={Pages.Users} headline={"Пользователи"} breadcrumbs={getBreadcrumbs()}>
            <div className={classes.data}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Key</TableCell>
                                <TableCell>Auth type</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Is confirmed</TableCell>
                                <TableCell>Is admin</TableCell>
                                <TableCell>Created at</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell>{user.id}</TableCell>
                                    <TableCell>{user.key}</TableCell>
                                    <TableCell>{user.authType}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.name}</TableCell>
                                    <TableCell>{user.isConfirmed?'да':'нет'}</TableCell>
                                    <TableCell>{user.isAdmin?'да':'нет'}</TableCell>
                                    <TableCell>{String(user.createdAt)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Layout>
    );
};


import * as React from 'react';
import {useState} from 'react';
import {redirect, useParams} from "react-router";
import {Layout, Pages} from "common/components";
import cogoToast from "cogo-toast";
import {Breadcrumb} from "../models";
import {SelectionDto} from "../models/SelectionDto";
import {SelectionForm} from "./SelectionForm";
import {Kind} from "../types";
import {selectionsAPI} from "../api/selections";
import {Grid} from "@mui/material";

export const SelectionCreatePage = () => {
    const {kind} = useParams();
    const [isCreated, setIsCreated] = useState(false);

    const onSubmit = async (dto: SelectionDto) => {
        await selectionsAPI.createSelection(dto);
        cogoToast.success('Подборка успешно создана', {position: 'bottom-left'});
        setIsCreated(true);
        window.scrollTo(0, 0);
    };

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
        ];
        switch (kind) {
            case Kind.dogs:
                breadcrumbs.push({
                    name: "Собаки",
                    href: "/dogs"
                },{
                    name: "Подборки",
                    href: "/dogs/selections"
                })
                break;
            case Kind.cats:
                breadcrumbs.push({
                    name: "Кошки",
                    href: "/cats"
                },{
                    name: "Подборки",
                    href: "/cats/selections"
                })
                break;
            case Kind.fishes:
                breadcrumbs.push({
                    name: "Рыбки",
                    href: "/fishes"
                },{
                    name: "Подборки",
                    href: "/fishes/selections"
                })
                break;
        }
        breadcrumbs.push({
            name: "Новая подборка",
        });

        return breadcrumbs;
    };

    const getRedirectUrl = (k: Kind): string => {
        switch (k) {
            case Kind.dogs:
                return '/dogs/selections';
            case Kind.cats:
                return '/cats/selections';
            case Kind.fishes:
                return '/fishes/selections';
        }
    };

    const getPage = (k: Kind): Pages => {
        switch (k) {
            case Kind.dogs:
                return Pages.Dogs;
            case Kind.cats:
                return Pages.Cats;
            case Kind.fishes:
                return Pages.Fishes;
        }
    }

    if (isCreated) {
        redirect(getRedirectUrl(kind as Kind));
    }

    return (
        <Layout page={getPage(kind as Kind)} headline={"Создание подборки"} breadcrumbs={getBreadcrumbs()}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <SelectionForm kind={kind as Kind} onSubmit={onSubmit} />
                </Grid>
                <Grid item xs={6}>

                </Grid>
            </Grid>
        </Layout>
    );
};


import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {StateInterface} from "reducers";
import commonStyles from "common/styles";
import {Link} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import CategoryIcon from '@mui/icons-material/Category';
import {ActionButton, Layout, Pages} from "common/components";

import {
    CustomPaging,
    DataTypeProvider,
    PagingState,
    RowDetailState,
    SearchState,
    SortingDirection,
    SortingState
} from '@devexpress/dx-react-grid';
import {
    Grid,
    PagingPanel,
    SearchPanel,
    Table,
    TableColumnVisibility,
    TableFixedColumns,
    TableHeaderRow,
    Toolbar
} from '@devexpress/dx-react-grid-material-ui';
import {helpers} from "../../common/helpers";
import {catsActions} from "../store/actions";
import {CatsFilters} from "../filters";
import {Breadcrumb} from "../../common/models";
import {createStyles, makeStyles} from '@mui/styles';
import Button from "@mui/material/Button";
import {Theme} from "@mui/material";


const useStyles = makeStyles((theme: Theme) => createStyles({
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    link: {
        ...commonStyles.link,
    },
    data: {
        marginTop: "0.5rem"
    },
    head: {
        backgroundColor: "#fff",
        position: "sticky",
        top: 0
    },
    actionBtns: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const ThumbnailTypeProvider = (props: any) => {
    return <DataTypeProvider
        formatterComponent={(row: any) => row.value ? <img style={{maxWidth: '200px', width: '100%'}} alt="" src={helpers.getImageUrl(row.value.thb350x250)}/> : null}
        {...props}
    />
};

const NameTypeProvider = (props: any) => {
    return <DataTypeProvider
        formatterComponent={(row: any) => row.value[0].toUpperCase() + row.value.slice(1)}
        {...props}
    />
};

const ActionsTypeProvider = (props: any) => (
    <DataTypeProvider
        formatterComponent={(props: any) => {
            return <>
                <ActionButton icon={<EditIcon/>}
                    url={'/cats/' + String(props.row.id)}/>
                <ActionButton icon={<VisibilityIcon/>}
                    url={`https://animalpaw.ru/cats/${props.row.slug}`}/>
                </>;
        }}
        {...props}
    />
);

export const CatsListPage: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const dogs = useSelector((state: StateInterface) => state.cats.cats.data);
    const totalCount = useSelector((state: StateInterface) => state.cats.cats.count);
    const params = useSelector((state: StateInterface) => state.cats.cats.params);

    let orderDirection = 'asc';
    let orderField = params.order;
    if (params.order.startsWith('-')) {
        orderDirection = 'desc';
        orderField = orderField.substr(1);
    }

    const [pageSizes] = useState([12, 24, 48]);
    useEffect(() => {
        // @ts-ignore
        dispatch(catsActions.fetchCats());
    }, [dispatch]);

    const handleChangePage = (pageNum: number) => {
        let newFilters: CatsFilters = Object.assign({}, params);
        newFilters.offset = pageNum * params.limit;
        // @ts-ignore
        dispatch(catsActions.fetchCats(newFilters));
    };

    const handleChangePageSize = (value: number) => {
        let newFilters: CatsFilters = Object.assign({}, params);
        newFilters.limit = value;
        newFilters.offset = 0;
        // @ts-ignore
        dispatch(catsActions.fetchCats(newFilters));
    };

    const handleSorting = (value: any) => {
        let newFilters: CatsFilters = Object.assign({}, params, {
            'order': (value[0].direction === 'desc'?'-':'') + value[0].columnName
        });
        // @ts-ignore
        dispatch(catsActions.fetchCats(newFilters,));
        /*
        {
            field: value[0].columnName,
            direction: value[0].direction
        }
         */
    };

    const onSearch = (value: any) => {
        let newFilters: CatsFilters = Object.assign({}, params);
        newFilters.offset = 0;
        newFilters.search = value;
        // @ts-ignore
        dispatch(catsActions.fetchCats(newFilters));
    };

    const getBreadcrumbs = () => {
        let breadcrumbs: Breadcrumb[] = [
            {
                name: "Главная",
                href: "/"
            },
            {
                name: "Кошки",
            },
        ];

        return breadcrumbs;
    };

    return (
        <Layout page={Pages.Cats} headline={"Кошки"} breadcrumbs={getBreadcrumbs()}>
            <div className={classes.actionBtns}>
                <Link to={'/cats/create'} style={{textDecoration: 'none'}}>
                    <Button variant="outlined" color="primary">
                        <AddIcon className={classes.leftIcon}/> Создать
                    </Button>
                </Link>
                <Link to={'/cats/selections'} style={{textDecoration: 'none'}}>
                    <Button variant="outlined" color="primary">
                        <CategoryIcon className={classes.leftIcon}/> Подборки
                    </Button>
                </Link>
            </div>
            <div className={classes.data}>
                <Grid
                    rows={dogs}
                    columns={[
                        { name: 'id', title: 'ID' },
                        { name: 'slug' },
                        { name: 'image', title: 'Logo' },
                        { name: 'name', title: 'Name' },
                        { name: 'actions' },
                    ]}>
                    <SortingState
                        sorting={[
                            {
                                columnName: orderField,
                                direction: orderDirection as SortingDirection
                            }
                        ]}
                        onSortingChange={handleSorting}
                        columnExtensions={[
                            {
                                columnName: 'image', sortingEnabled: false
                            },
                            {
                                columnName: 'actions', sortingEnabled: false
                            }
                        ]}
                    />
                    <PagingState
                        currentPage={Math.floor(params.offset / params.limit)}
                        onCurrentPageChange={handleChangePage}
                        pageSize={params.limit}
                        onPageSizeChange={handleChangePageSize}
                    />
                    <SearchState
                        value={params.search}
                        onValueChange={onSearch}
                    />
                    <RowDetailState />

                    <CustomPaging totalCount={totalCount}/>
                    <Table />
                    <TableColumnVisibility defaultHiddenColumnNames={['slug']}/>
                    <ThumbnailTypeProvider for={['image']}/>
                    <NameTypeProvider for={['name']}/>
                    <ActionsTypeProvider for={['actions']}/>
                    <TableHeaderRow showSortingControls />
                    <Toolbar />
                    <SearchPanel />
                    <TableFixedColumns
                        leftColumns={['id']}
                        rightColumns={['actions']}
                    />
                    <PagingPanel
                        pageSizes={pageSizes}
                    />
                </Grid>
            </div>
        </Layout>
    );
};


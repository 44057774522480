import {types} from "quizes/store/actions/types";
import {TestsState} from "../states";

const initialState = (): TestsState => ({
    data: [],
    count: 0,
    params: {
        limit: 12,
        offset: 0,
    },
});

export const TestsReducer = (state = initialState(), action: any) => {
    switch (action.type) {
        case types.TESTS_LOAD:
            return Object.assign({}, state, {
                params: action.payload.params
            });
        case types.TESTS_LOAD_COMPLETED:
            const {data, count} = action.payload;
            return Object.assign({}, state, {
                data: data,
                count: count,
            });
    }
    return state;
};